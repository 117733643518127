import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { UserDataContext } from "../../context/userContext";

import MyDevices from "../../components/tables/devices";

import "./index.css";
export default function Subscription(props) {
  const { UserInfo } = useContext(UserDataContext);

  useEffect(() => {
    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);

  const [Showloading, setLoading] = useState(false);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }} className="section-wrap">
          <MyDevices />
        </div>
      </React.Fragment>
    );
  }
}
