import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
  validatePassword,
} from "../../components/helpers/validateInput";
import { NotificationManager } from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import swal from "sweetalert";
import Select from "react-select";
import countryList from "react-select-country-list";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { AppLogo } from "../../assets/svg";
import Logo from "../../assets//images/appLogo.png";
import SEO from "../../components/Layout/SEO";
export default function CreateAccount(props) {
  const [userAccount, setUserAccount] = useState({
    type: "",
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
    activeInput: "",
  });
  const [accountType, SetaccountType] = useState("individual");
  const [setValidatedForm, setValidated] = useState(false);
  const [emailOptionText, setEmailOptionText] = useState(false);
  const [userCountry, setuserCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [userNumber, setuserNumber] = useState("");

  const changeHandler = (value) => {
    // alert(value.label);
    setuserCountry(value);
  };

  const [errorInput, setErrorInput] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "lastName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, lastName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "companyName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, companyName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = validatePassword(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.name === "header_image") {
      //  setUserAccount({...offers,header_image:e.target.files[0], previewHeaderImg: URL.createObjectURL(e.target.files[0])})
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      errorInput.password !== "Looks Good!" ||
      errorInput.email !== "Looks Good!" ||
      errorInput.firstName !== "Looks Good!" ||
      errorInput.lastName !== "Looks Good!"
    ) {
      NotificationManager.error("Please fill in all input.");

      return;
    }

    if (userCountry === "") {
      NotificationManager.error("Please select your country.");
      return;
    }

    if (userNumber === "" || userNumber === null || userNumber === undefined) {
      return NotificationManager.error("Phone number is required.");
    }

    if (termAndConditionCheck === false) {
      swal({
        title: "Oops!",
        text: "You need to accept our terms of use before you can procceed",
        icon: "warning",
        dangerMode: true,
      });

      return;
    }

    showLoader();
    let countryCode = `+${parsePhoneNumber(userNumber).countryCallingCode}`;
    let sliceNum = userNumber.slice(-10);
    const data = {
      type: accountType,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      email: userAccount.email,
      password: userAccount.password,
      emailOpt: emailOptionText,
      country: userCountry.label,
      countryCode,
      phoneNumber: sliceNum,
    };
    let newData =
      accountType === "corporate"
        ? { ...data, companyName: userAccount.companyName }
        : data;
    let res = await httpPost(`auth/register_user`, newData);

    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);

        return;
      }

      setUserAccount({
        type: "",
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        accountType: "",
        activeInput: "",
      });
      hideLoader();
      swal(
        "Account Created!",
        "In order to complete the sign-up process, please click the confirmation link sent to your registered email. If you do not receive a confirmation email, please check your spam folder also.",
        "success"
      ).then(() => {
        props.history.push("/login");
      });
    }
    hideLoader();
  };

  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  const [termAndConditionCheck, SettermAndConditionCheck] = useState(false);
  return (
    <>
      <SEO
        title="TheNewsPaperStand (TNS) | Sign up to Access Digital Newspapers & Magazines"
        description="Sign up for free to TheNewsPaperStand (TNS) to access digital replicas of Nigerian newspapers and magazines. Stay updated with the latest news, ePapers, and eMagazines from Nigeria's print media. Experience our online newsstand today!"
        url="https://app.thenewspaperstand.com/create_account"
      />
      <div className="login-wrap-v2">
        <div className="login-wrap-v2-col1">
          <AppLogo />

          {/* <div className="heroTextSection">
          <h1>
            I see you’re the first <br /> time here.
          </h1>
          <p>
            Maybe some text here will help me see it better. <br />
            Oh God. Oke, let’s do it then.{" "}
          </p>
          <button>Create Account</button>
        </div> */}
        </div>

        <div className="login-wrap-v2-col2">
          <div className="signUpOptonLogin">
            <p>
              Already have an Account?{" "}
              <span>
                {" "}
                <Link to="/login">Sign In</Link>
              </span>
            </p>
          </div>

          <div className="mobileViewLogoV2">
            <img src={Logo} alt="" />
          </div>

          <div className="login-wrapv2-main">
            <h1 className="login-txt-header">Sign Up</h1>
            <form>
              <div className="account-type-home accountTypehfo">
                <span>
                  Individual{" "}
                  <input
                    onClick={() => SetaccountType("individual")}
                    checked={accountType === "individual" ? true : false}
                    type="radio"
                    onChange={() => console.log("ch")}
                  />
                </span>
                <span>
                  Corporate{" "}
                  <input
                    onClick={() => SetaccountType("corporate")}
                    checked={accountType === "corporate" ? true : false}
                    type="radio"
                    onChange={() => console.log("ch")}
                  />
                </span>
              </div>

              {accountType === "individual" ? (
                <div>
                  <div className="inputWrap-v2">
                    <label>First Name</label>

                    <input
                      name="firstName"
                      onChange={handleChange}
                      placeholder="Enter first name"
                      type="text"
                      value={userAccount.firstName}
                    />
                    <span
                      style={
                        errorInput.firstName === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.firstName}
                    </span>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Last Name</label>
                    <div className="input-create-acc">
                      <input
                        name="lastName"
                        onChange={handleChange}
                        placeholder="Enter last name"
                        type="text"
                        value={userAccount.lastName}
                      />
                      <span
                        style={
                          errorInput.lastName === "Looks Good!"
                            ? { color: "green" }
                            : {}
                        }
                        className="AppError"
                      >
                        {errorInput.lastName}
                      </span>
                    </div>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Email address</label>
                    <div className="input-create-acc">
                      <input
                        name="email"
                        onChange={handleChange}
                        placeholder="Enter email address"
                        type="text"
                        value={userAccount.email}
                      />
                      <span
                        style={
                          errorInput.email === "Looks Good!"
                            ? { color: "green" }
                            : {}
                        }
                        className="AppError"
                      >
                        {errorInput.email}
                      </span>
                    </div>
                  </div>
                  <div className="inputWrap-v2 input-wrap-create-acc">
                    <label>Mobile Number</label>
                    <div className="PhoneInput">
                      <PhoneInput
                        defaultCountry="NG"
                        placeholder="Enter phone number"
                        value={userNumber}
                        onChange={setuserNumber}
                      />
                    </div>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Select Country</label>
                    <div className="input-create-acc input-wrap-create-acc ">
                      <Select
                        options={options}
                        value={userCountry}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Password</label>
                    <div className="input-create-acc">
                      <input
                        name="password"
                        onChange={handleChange}
                        placeholder="Enter password"
                        type="password"
                        value={userAccount.password}
                        type={`${showPass ? "text" : "password"}`}
                      />
                      <div className="togglePasscreAcc">
                        <i
                          onClick={toggleSeePasswordIcon}
                          className={`fas ${
                            showPass ? " fa-eye" : " fa-eye-slash"
                          }`}
                        ></i>
                        {/* <i class="fas fa-eye-slash"></i> */}
                      </div>

                      <span
                        style={
                          errorInput.password === "Looks Good!"
                            ? { color: "green" }
                            : {}
                        }
                        className="AppError"
                      >
                        {errorInput.password}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {accountType === "corporate" ? (
                <div>
                  <div className="inputWrap-v2">
                    <label>Company Name</label>

                    <input
                      name="companyName"
                      onChange={handleChange}
                      placeholder="Enter Company Name"
                      type="text"
                      value={userAccount.companyName}
                    />
                    <span
                      style={
                        errorInput.companyName === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.companyName}
                    </span>
                  </div>
                  <div className="inputWrap-v2">
                    <label>First Name</label>

                    <input
                      name="firstName"
                      onChange={handleChange}
                      placeholder="Enter first name"
                      type="text"
                      value={userAccount.firstName}
                    />
                    <span
                      style={
                        errorInput.firstName === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.firstName}
                    </span>
                  </div>
                  <div className="inputWrap-v2">
                    <label>Last Name</label>

                    <input
                      name="lastName"
                      onChange={handleChange}
                      placeholder="Enter last name"
                      type="text"
                      value={userAccount.lastName}
                    />
                    <span
                      style={
                        errorInput.lastName === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.lastName}
                    </span>
                  </div>
                  <div className="inputWrap-v2">
                    <label>Email address</label>

                    <input
                      name="email"
                      onChange={handleChange}
                      placeholder="Enter email address"
                      type="text"
                      value={userAccount.email}
                    />
                    <span
                      style={
                        errorInput.email === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.email}
                    </span>
                  </div>

                  <div className="inputWrap-v2 input-wrap-create-acc">
                    <label>Mobile Number</label>
                    <div className="PhoneInput">
                      <PhoneInput
                        defaultCountry="NG"
                        placeholder="Enter phone number"
                        value={userNumber}
                        onChange={setuserNumber}
                      />
                    </div>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Select Country</label>
                    <div className="input-create-acc input-wrap-create-acc ">
                      <Select
                        options={options}
                        value={userCountry}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>

                  <div className="inputWrap-v2">
                    <label>Password</label>

                    <input
                      name="password"
                      onChange={handleChange}
                      placeholder="Enter password"
                      type="password"
                      value={userAccount.password}
                    />
                    <span
                      style={
                        errorInput.password === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.password}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className="create-acc-newlette"
                style={{ marginTop: "40px" }}
              >
                <h1>Allow email communications:</h1>
                <p>
                  <input
                    onClick={() => setEmailOptionText(!emailOptionText)}
                    type="checkbox"
                  />
                  General Update, New Issues and topic trends
                </p>
              </div>

              <div
                style={{ marginTop: "23px" }}
                className="create-acc-newlette"
              >
                <h1>Terms and condition:</h1>
                <p>
                  <input
                    checked={termAndConditionCheck}
                    onClick={() =>
                      SettermAndConditionCheck(!termAndConditionCheck)
                    }
                    type="checkbox"
                  />
                  I understand and agree to the{" "}
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/terms-conditions/"
                  >
                    <span style={{ color: "orange", cursor: "pointer" }}>
                      TNS terms and condition
                    </span>
                  </a>
                  and
                  <br className="hdCreM" />
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/privacy-policy/"
                  >
                    <span
                      style={{
                        color: "orange",
                        cursor: "pointer",
                        marginLeft: "32px",
                        top: "-7px",
                        position: "relative",
                      }}
                      className="pPMr"
                    >
                      Privacy Policy
                    </span>
                  </a>
                </p>
              </div>

              <div className="loginBtnV2" style={{ marginBottom: "30px" }}>
                <button onClick={handleSubmit}>Create my account</button>
              </div>

              <div className="haveanAccountV2Op">
                <p>
                  {" "}
                  Already have an Account?
                  <Link to="/login"> SIGN IN</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
