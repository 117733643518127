import React, { useState, useEffect } from "react";
import icons from "../../components/tables/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "../../components/helpers/dateFormater";

export default function TransationTable(props) {
  useEffect(() => {
    // getDownloadAciveLink();
  }, []);
  const getDownloadAciveLink = (data) => {
    console.log(JSON.parse(data));
    let pdf = JSON.parse(data);
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === pdf.length - 1) clearInterval(intervalId);
      window.location.href = pdf[currentIndex].path;
      currentIndex++;
    }, 1000);
  };
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        className="appTab"
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          showTitle: false,
        }}
        columns={[
          // { title: "Name", field: "oldName" },
          { title: "Publication Name", field: "newName" },
          { title: "Publication date", field: "upDate" },
          { title: "Status", field: "status" },
          { title: "Date Applied", field: "DateApplied" },
          //   { title: "Full new name", field: "newName" },
        ]}
        data={props.applications?.map((data) => {
          return {
            newName: data.publication.name,
            upDate: `${dateFormater(data?.updatedAt)}`,
            status:
              data.status == "completed" ? (
                <p
                  // href={JSON.parse(data?.requestDates).map((data) => {
                  //   return data.path;
                  // })}
                  // download
                  // rel="noopener noreferrer"
                  // target="_blank"
                  style={{ color: "green" }}
                  onClick={() => getDownloadAciveLink(data.requestDates)}
                >
                  Completed
                </p>
              ) : (
                <p>{data.status}</p>
              ),
            DateApplied: `${JSON.parse(data?.requestDates).map((data) => {
              return dateFormater(data.date);
            })}`,
          };
        })}
      />
    </div>
  );
}
