import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { UserDataContext } from "../../context/userContext";
import { CartContextData } from "../../context/newsPaperContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  httpPostFormData,
  httpPut,
  httpPatch,
  httpGet,
  httpPost,
  httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import NotFound from "../../components/helpers/notFound";
import Subscrptions from "../../pages/subscriptions/mySubs";

import "./index.css";
import dateFormater from "./../../components/helpers/dateFormater";
import ActiveSubscriptions from "../../components/MySubscription/ActiveSubscriptions";
import ExpiredSubscriptions from "../../components/MySubscription/ExpiredSubscriptions";

export default function Subscription(props) {
  // CONTEXT API
  const { UserInfo, setLoginModal } = useContext(UserDataContext);
  const { setcartPage, onOpenModal } = useContext(CartContextData);

  // USE STATES
  const [Showloading, setLoading] = useState(false);
  const [loadData, setloadData] = useState(true);
  const [Usersubscriptions, SetUsersubscriptions] = useState([]);

  // HANDLERS
  const Usersubscriptionsfun = async (id) => {
    showLoader();
    const res = await httpGet(`my_subscriptions`);
    if (res) {
      if (res.er) {
        hideLoader();
        console.log("subError", res.er);
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        setloadData(false);
        return;
      }
      hideLoader();
      SetUsersubscriptions(res.data.subscriptions);
      setLoading(false);
      setloadData(false);

      console.log(res.data);
    }
  };

  const cancelSubscription = async (id) => {
    showLoader();
    const res = await httpPost(`cancel_subscription/${id}`);
    if (res) {
      if (res.er) {
        hideLoader();
        console.log("subError", res.er);
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      hideLoader();

      NotificationManager.success("Successfully cancelled subscription");

      console.log(res.data);
    }
  };

  useEffect(() => {
    setcartPage(props.history.location.pathname);
    console.log(props.history.location.pathname);
    Usersubscriptionsfun();
    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);

  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "10px" }} className="section-wrap">
        <div className="subscription-wrap">
          <div className="col-one-subscription">
            <p>Publish your own content and make money</p>
            <button>Start Publishing</button>
          </div>
          <div className="col-two-subscription">
            {loadData === true ? (
              <div className="row h-100 justify-content-center align-items-center app-spinner">
                <Loader
                  type="TailSpin"
                  color="#EF6C00"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <div>
                {Usersubscriptions.length === 0 ? (
                  <NotFound
                    header="You don't have any subscription yet"
                    body="Explore our resources and subscribe to the ones you like the most so you
                don’t lose sight of them."
                  />
                ) : (
                  <>
                    <ActiveSubscriptions
                      data={Usersubscriptions.filter(
                        (data) => data.status === "active"
                      )}
                      viewPaper={props.history}
                    />
                    <ExpiredSubscriptions
                      data={Usersubscriptions.filter(
                        (data) => data.status === "expired"
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
