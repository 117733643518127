import React, { useState, useContext, useRef } from "react";
import { useRecoilState } from "recoil";
import { toggleSidebar } from "../../globalState/localData";
import { CartContextData } from "../../context/newsPaperContext";
import x from "../../assets/x.png";
import box from "../../assets/box.png";
import calendar from "../../assets/calendar.png";
import mdi_sort from "../../assets/mdi_sort.png";
import cate from "../../assets/cate.png";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { DisabledItemsContext } from "../../context/DisabledItemsContext";
export default function Sidebar(props) {
  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);
  const {
    setfilterType,
    UserCatgories,
    setFrequencyType,
    categoriesType,
    setCategoriesType,
  } = useContext(CartContextData);

  // FOR NONE WORKING ITEMS AND ITEMS TO DISABLE
  const { showPublicNotice } = useContext(DisabledItemsContext);

  const [PublicationShow, SetPublicationShow] = useState(true);
  const [frequencyShow, setFrequencyShow] = useState(true);
  const [categoriesShow, setCategoriesShow] = useState(false);
  const [SortShow, SetSortShow] = useState(false);
  const [DateShow, SetDateShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const toolTip = useRef(null);

  const [PublicationsFilter, SetPublicationsFilter] = useState({
    all: true,
    newsPaper: false,
    magazine: false,
    books: false,
  });

  const [frequencyFilter, setFrequencyFilter] = useState({
    all: true,
    daily: false,
    weekly: false,
    monthly: false,
    quaterly: false,
    yearly: false,
  });

  const handleFrequencyFilter = (type) => {
    if (type === "all") {
      setFrequencyType("all");
      setFrequencyFilter({
        all: true,
        daily: false,
        weekly: false,
        monthly: false,
        quaterly: false,
        yearly: false,
      });
    }
    if (type === "daily") {
      setFrequencyType("daily");
      setFrequencyFilter({
        all: false,
        daily: true,
        weekly: false,
        monthly: false,
        quaterly: false,
        yearly: false,
      });
    }
    if (type === "weekly") {
      setFrequencyType("weekly");
      setFrequencyFilter({
        all: false,
        daily: false,
        weekly: true,
        monthly: false,
        quaterly: false,
        yearly: false,
      });
    }
    if (type === "monthly") {
      setFrequencyType("monthly");
      setFrequencyFilter({
        all: false,
        daily: false,
        weekly: false,
        monthly: true,
        quaterly: false,
        yearly: false,
      });
    }
    if (type === "quaterly") {
      setFrequencyType("quaterly");
      setFrequencyFilter({
        all: false,
        daily: false,
        weekly: false,
        monthly: false,
        quaterly: true,
        yearly: false,
      });
    }
    if (type === "annualy") {
      setFrequencyType("annualy");
      setFrequencyFilter({
        all: false,
        daily: false,
        weekly: false,
        monthly: false,
        quaterly: false,
        annualy: true,
      });
    }
  };
  const handleTypeFilter = (type) => {
    if (type === "all") {
      setfilterType("all");
      SetPublicationsFilter({
        all: true,
        magazine: false,
        books: false,
        newsPaper: false,
      });
    }

    if (type === "Newspaper") {
      setfilterType("Newspaper");
      SetPublicationsFilter({
        all: false,
        magazine: false,
        books: false,
        newsPaper: true,
      });
    }

    if (type === "Magazines") {
      setfilterType("Magazines");
      SetPublicationsFilter({
        all: false,
        magazine: true,
        books: false,
        newsPaper: false,
      });
    }

    if (type === "Books") {
      setfilterType("Books");
      SetPublicationsFilter({
        all: false,
        magazine: false,
        books: true,
        newsPaper: false,
      });
    }
  };

  const [SortFilter, SetSortFilter] = useState({
    popular: true,
    recent: false,
  });
  let currentRoute = props.currentRoute;

  return (
    <div
      className={`responsiveSideBar ${
        sidebarState.openSidebar === true
          ? "responsiveSideBarOpen"
          : sidebarState.defaultSidebarApiState
      }`}
    >
      <div className="sidebar-wrap">
        <Scrollbars
          style={{ width: 241, height: "100vh " }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          <ul style={{ paddingBottom: "60px" }} className="sidebar-list">
            <span className="sidebar-header">
              <span>FILTERS & SERVICES</span>
              <img
                onClick={() =>
                  setSidebarState({
                    ...sidebarState,
                    openSidebar: !sidebarState.openSidebar,
                    defaultSidebarApiState: "AppCloseSidebar",
                  })
                }
                src={x}
                title="Close Sidebar"
                alt="img-data"
              />
            </span>

            <li
              onClick={() => props.browserRouter(`/home`)}
              className={currentRoute === "/home" ? "" : ""}
            >
              <div className="inner-navlist">
                <div>
                  <img src={box} alt="img-data" />{" "}
                  <span className="inner-navHeader">Publications</span>
                </div>
                <i
                  onClick={() => SetPublicationShow(!PublicationShow)}
                  className={`fa fa-chevron-${
                    PublicationShow === true ? "up" : "down"
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
              {PublicationShow === false ? (
                ""
              ) : (
                <div className="innerList-drop-down">
                  <span>
                    <input
                      onChange={() => handleTypeFilter("all")}
                      onClick={() => handleTypeFilter("all")}
                      checked={PublicationsFilter.all === true ? true : false}
                      type="checkbox"
                    />
                    All
                  </span>
                  <span>
                    <input
                      onChange={() => handleTypeFilter("Newspaper")}
                      onClick={() => handleTypeFilter("Newspaper")}
                      checked={
                        PublicationsFilter.newsPaper === true ? true : false
                      }
                      type="checkbox"
                    />
                    Newspaper
                  </span>
                  <span>
                    <input
                      onChange={() => handleTypeFilter("Magazines")}
                      onClick={() => handleTypeFilter("Magazines")}
                      checked={
                        PublicationsFilter.magazine === true ? true : false
                      }
                      type="checkbox"
                    />
                    Magazine
                  </span>{" "}
                  <span>
                    <input
                      onChange={() => handleTypeFilter("Books")}
                      onClick={() => handleTypeFilter("Books")}
                      checked={PublicationsFilter.books === true ? true : false}
                      type="checkbox"
                    />
                    Books
                  </span>{" "}
                </div>
              )}
            </li>
            <li
              onClick={() => props.browserRouter(`/home`)}
              className={currentRoute === "/home" ? "" : ""}
            >
              <div className="inner-navlist">
                <div>
                  <img src={box} alt="img-data" />{" "}
                  <span className="inner-navHeader">Frequency</span>
                </div>
                <i
                  onClick={() => setFrequencyShow(!frequencyShow)}
                  className={`fa fa-chevron-${
                    frequencyShow === true ? "up" : "down"
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
              {frequencyShow === false ? (
                ""
              ) : (
                <div className="innerList-drop-down">
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("all")}
                      onClick={() => handleFrequencyFilter("all")}
                      checked={frequencyFilter.all === true ? true : false}
                      type="checkbox"
                    />
                    All
                  </span>
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("daily")}
                      onClick={() => handleFrequencyFilter("daily")}
                      checked={frequencyFilter.daily === true ? true : false}
                      type="checkbox"
                    />
                    Daily
                  </span>
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("weekly")}
                      onClick={() => handleFrequencyFilter("weekly")}
                      checked={frequencyFilter.weekly === true ? true : false}
                      type="checkbox"
                    />
                    Weekly
                  </span>
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("monthly")}
                      onClick={() => handleFrequencyFilter("monthly")}
                      checked={frequencyFilter.monthly === true ? true : false}
                      type="checkbox"
                    />
                    Monthly
                  </span>
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("quaterly")}
                      onClick={() => handleFrequencyFilter("quaterly")}
                      checked={frequencyFilter.quaterly === true ? true : false}
                      type="checkbox"
                    />
                    Quarterly
                  </span>
                  <span>
                    <input
                      onChange={() => handleFrequencyFilter("annualy")}
                      onClick={() => handleFrequencyFilter("annualy")}
                      checked={frequencyFilter.annualy === true ? true : false}
                      type="checkbox"
                    />
                    Annually
                  </span>{" "}
                </div>
              )}
            </li>
            <li
              onClick={() => props.browserRouter(`/home`)}
              className={currentRoute === "/home" ? "" : ""}
            >
              <div className="inner-navlist">
                <div>
                  <img src={box} alt="img-data" />{" "}
                  <span className="inner-navHeader">Categories</span>
                </div>
                <i
                  onClick={() => setCategoriesShow(!categoriesShow)}
                  className={`fa fa-chevron-${
                    categoriesShow === true ? "up" : "down"
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
              {categoriesShow === false ? (
                ""
              ) : (
                <div className="innerList-drop-down">
                  <span>
                    <input
                      onChange={() =>
                        setCategoriesType({ name: "all", id: null })
                      }
                      onClick={() =>
                        setCategoriesType({ name: "all", id: null })
                      }
                      checked={categoriesType?.id === null ? true : false}
                      type="checkbox"
                    />
                    All
                  </span>
                  {UserCatgories?.map((item) => (
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      <input
                        onChange={() =>
                          setCategoriesType({ name: item?.name, id: item?.id })
                        }
                        onClick={() =>
                          setCategoriesType({ name: item?.name, id: item?.id })
                        }
                        checked={categoriesType.id === item?.id ? true : false}
                        type="checkbox"
                      />
                      {item?.name}
                    </span>
                  ))}
                </div>
              )}
            </li>

            <div className="sidebar-hr"> </div>

            <div className="sidebar-hr"> </div>
            <li
              onClick={() => props.browserRouter(`/home`)}
              className={`sidebar-quick-access ${
                currentRoute === "/home" ? "" : ""
              }`}
            >
              <div className="inner-navlist">
                <div>
                  <span className="inner-navHeader">Quick Services</span>
                </div>
              </div>
            </li>

            <div
              style={{
                width: "243px",
                border: "1px solid rgba(0, 0, 0, 0.4)",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.06)",
              }}
              className="sidebar-hr"
            >
              {" "}
            </div>
            <li
              ref={toolTip}
              data-tip="Coming Soon"
              onClick={() => {
                props.browserRouter(`/archive`);
                setSidebarState({
                  ...sidebarState,
                  openSidebar: !sidebarState.openSidebar,
                  defaultSidebarApiState: "AppCloseSidebar",
                });
              }}
              className={`sidebar-quick-access-link ${
                currentRoute === "/archive" ? "" : ""
              }`}
            >
              <div className="inner-navlist">
                <div>
                  <span className="inner-navHeader">Request Newspaper</span>
                </div>
              </div>
            </li>

            <div
              style={{
                width: "243px",
                border: "1px solid rgba(0, 0, 0, 0.4)",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.06)",
              }}
              className="sidebar-hr"
            >
              {" "}
            </div>
            <li
              ref={toolTip}
              data-tip="Coming Soon"
              onClick={() => {
                props.browserRouter(`/changeofname`);

                setSidebarState({
                  ...sidebarState,
                  openSidebar: !sidebarState.openSidebar,
                  defaultSidebarApiState: "AppCloseSidebar",
                });
              }}
              className={`sidebar-quick-access-link ${
                currentRoute === "/changeofname" ? "" : ""
              }`}
            >
              <div className="inner-navlist">
                <div>
                  <span className="inner-navHeader">Change Of Name</span>
                </div>
              </div>
            </li>

            <div
              style={{
                width: "243px",
                border: "1px solid rgba(0, 0, 0, 0.4)",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.06)",
              }}
              className="sidebar-hr"
            >
              {" "}
            </div>

            {/* DISABLED "PUBLIC NOTICE" */}
            {showPublicNotice ? (
              <>
                <li
                  ref={toolTip}
                  data-tip="Coming Soon"
                  onClick={() => {
                    props.browserRouter(`/public_notice`);
                    setSidebarState({
                      ...sidebarState,
                      openSidebar: !sidebarState.openSidebar,
                      defaultSidebarApiState: "AppCloseSidebar",
                    });
                  }}
                  className={`sidebar-quick-access-link ${
                    currentRoute === "/public_notice" ? "" : ""
                  }`}
                >
                  <div className="inner-navlist">
                    <div>
                      <span className="inner-navHeader">Public Notice</span>
                    </div>
                  </div>
                </li>
                <div
                  style={{
                    width: "243px",
                    border: "1px solid rgba(0, 0, 0, 0.4)",
                    boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.06)",
                  }}
                  className="sidebar-hr"
                >
                  {" "}
                </div>
              </>
            ) : null}

            <li
              className={`sidebar-quick-access-link ${
                currentRoute === "/home" ? "" : ""
              }`}
              onClick={() => {
                window.open(
                  "https://vendor.thenewspaperstand.com/register",
                  "_blank"
                );
                setSidebarState({
                  ...sidebarState,
                  openSidebar: !sidebarState.openSidebar,
                  defaultSidebarApiState: "AppCloseSidebar",
                });
              }}
            >
              <div className="inner-navlist">
                <div>
                  <span className="inner-navHeader">Publish your content</span>
                </div>
              </div>
            </li>
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
}
