import React, { useState, useEffect } from "react";
// import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
} from "../../components/helpers/validateInput";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  httpPostFormData,
  httpPut,
  httpPatch,
  httpGet,
  httpPost,
  httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
export default function Login(props) {
  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async (e) => {
    showLoader();
    Swal.fire(
      "verifying Account",
      "Please wait while we set up your account.",
      "info"
    );
    let res = await httpPost(`auth/user/verify/${props.match.params.id}`);

    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }

      hideLoader();
      NotificationManager.success("Account verified successfully.");
      props.history.push("/login");
    }
  };

  return <div className="login-wrap-home"></div>;
}
