import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { UserDataContext } from "../../context/userContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  httpPostFormData,
  httpPut,
  httpPatch,
  httpGet,
  httpPost,
  httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import ba from "../../assets/ba.png";

import "./index.css";
export default function Subscription(props) {
  const { UserInfo } = useContext(UserDataContext);

  useEffect(() => {
    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);

  const [Showloading, setLoading] = useState(false);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }} className="section-wrap">
          <div className="following-wrap">
            {[..."1ggghg23"].map((data) => {
              return (
                <div className="following-cards">
                  <div className="following-img-wrap">
                    <img src={ba} alt="" />
                  </div>
                  <div
                    style={{
                      width: "70%",
                      marginLeft: "24px",
                      marginBottom: "6px",
                    }}
                    className="hr-sub"
                  ></div>
                  <div className="following-uname">@punchnewspaper</div>
                  <div style={{ marginTop: "7px" }} className="fol-fol">
                    <span style={{ fontWeight: "600", color: "black" }}>2</span>{" "}
                    Pages
                  </div>
                  <div className="fol-fol">
                    <span style={{ fontWeight: "600", color: "black" }}>
                      Book Marked on
                    </span>{" "}
                    10/12/21
                  </div>
                  <div className="continue-reading">Continue Reading</div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
