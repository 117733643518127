import React, { createContext, useState, useEffect, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Loader from "react-loader-spinner";
import Select from "react-select";
import { UserDataContext } from "../../context/userContext";
import { PaymentContext } from "../../context/paymentContext";
import { httpGet, httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import ba from "../../assets/ba.png";
import SubAccounts from "../../components/tables/subAccount";

import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "./index.css";

const CustomStyle = {
  option: (base, state) => ({
    ...base,
    fontSize: 12,
  }),
  menu: (provided, state) => ({
    ...provided,
    // paddingBottom: '50px',
    // height: '50%',
    overflow: "hidden",
    maxHeight: 200,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 200, // your desired height
  }),
  control: (provided, state) => ({
    ...provided,
    // height: 100,
  }),
};

export default function Subscription(props) {
  const { UserInfo, userLocation, setLoginModal } = useContext(UserDataContext);
  const {
    setgetPublicationDetails,
    sendPaymentDetailsToBackend,
    HandlePayWithMonifyFun,
    subAccountSuccess,
    SetsubAccountSuccess,
  } = useContext(PaymentContext);

  const [Showloading, setLoading] = useState(false);
  const [showAddSubAccountModal, setAddSubAccountModal] = useState(false);
  const [publicationsAV, setpublicationsAV] = useState([]);
  const [allMySubAccountsData, setallMySubAccountsData] = useState([]);
  const [options, setOptions] = useState([]);

  const [userNumber, setuserNumber] = useState("");
  const [buyForMeDetails, setBuyForMeDetails] = useState({
    firstName: "",
    email: "",
    publicationId: null,
    subType: "",
    amount: "0.0",
    isPaid: false,
  });

  useEffect(() => {
    if (subAccountSuccess == true) {
      getAllSubAccounts();
    }
  }, [subAccountSuccess]);

  useEffect(() => {
    SetsubAccountSuccess(false);
  }, []);

  const handleAddSubAccountFun = (e) => {
    e.preventDefault();
    if (
      buyForMeDetails.firstName === "" ||
      buyForMeDetails.lastName === "" ||
      buyForMeDetails.email === "" ||
      buyForMeDetails.publicationId === null ||
      buyForMeDetails.subType === ""
    ) {
      return NotificationManager.error(
        "All field is required to procceed",
        "Opps"
      );
    }
    setgetPublicationDetails("subAccount");
    let countryCode = `+${parsePhoneNumber(userNumber)?.countryCallingCode}`;
    let sliceNum = userNumber?.slice(-10);
    const data = {
      amount: buyForMeDetails.amount,
      subscriptionType: buyForMeDetails.subType,
      currency: userLocation === "Nigeria" ? "NGN" : "USD",
      publicationId: buyForMeDetails.publicationId,
      isPaid: buyForMeDetails.isPaid,
      firstName: buyForMeDetails.firstName,
      lastName: buyForMeDetails.lastName,
      email: buyForMeDetails.email,
      phoneNumber: sliceNum,
      countryCode,
      type: "CARD",
    };

    // return console.log(data);
    sendPaymentDetailsToBackend(
      buyForMeDetails.amount,
      buyForMeDetails.subType,
      userLocation === "Nigeria" ? "NGN" : "USD",
      buyForMeDetails.publicationId,
      buyForMeDetails.isPaid,
      buyForMeDetails.firstName,
      buyForMeDetails.lastName,
      buyForMeDetails.email
    );
    // HandlePayWithMonifyFun(data);
    setAddSubAccountModalFun();
  };
  const Publication = async () => {
    try {
      const res = await httpGet(`publications`);
      setpublicationsAV(res.data.publications);
    } catch (error) {
      return;
    }
  };

  const getAllSubAccounts = async (id) => {
    showLoader();
    let res = await httpGet(`bought_by_me`);
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      setallMySubAccountsData(res.data.subscriptions);
      hideLoader();
    }
  };

  const cancelSubscription = async (id) => {
    showLoader();
    let res = await httpPost(`cancel_buy_for_me_subscription/${id}`);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      hideLoader();
      getAllSubAccounts();
      NotificationManager.success(res.message);
    }
  };

  const setAddSubAccountModalFun = () => {
    setAddSubAccountModal(!showAddSubAccountModal);
    setgetPublicationDetails("subAccount");
  };
  const [getSubAmount, setSubAmount] = useState("");
  const handleSubType = (e) => {
    console.log(e.value);
    setBuyForMeDetails({
      ...buyForMeDetails,
      subType: e.value,
    });

    let getAmount = publicationsAV.filter((data) => {
      return data.id === buyForMeDetails.publicationId;
    });
    console.log(getAmount);
    if (e.value === "one-off") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.oneOffFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "one-off",
      });
    }

    if (e.value === "weekly") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.weeklyFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "weekly",
      });
    }

    if (e.value === "quarterly") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.quarterlyFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "weekly",
      });
    }

    if (e.value === "monthly") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.monthlyFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "monthly",
      });
    }

    if (e.value === "annual") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.annualFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "annual",
      });
    }

    if (e.value === "biAnnual") {
      setBuyForMeDetails({
        ...buyForMeDetails,
        amount: getAmount[0]?.biannuallyFee,
        isPaid: getAmount[0]?.isPaid,
        subType: "annual",
      });
    }
  };

  const getPublicationIdAndIspaid = (e) => {
    setBuyForMeDetails({
      ...buyForMeDetails,
      publicationId: e.target.value,
      amount: "",
      subType: "",
    });

    console.log(">>>>", e.target);
  };

  const handleSelect = (val) => {
    setBuyForMeDetails({
      ...buyForMeDetails,
      publicationId: val.id,
      amount: "",
      subType: "",
    });

    console.log(">>>>", val.id);
    let data = publicationsAV.filter((data) => {
      return data.id === val.id;
    });
    let option = [];
    if (data[0].oneOffFee !== null && Number(data[0].oneOffFee) > 0) {
      option.push({ value: "one-off", label: "One off Plan" });
    }
    if (data[0].weeklyFee !== null && Number(data[0].weeklyFee) > 0) {
      option.push({ value: "weekly", label: "Weekly Plan" });
    }
    if (data[0].monthlyFee !== null && Number(data[0].monthlyFee) > 0) {
      option.push({ value: "monthly", label: "Monthly Plan" });
    }
    if (data[0].quarterlyFee !== null && Number(data[0].quarterlyFee) > 0) {
      option.push({ value: "quarterly", label: "Quarterly Plan" });
    }
    if (data[0].annualFee !== null && Number(data[0].annualFee) > 0) {
      option.push({ value: "annual", label: "Annual Plan" });
    }
    if (data[0].biannuallyFee !== null && Number(data[0].biannuallyFee) > 0) {
      option.push({ value: "biAnnual", label: "Bi Annual Plan" });
    }
    setOptions(option);
  };
  console.log("///", options);

  useEffect(() => {
    getAllSubAccounts();
    Publication();
    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      setgetPublicationDetails(null);
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);
  console.log(publicationsAV);

  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }} className="section-wrap">
          <SubAccounts
            setAddSubAccountModalFun={setAddSubAccountModalFun}
            allMySubAccountsData={allMySubAccountsData}
            cancelSubscription={cancelSubscription}
          />
        </div>
        <Modal open={showAddSubAccountModal} onClose={setAddSubAccountModalFun}>
          <div
            style={{ backgroundColor: "transparent" }}
            className="inner-modal-wrap-tr "
          >
            <div className="waletBalanceModalWrap">
              <div className="addSubAccountModalFormWrap">
                <div className="moadlHeader" style={{ marginBottom: "20px" }}>
                  Please fill in the details to add a sub account.
                </div>
                <form className="subAccForm">
                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel"> Email</label>
                    <input
                      className="subAccountInputtag"
                      placeholder="sub@gmail.com"
                      type="text"
                      onChange={(e) =>
                        setBuyForMeDetails({
                          ...buyForMeDetails,
                          email: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel">
                      First Name
                    </label>
                    <input
                      className="subAccountInputtag"
                      type="text"
                      onChange={(e) =>
                        setBuyForMeDetails({
                          ...buyForMeDetails,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel">Last Name</label>
                    <input
                      className="subAccountInputtag"
                      type="text"
                      onChange={(e) =>
                        setBuyForMeDetails({
                          ...buyForMeDetails,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* <div className='subAccountInputWraphgf'>
                    <label className='subAccountInputtaglabel'>Last Name</label>
                    <input
                      className='subAccountInputtag'
                      type='text'
                      onChange={(e) =>
                        setBuyForMeDetails({
                          ...buyForMeDetails,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div> */}

                  {/* <div className='subAccountInputWraphgf'>
                    <label className='subAccountInputtaglabel'>
                      Phone Number
                    </label>

                    <PhoneInput
                      className='subAccountInputtag'
                      defaultCountry='NG'
                      placeholder='Enter phone number'
                      value={userNumber}
                      onChange={setuserNumber}
                    />
                  </div> */}

                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel">
                      Select Publication
                    </label>
                    <Select
                      className="react-select"
                      options={publicationsAV}
                      getOptionLabel={(option) => `${option?.name}`}
                      onChange={handleSelect}
                      getOptionValue={(option) => `${option}`}
                      isOptionSelected={(option) =>
                        buyForMeDetails.publicationId === option.id
                          ? true
                          : false
                      }
                      styles={CustomStyle}
                    />
                    {/* <select
                      className='subAccountInputtag'
                      value={buyForMeDetails.publicationId}
                      onChange={(e) => console.log(e.target.value)}
                    >
                      <option>Select</option>
                      {publicationsAV
                        ? publicationsAV.map((data) => {
                            return (
                              <option value={data.id} key={data.id}>
                                {data.name}
                              </option>
                            );
                          })
                        : []}
                    </select> */}
                  </div>

                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel">
                      Subscription Type
                    </label>
                    <Select
                      className="react-select"
                      options={options}
                      onChange={handleSubType}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option?.label}`}
                      isOptionSelected={(option) =>
                        buyForMeDetails.publicationId === option.id
                          ? true
                          : false
                      }
                      styles={CustomStyle}
                    />
                    {/* <select
                      name=''
                      id=''
                      onChange={handleSubType}
                      className='subAccountInputtag'
                      value={buyForMeDetails.subType}
                    >
                      <option value=''>Select</option>
                      <option value='one-off'>One off Plan</option>
                      <option value='weekly'>Weekly Plan</option>
                      <option value='monthly'>Monthly Plan</option>
                      <option value='annual'>Annual Plan</option>
                    </select> */}
                  </div>

                  <div className="subAccountInputWraphgf">
                    <label className="subAccountInputtaglabel">
                      Plan Amount
                    </label>
                    <input
                      disabled
                      className="subAccountInputtag"
                      value={
                        buyForMeDetails.amount === null
                          ? "Free"
                          : buyForMeDetails.amount
                      }
                      placeholder=""
                      type="text"
                      onChange={(e) =>
                        setBuyForMeDetails({
                          ...buyForMeDetails,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="handleSubMe">
                    <button onClick={handleAddSubAccountFun}>Continue</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
