import React, { useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
} from "../../components/helpers/validateInput";
import { NotificationManager } from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { setLocalItem } from "../../components/helpers/authService";

import { Modal } from "react-responsive-modal";
export default function ModalLogin({
  ToggleLoginModal,
  setLoginModal,
  setReloadExpToken,
  sLoginM,
  LogUserOut,
}) {
  useEffect(() => {
    // showLogModalFun();
  }, [sLoginM]);
  //   const showLogModalFun = () => {
  //     if (sLoginM === "true") {
  //       setLoginModal();
  //     }
  //   };

  const [userAccount, setUserAccount] = useState({
    email: "",
    password: "",
  });
  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      errorInput.password === "Looks Good!" &&
      errorInput.email === "Looks Good!"
    ) {
      showLoader();
      const data = {
        email: userAccount.email,
        password: userAccount.password,
      };
      let res = await httpPost(`auth/user_login`, data);

      if (res) {
        if (res.er) {
          NotificationManager.error(res.er.message);

          return;
        }
        setUserAccount({
          email: "",
          password: "",
        });
        // checkUserIsLogin();
        // GetUserInfoFromStorge();
        localStorage.setItem("token", res.data.token);
        console.log(res.data);
        setLocalItem("user", res.data.user);
        localStorage.setItem("expiredToken", "false");
        NotificationManager.success(`Welcome Back ${res.data.user.firstName}.`);
        setReloadExpToken(true);
        localStorage.setItem("showModal", "false");
        setLoginModal();
        hideLoader();
      }
    }

    hideLoader();
  };
  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  return (
    <div>
      <Modal
        open={ToggleLoginModal}
        onClose={setLoginModal}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <div className="inner-modal-wrap-mLogin">
          <div
            style={{ marginBottom: "0px" }}
            className="login-wrap-home-input"
          >
            <h1 className="login-modal-header-text">
              Your session has expired
              <br />
              please login again
            </h1>
            <form className="login-form-wrap">
              <div className="login-col-three-home">
                <div className="inPutHomeWrapP">
                  <input
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter email address"
                    type="text"
                    value={userAccount.email}
                  />
                  <div className="error-container">
                    <span
                      style={
                        errorInput.email === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.email}
                    </span>
                  </div>
                  <input
                    style={{ marginTop: "15px" }}
                    name="password"
                    onChange={handleChange}
                    placeholder="Enter password"
                    value={userAccount.password}
                    type={`${showPass ? "text" : "password"}`}
                  />
                  <div className="toggleViewPassword">
                    <i
                      onClick={toggleSeePasswordIcon}
                      className={`fas ${
                        showPass ? " fa-eye" : " fa-eye-slash"
                      }`}
                    ></i>
                    {/* <i class="fas fa-eye-slash"></i> */}
                  </div>

                  <div className="error-container">
                    <span
                      style={
                        errorInput.password === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.password}
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="submit-login-details-home">
              <button onClick={handleSubmit}>Login to your account</button>
            </div>
            <div className="logoutBtnLoginModal">
              <span onClick={LogUserOut}>LOGOUT</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
