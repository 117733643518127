import React, { useState } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
export default function DataTable(props) {
  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);

  const handlePopOver = (id) => {
    console.log("CLicking>>>");
    setShow(!show);
    if (show) {
      setPopOver([id]);
    }
  };
  const data = [
    {
      Device: "Desktop ",
      Browser: "macOS Catalina 10.15",
      OS: "Chrome Dev 86.0.4240.193",
      Location: "Lagos",
      LastLogin: "30/11/2020 11:32:18",

      Status: "Active",
      Action: (
        <select>
          <option value="One Off">Block</option>
        </select>
      ),
    },
    {
      Device: "Desktop ",
      Browser: "macOS Catalina 10.15",
      OS: "Chrome Dev 86.0.4240.193",
      Location: "Lagos",
      LastLogin: "30/11/2020 11:32:18",

      Status: "Inactive",
      Action: (
        <select>
          <option value="One Off">Unblock</option>
        </select>
      ),
    },

    {
      Device: "Desktop ",
      Browser: "macOS Catalina 10.15",
      OS: "Chrome Dev 86.0.4240.193",
      Location: "Lagos",
      LastLogin: "30/11/2020 11:32:18",

      Status: "Active",
      Action: (
        <select>
          <option value="One Off">Block</option>
        </select>
      ),
    },
  ];

  return (
    <div classDevice="table-wrap border styleTable">
      <div style={{ backgroundColor: "white" }} className="mbbfed9hyt">
        This list displays all the devices from which access have been made from
        your account. There is a limit of devices from which you can connect to
        the this portal. If your current device is not on the list of active
        devices, you can block any of the active ones and activate yours.
        Remember that your account is exclusively personal and can't be shared
        with others.
      </div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Device", field: "Device" },
          { title: "OS", field: "OS" },
          { title: "Browser", field: "Browser" },
          { title: " Location", field: "Location" },
          { title: " Last Login", field: "LastLogin" },
          { title: " Status", field: "Status" },

          { title: " Action", field: "Action" },
        ]}
        data={data.map((data) => {
          return {
            Device: data.Device,
            OS: data.OS,
            Browser: data.Browser,
            Location: data.Location,
            LastLogin: data.LastLogin,
            Active: data.Active,
            Status: data.Status,
            Action: data.Action,

            Check: (
              <div classDevice="LastLoginCheckbox">
                <input id="c1" type="checkbox" />
              </div>
            ),
          };
        })}
        title="My Devices"
      />
    </div>
  );
}
