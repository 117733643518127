import moment from "moment";
export function FormatMontly(date, type) {
  let formatedDate = moment(date, "DD-MM")
    .add(1, "months")
    .format("Do MMM, yyyy");
  return formatedDate;
}

export function FormatYearly(date, type) {
  let formatedDate = moment(date, "DD-MM-YYYY")
    .add(1, "years")
    .format("Do MMM, yyyy");
  return formatedDate;
}

export function FormatWeekly(date, type) {
  let formatedDate = moment(date, "DD").add(7, "days").format("Do MMM, yyyy");
  return formatedDate;
}

export function FormatDaily(date, type) {
  let formatedDate = moment(date, "DD").add(1, "days").format("Do MMM, yyyy");
  return formatedDate;
}

export function FormatQuarter(date, type) {
  let formatedDate = moment(date).add(1, "quarters").format("Do MMM, yyyy");
  return formatedDate;
}

export function FormatBiannul(date, type) {
  let formatedDate = moment(date).add(6, "months").format("Do MMM, yyyy");
  return formatedDate;
}
