import React, { useState, useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useRecoilState } from "recoil";
import { PublicationFilter } from "../../globalState/asyncCalls";
import "./home.css";
import NewspaperCards from "../../components/newpaperCrads/newspaperCards";
import MagazineCards from "../../components/newpaperCrads/magazineCard";
import BooksCards from "../../components/newpaperCrads/books";
import Loader from "react-loader-spinner";
import { CartContextData } from "../../context/newsPaperContext";
import { PaymentContext } from "../../context/paymentContext";
import { UserDataContext } from "../../context/userContext";
import { httpGet } from "../../components/helpers/httpMethods";
import Swal from "sweetalert2";
import ContentLoader, { Facebook } from "react-content-loader";
import TNSWebBanners3 from "../../assets/TNS-Web-Banners3.png";
import TNSWebBanners4 from "../../assets/TNS-Web-Banners4.png";
import TNSWebBanners5 from "../../assets/TNS-Web-Banners5.png";
import TNSWebBannersIndependentNewspapers from "../../assets/TNS-Web-Banners-Independent-Newspapers.png";
import TNSWebBanners from "../../assets/TNS-Web-Banners.png";

import HomeBannar2 from "../../assets/banner2.jpg";
import SEO from "../../components/Layout/SEO";

const CarouselData = [
  {
    image: TNSWebBanners3,
    link: "https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US",
  },
  {
    image: TNSWebBanners4,
    link: "https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US",
  },
  {
    image: TNSWebBannersIndependentNewspapers,
    link: "https://app.thenewspaperstand.com/publication/INDEPENDENT-NEWSPAPERS/",
  },
  {
    image: TNSWebBanners,
    link: "https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US",
  },
  {
    image: TNSWebBanners5,
    link: "https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US",
  },
];

export default function Home(props) {
  const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    // thumbWidth: number("thumbWidth", 100, {}, valuesGroupId),
    // selectedItem: number("selectedItem", 0, {}, valuesGroupId),
    interval: 6000,
    transitionTime: 290,
    // swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
  });
  const { filterType, categoriesType, frequencyType, setcartPage } =
    useContext(CartContextData);
  const {} = useContext(PaymentContext);

  const { userIsLogin, checkUserIsLogin, GetUserInfoFromStorge } =
    useContext(UserDataContext);

  useEffect(() => {
    setcartPage(props.history.location.pathname);
    setfilterPubs(filterType);
    getPublication();
    checkUserIsLogin();
    GetUserInfoFromStorge();
    localStorage.setItem("paidWithWallet", "false");
    // Swal.fire({
    //   icon: "warning",
    //   title: "Payment Gateway Failure",
    //   text:
    //     "We are currently having issues with our payment partner, therefore you can't pay with card at the moment. Kindly subscribe from your wallet balance or fund wallet via USSD transfer. We apologize for the inconvenience. Thank you for bearing with us",
    // });
    return () => {
      setcartPage("user/subscription");
    };
  }, []);
  const [Showloading, setLoading] = useState(true);
  const [filterPubs, setfilterPubs] = useState();
  const [magazines, setMagazines] = useState([]);
  const [Newspapers, setNewspapers] = useState([]);
  const [booksData, setbooksData] = useState([]);
  const [publications, setPublications] = useState([]);

  console.log("======", Newspapers);

  const getPublication = async () => {
    try {
      const res = await httpGet(`filtered_publication?type=all`);
      console.log("TESTING RESONSE", res);
      if (res?.success) {
        setPublications(res?.data?.publications);
      } else {
        setPublications([]);
      }

      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.log("TESTING RESONSE", error);

      return;
    }
  };

  useEffect(() => {
    let auxPubs =
      categoriesType?.id !== null
        ? publications?.filter((data) => {
            if (
              data?.editions?.map(
                (item) => item?.categoryId === categoriesType?.id
              )[0] === true
            ) {
              return data;
            }
          })
        : publications;

    let filterEditionsResMagazines = auxPubs.filter((data) => {
      return data.type.name === "magazines";
    });

    let filterEditionsResNewspaper = auxPubs.filter((data) => {
      return data.type.name === "newspapers";
    });

    let fBooks = auxPubs.filter((data) => {
      return data.type.name === "books";
    });
    setbooksData(fBooks);
    setMagazines(filterEditionsResMagazines);
    setNewspapers(filterEditionsResNewspaper);
  }, [publications, filterType, categoriesType]);

  return (
    <React.Fragment>
      <SEO
        title="TheNewsPaperStand (TNS) | Access Digital Replicas of Nigerian Newspapers & Magazines"
        description="Discover a vast collection of digital replicas of Nigerian newspapers and magazines on TheNewsPaperStand (TNS). Access current news, ePapers, and eMagazines online – providing the latest updates and insights from Nigeria's print media. Explore our online newsstand today!"
        url="https://app.thenewspaperstand.com"
      />
      <div className="slide-wrap-home hideSlideOn-md-screen">
        <Carousel {...getConfigurableProps()}>
          {CarouselData.map(({ image, link }, i) => (
            <a href={link} target="_blank">
              <div key={i} className="slide-wrap-users-home">
                <img src={image} alt="banner" />
              </div>
            </a>
          ))}

          {/* <a
            href="https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US"
            target="_blank"
          >
            <div className="slide-wrap-users-home">
              <img src={TNSWebBanners3} alt="banner" />

              <div
              className='home-slide-wrap '
              style={{ backgroundColor: '#FFDA93' }}
            >
              <div className='home-slide-text'>
                <h1>No More Hear Say</h1>
                <p>
                  Find the truth right here at TheNewspaperStand. Access
                  daily/weekly updates on your favourite newspapers and
                  magazines.
                </p>

                {userIsLogin ? (
                  ''
                ) : (
                  <button
                    onClick={() =>
                      userIsLogin
                        ? console.log('Logged')
                        : props.history.push('/create_account')
                    }
                  >
                    Begin Here!
                  </button>
                )}
              </div>
              <div className='home-slide-img'>
                <img src='./home-slide.png' alt='' />
              </div>
            </div>
            </div>
          </a> */}
          {/* <a
            href="https://play.google.com/store/apps/details?id=com.thenewspaperstand&hl=en&gl=US"
            target="_blank"
          >
            <div className="slide-wrap-users-home">
              <img src={HomeBannar2} alt="banner" />

              <div
              className='home-slide-wrap '
              style={{ backgroundColor: '#FFDA93' }}
            >
              <div className='home-slide-text'>
                <h1>No More Hear Say</h1>
                <p>
                  Find the truth right here at TheNewspaperStand. Access
                  daily/weekly updates on your favourite newspapers and
                  magazines.
                </p>

                {userIsLogin ? (
                  ''
                ) : (
                  <button
                    onClick={() =>
                      userIsLogin
                        ? console.log('Logged')
                        : props.history.push('/create_account')
                    }
                  >
                    Begin Here!
                  </button>
                )}
              </div>
              <div className='home-slide-img'>
                <img src='./home-slide.png' alt='' />
              </div>
            </div>
            </div>
          </a> */}

          {/* <div
            className='slide-wrap-users-home'
            style={{ backgroundColor: '#FBC48C' }}
          >
            <div className='home-slide-wrap hideSlideOn-md-screen '>
              <div className='home-slide-text'>
                <h1>Daily Access To Genuine Information</h1>
                <p>
                  Access your Daily papers for less than half the original
                  price.
                </p>
                {userIsLogin ? (
                  ''
                ) : (
                  <button
                    onClick={() =>
                      userIsLogin
                        ? console.log('Logged')
                        : props.history.push('/create_account')
                    }
                  >
                    Grab the offer now!
                  </button>
                )}
              </div>
              <div className='home-slide-img'>
                <img src='./home-slide.png' alt='' />
              </div>
            </div>
          </div> */}

          {/* <div className='slide-wrap-users-home'>
            <div
              className='home-slide-wrap hideSlideOn-md-screen '
              style={{ backgroundColor: '#FFB9B9' }}
            >
              <div className='home-slide-text'>
                <h1>Priceless Details!</h1>
                <p>
                  Unveil the hidden secrets.... subscribe to your number 1
                  source of genuine information.
                </p>
                {userIsLogin ? (
                  ''
                ) : (
                  <button
                    onClick={() =>
                      userIsLogin
                        ? console.log('Logged')
                        : props.history.push('/create_account')
                    }
                  >
                    Start Now
                  </button>
                )}
              </div>
              <div className='home-slide-img'>
                <img src='./home-slide.png' alt='' />
              </div>
            </div>
          </div> */}
        </Carousel>
      </div>

      <div className="">
        <div className="">
          <div className="section-wrap">
            {filterType === "all" || filterType === "Newspaper" ? (
              <div>
                {Showloading ? (
                  <div className="loadingSvgHomeWrap">
                    {Array(10)
                      .fill("")
                      .map(() => (
                        <ContentLoader
                          className="svgHomeLoader"
                          title="Loading Newspaper"
                        >
                          {/* Only SVG shapes */}
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="100%"
                          />
                        </ContentLoader>
                      ))}
                  </div>
                ) : (
                  <NewspaperCards
                    Newspapers={
                      frequencyType === "all"
                        ? Newspapers
                        : Newspapers?.filter(
                            (x) => x.subscriptionType === frequencyType
                          )
                    }
                    viewPaper={props.history}
                    header="Newspapers"
                    userIsLogin={userIsLogin}
                  />
                )}
              </div>
            ) : (
              ""
            )}

            {filterType === "all" || filterType === "Magazines" ? (
              <div>
                {Showloading ? (
                  <div className="loadingSvgHomeWrap">
                    {Array(10)
                      .fill("")
                      .map(() => (
                        <ContentLoader
                          className="svgHomeLoader"
                          title="Loading Magazine"
                        >
                          {/* Only SVG shapes */}
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="100%"
                          />
                        </ContentLoader>
                      ))}
                  </div>
                ) : (
                  <MagazineCards
                    magazines={
                      frequencyType === "all"
                        ? magazines
                        : magazines?.filter(
                            (x) => x.subscriptionType === frequencyType
                          )
                    }
                    viewPaper={props.history}
                    header="Magazines"
                    userIsLogin={userIsLogin}
                  />
                )}
              </div>
            ) : (
              ""
            )}

            {filterType === "all" || filterType === "Books" ? (
              <div>
                <BooksCards
                  magazines={booksData}
                  viewPaper={props.history}
                  header="Books"
                  userIsLogin={userIsLogin}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
