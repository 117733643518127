import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import demo1 from "../../assets/demopic1.png";
import TNSLogo from "./logo.png";
import validateImage from "../../components/helpers/validateImage";
import { useParams } from "react-router-dom";
import { httpGet, httpPost } from "../../components/helpers/httpMethods";
import swal from "sweetalert";
import { UserDataContext } from "../../context/userContext";
import { PaymentContext } from "../../context/paymentContext";
import { hideLoader } from "../../components/helpers/loader";
import { showLoader } from "./../../components/helpers/loader";
export default function FormerName(props) {
  const { UserInfo, userLocation, getUserProfile, userIsLogin } =
    useContext(UserDataContext);
  const {
    PublicNoticepayWithWallet,
    changeOfNameSuccess,
    SetchangeOfNameSuccess,
    PUBLICNoticeinitPayment,
  } = useContext(PaymentContext);
  const params = useParams();
  useEffect(() => {
    console.log(">>user", UserInfo);
    document.body.style.backgroundColor = "#f4f4f418";
    showChangeOfNameVendors();
    if (changeOfNameSuccess == true) {
      rediretOnSuccessFulPayment();
    }
    return () => {
      // SetchangeOfNameSuccess(false);
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, [changeOfNameSuccess]);

  const rediretOnSuccessFulPayment = () => {
    if (changeOfNameSuccess == true) {
      SetchangeOfNameSuccess(false);
      setTimeout(() => {
        window.location.href = "/change_of_name_applications";
      }, 2000);
    }
  };

  const [vendor, setVendor] = useState([]);
  const [filterPub, setPublicationFullDetails] = useState([]);
  const showChangeOfNameVendors = async () => {
    const res = await httpGet(`pn/vendors`);
    setLoading(false);
    if (res.code == 200) {
      setVendor(res.data.pnSubscriptions);
      setLoading(false);
    }
  };

  const [tab, setTab] = useState("tab1");
  const [loading, setLoading] = useState(false);
  const [reasonForChangeOfName, setReasonForChangeOfName] = useState("");
  const [showModalBookingSummart1, setshowModalBookingSummart1] =
    useState(false);

  const [showModalBookingSummart2, setshowModalBookingSummart2] =
    useState(false);
  const setOpenshowModalBookingSummart1Modal = () => {
    setshowModalBookingSummart1(!showModalBookingSummart1);
  };

  const setOpenshowModalBookingSummart2Modal = () => {
    setshowModalBookingSummart2(!showModalBookingSummart2);
  };

  const [changeDetails, setChangeDetails] = useState({
    changeOfFormerTitle: "",
    changeOfFormerLastname: "",
    changeOfFormerFirstname: "",
    changeOfFormerMiddlename: "",
    changeOfNewTitle: "",
    changeOfNewLastname: "",
    changeOfNewFirstname: "",
    changeOfNewMiddlename: "",
    phone: "",
    email: "",
    affidavit: "",
    reason: "",
    proveOfReason: "",
    reasonDescription: "",
    publicationId: "",
  });

  const [category, seTcategory] = useState("");
  const [publicationId, setPublicationId] = useState("");
  const [lossOfDoc, setLossOfDoc] = useState({
    firstName: "",
    lastName: "",
    typeOfDoc: "",
    documentId: "",
    des: "",
    dateOfLoss: "",
    declearation: "",
  });

  const [changeOfBoardOfTrust, setChangeOfBoardOfTrust] = useState({
    bussinessName: "",
    houseAddress: "",
    bussinessTrustee1: "",
    bussinessTrustee2: "",
    aimsGoals: "",
    signedBy: "",
    declearation: "",
  });

  const [businessRegistration, setbusinessRegistration] = useState({
    bussinessName: "",
    houseAddress: "",
    bussinessTrustee1: "",
    bussinessTrustee2: "",
    aimsGoals: "",
    signedBy: "",
    declearation: "",
  });

  const [changeBusinessName, setChangeBusinessName] = useState({
    oldBussinessName: "",
    newBussinessName: "",
    bussinessTrustee1: "",
    bussinessTrustee2: "",
    businessAddress: "",
    signedBy: "",
    declearation: "",
  });
  const submitData = async () => {
    showLoader();

    let informations = null;
    if (category == "Loss of document") {
      informations = lossOfDoc;
    }

    if (category == "Change of board of trustees") {
      informations = changeOfBoardOfTrust;
    }

    if (category == "Change of businees name") {
      informations = changeBusinessName;
    }

    if (category == "Business registration") {
      informations = businessRegistration;
    }

    let data = {
      informations: JSON.stringify(informations),
      publicationId,
      category,
    };

    const res = await httpPost(`pn/apply`, data);
    if (res.code == 201) {
      hideLoader();
      // swal("tt", "ep", "success");
      initPNPayment(res.data);
      console.log(res);
    }
    hideLoader();
  };
  const initPNPayment = async ({ publicationId, id }) => {
    showLoader();
    const { wallet } = UserInfo;
    let type = "CARD";
    const Data = {
      publicationId,
      id,
      currency: UserInfo.wallet?.currency,
      amount: `${
        Number(filterPub[0]?.price) + Number(filterPub[0]?.serviceCharge)
      }`,
      type,
    };
    // setCONdataInitPayment(Data);

    if (
      Number(wallet?.amount) >=
      Number(filterPub[0]?.price) + Number(filterPub[0]?.serviceCharge)
    ) {
      setOpenshowModalBookingSummart2Modal();
      setOpenshowModalBookingSummart1Modal();
      return PublicNoticepayWithWallet(Data);
    } else {
      PUBLICNoticeinitPayment(Data);
    }
  };

  const validateOne = () => {
    if (publicationId == "" || category == "") {
      swal({
        title: "Validation Error!",
        text: "*All marked fields (**) are mandatory*",
        icon: "error",
      });
    } else {
      setTab("tab2");
    }
  };

  const validateTwo = () => {
    setOpenshowModalBookingSummart2Modal();
    // if (
    //   changeDetails.changeOfNewTitle == "" ||
    //   changeDetails.changeOfNewLastname == "" ||
    //   changeDetails.changeOfNewFirstname == "" ||
    //   changeDetails.changeOfNewMiddlename == ""
    // ) {
    //   swal({
    //     title: "Validation Error!",
    //     text: "All input field is required!",
    //     icon: "error",
    //   });
    // } else {
    //   setTab("tab3");
    // }
  };

  const validateThree = () => {
    if (changeDetails.affidavit == "") {
      return swal({
        title: "Validation Error!",
        text: "Affidavit is required!",
        icon: "error",
      });
    }

    if (reasonForChangeOfName == "") {
      return swal({
        title: "Validation Error!",
        text: "Select your reason for Change Of Name!",
        icon: "error",
      });
    }
    if (
      changeDetails.reason == "Others" &&
      changeDetails.reasonDescription == ""
    ) {
      return swal({
        title: "Validation Error!",
        text: "Please Input your reason for change of name!",
        icon: "error",
      });
    }
    if (changeDetails.proveOfReason == "") {
      return swal({
        title: "Validation Error!",
        text: "A Valid prove image document is required!",
        icon: "error",
      });
    } else {
      setOpenshowModalBookingSummart1Modal();
    }
  };

  const [checbox, setCheckbox] = useState(false);
  const confirmTermsApprove = () => {
    if (checbox == false) {
      return swal({
        title: "Validation Error!",
        text: "Please accept, and confirm that all information provided are correct by clicking the check box below!",
        icon: "error",
      });
    } else {
      setOpenshowModalBookingSummart2Modal();
    }
  };
  // const handleSubmit = async () => {
  // if(){
  //   swal({
  //     title: "Validation Error!",
  //     text:
  //       "DO NOT: screengrab, flip, print, share or circulate any content from this site as it will attract stiff penalties for defaulters.!",
  //     icon: "error",

  //   });
  // }
  // };

  const getPublicationData = (publicationId) => {
    const filterSelectedPublication = vendor;
    let filtered = filterSelectedPublication.filter((data) => {
      return data.publicationId === publicationId;
    });
    console.log(filtered);
    setPublicationFullDetails(filtered);
  };

  const HandleMakePayment = async () => {};

  return loading ? (
    <div></div>
  ) : (
    <div>
      <div className="changeOfNameTabWrap">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
          className="cangeONameHeasderRes"
        >
          {tab == "tab1" ? (
            <h1 style={{ fontSize: "22px", marginBottom: "10px" }}>
              Public Notice
            </h1>
          ) : (
            ""
          )}

          {tab == "tab2" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="cangeONameHeasderRes"
            >
              <h1 style={{ fontSize: "22px", marginBottom: "10px" }}>
                Public notice
              </h1>
              <br />

              <p style={{ fontSize: "15px", marginBottom: "15px" }}>
                You have chosen to publish your{" "}
                <span style={{ color: "#EE7F0D" }}>{category}</span> with{" "}
                <span style={{ color: "#EE7F0D" }}>
                  {filterPub[0]?.publication?.name}
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        {tab == "tab1" ? (
          <div className="inputChangeNameBox" style={{ width: "400px" }}>
            <div className="inputnwCh " style={{ width: "100%" }}>
              <select
                style={{ width: "100%" }}
                name=""
                id=""
                className="mwhgeqinput upSelsf"
                onChange={({ target }) => seTcategory(target.value)}
                value={category}
              >
                <option value="">Select category </option>
                <option value="Loss of document">Loss of document</option>
                <option value="Change of board of trustees">
                  {" "}
                  Change of board of trustees
                </option>
                <option value="Business registration">
                  Business registration
                </option>

                <option value="Change of businees name">
                  Change of businees name
                </option>
              </select>
            </div>
            <div className="inputnwCh" style={{ width: "100%" }}>
              <select
                style={{ width: "100%" }}
                name=""
                id=""
                className="mwhgeqinput"
                onChange={({ target }) => {
                  setPublicationId(target.value);
                  getPublicationData(target.value);
                }}
                value={publicationId}
              >
                <option value="">Select Publication</option>
                {vendor.map((data, i) => {
                  return (
                    <option
                      key={i}
                      value={data.publicationId}
                    >{`${data.publication.name} - ${data.price}`}</option>
                  );
                })}
              </select>
            </div>
            <div className="actionBtnCName">
              <button
                // style={{ opacity: "0" }}
                onClick={() => props.history.goBack()}
              >
                Back
              </button>
              <button onClick={validateOne}>Next</button>
            </div>
          </div>
        ) : (
          ""
        )}
        {tab == "tab2" ? (
          <div>
            {category == "Loss of document" ? (
              <div className="inputChangeNameBox">
                <div className="inputnwCh">
                  <input
                    type="text"
                    placeholder=" First Name * "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setLossOfDoc({
                        ...lossOfDoc,
                        firstName: e.target.value,
                      })
                    }
                    value={lossOfDoc.firstName}
                  />
                  <input
                    type="text"
                    placeholder="Last Name * "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setLossOfDoc({
                        ...lossOfDoc,
                        lastName: e.target.value,
                      })
                    }
                    value={lossOfDoc.lastName}
                  />
                </div>
                <div className="inputnwCh">
                  {/* <select
                name=""
                id=""
                className="mwhgeqinput"
                onChange={({ target }) =>
                  setChangeDetails({
                    ...changeDetails,
                    changeOfFormerTitle: target.value,
                  })
                }
                value={changeDetails.changeOfFormerTitle}
              >
                <option value="">Type of document</option>
                <option value="Mr"> category</option>
              </select> */}

                  <input
                    type="text"
                    placeholder="Type of document * "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setLossOfDoc({
                        ...lossOfDoc,
                        typeOfDoc: e.target.value,
                      })
                    }
                    value={lossOfDoc.typeOfDoc}
                  />

                  <input
                    type="text"
                    placeholder="Document ID *"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setLossOfDoc({
                        ...lossOfDoc,
                        documentId: e.target.value,
                      })
                    }
                    value={lossOfDoc.documentId}
                  />
                </div>

                <div className="inputnwCh">
                  <div className="mwhgeqinputDiv">
                    <label style={{ opacity: "0" }} className="">
                      Date
                    </label>
                    <input
                      type="text"
                      placeholder="Description *"
                      className="mwhgeqinput"
                      onChange={(e) =>
                        setLossOfDoc({
                          ...lossOfDoc,
                          des: e.target.value,
                        })
                      }
                      value={lossOfDoc.des}
                    />
                  </div>
                  <div className="mwhgeqinputDiv">
                    <label className="">Date of loss</label>
                    <input
                      type="date"
                      placeholder="Date of loss *"
                      className="mwhgeqinput"
                      onChange={(e) =>
                        setLossOfDoc({
                          ...lossOfDoc,
                          dateOfLoss: e.target.value,
                        })
                      }
                      value={lossOfDoc.dateOfLoss}
                    />
                  </div>
                </div>

                <div className="inputnwCh">
                  <textarea
                    name=""
                    id=""
                    placeholder="I hearby declare that..."
                    style={{ width: "100%", height: "80px" }}
                    onChange={(e) =>
                      setLossOfDoc({
                        ...lossOfDoc,
                        declearation: e.target.value,
                      })
                    }
                    value={lossOfDoc.declearation}
                  ></textarea>
                </div>
                <div className="actionBtnCName">
                  <button onClick={() => setTab("tab1")}>Back</button>
                  <button onClick={validateTwo}>Next</button>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Change of board of trustees START */}

            {category == "Change of board of trustees" ? (
              <div className="inputChangeNameBox">
                <div className="inputnwCh">
                  <input
                    type="text"
                    placeholder=" Bussiness name* "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        bussinessName: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.bussinessName}
                  />
                  <input
                    type="text"
                    placeholder="Bussiness address* "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        houseAddress: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.houseAddress}
                  />
                </div>
                <div className="inputnwCh">
                  {/* <select
                name=""
                id=""
                className="mwhgeqinput"
                onChange={({ target }) =>
                  setChangeDetails({
                    ...changeDetails,
                    changeOfFormerTitle: target.value,
                  })
                }
                value={changeDetails.changeOfFormerTitle}
              >
                <option value="">Type of document</option>
                <option value="Mr"> category</option>
              </select> */}

                  <input
                    type="text"
                    placeholder="Bussiness trustee 01 "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        bussinessTrustee1: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.bussinessTrustee1}
                  />

                  <input
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Bussiness trustee 02 "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        bussinessTrustee2: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.bussinessTrustee2}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Bussiness aims & goals"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        aimsGoals: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.aimsGoals}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Signed by"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        signedBy: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.signedBy}
                  />
                </div>

                <div className="inputnwCh">
                  <textarea
                    name=""
                    id=""
                    placeholder="I hearby declare that..."
                    style={{ width: "100%", height: "80px" }}
                    onChange={(e) =>
                      setChangeOfBoardOfTrust({
                        ...changeOfBoardOfTrust,
                        declearation: e.target.value,
                      })
                    }
                    value={changeOfBoardOfTrust.declearation}
                  ></textarea>
                </div>
                <div className="actionBtnCName">
                  <button onClick={() => setTab("tab1")}>Back</button>
                  <button onClick={validateTwo}>Next</button>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Change of board of trustees ENDS */}

            {/* Business registration START */}

            {category == "Business registration" ? (
              <div className="inputChangeNameBox">
                <div className="inputnwCh">
                  <input
                    type="text"
                    placeholder=" Bussiness name* "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        bussinessName: e.target.value,
                      })
                    }
                    value={businessRegistration.bussinessName}
                  />
                  <input
                    type="text"
                    placeholder="Business address* "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        houseAddress: e.target.value,
                      })
                    }
                    value={businessRegistration.houseAddress}
                  />
                </div>
                <div className="inputnwCh">
                  {/* <select
               name=""
               id=""
               className="mwhgeqinput"
               onChange={({ target }) =>
                 setChangeDetails({
                   ...changeDetails,
                   changeOfFormerTitle: target.value,
                 })
               }
               value={changeDetails.changeOfFormerTitle}
             >
               <option value="">Type of document</option>
               <option value="Mr"> category</option>
             </select> */}

                  <input
                    type="text"
                    placeholder="Bussiness trustee 01 "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        bussinessTrustee1: e.target.value,
                      })
                    }
                    value={businessRegistration.bussinessTrustee1}
                  />

                  <input
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Bussiness trustee 02 "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        bussinessTrustee2: e.target.value,
                      })
                    }
                    value={businessRegistration.bussinessTrustee2}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Bussiness aims & goals"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        aimsGoals: e.target.value,
                      })
                    }
                    value={businessRegistration.aimsGoals}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    className="mwhgeqinput"
                    placeholder="Signed by"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        signedBy: e.target.value,
                      })
                    }
                    value={businessRegistration.signedBy}
                  />
                </div>

                <div className="inputnwCh">
                  <textarea
                    name=""
                    id=""
                    placeholder="I hearby declare that..."
                    style={{ width: "100%", height: "80px" }}
                    onChange={(e) =>
                      setbusinessRegistration({
                        ...businessRegistration,
                        declearation: e.target.value,
                      })
                    }
                    value={businessRegistration.declearation}
                  ></textarea>
                </div>
                <div className="actionBtnCName">
                  <button onClick={() => setTab("tab1")}>Back</button>
                  <button onClick={validateTwo}>Next</button>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Business registration ENDS */}

            {/* Change of businees name START */}

            {category == "Change of businees name" ? (
              <div className="inputChangeNameBox">
                <div className="inputnwCh">
                  <input
                    type="text"
                    placeholder="Old business name*"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        oldBussinessName: e.target.value,
                      })
                    }
                    value={changeBusinessName.oldBussinessName}
                  />
                  <input
                    type="text"
                    placeholder="New business name*"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        newBussinessName: e.target.value,
                      })
                    }
                    value={changeBusinessName.newBussinessName}
                  />
                </div>
                <div className="inputnwCh">
                  <input
                    type="text"
                    placeholder="Bussiness trustee 1* "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        bussinessTrustee1: e.target.value,
                      })
                    }
                    value={changeBusinessName.bussinessTrustee1}
                  />

                  <input
                    type="text"
                    placeholder="Bussiness trustee 2 (optional)"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        bussinessTrustee2: e.target.value,
                      })
                    }
                    value={changeBusinessName.bussinessTrustee2}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Business address*  "
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        businessAddress: e.target.value,
                      })
                    }
                    value={changeBusinessName.businessAddress}
                  />
                </div>

                <div className="inputnwCh">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Signed by"
                    className="mwhgeqinput"
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        signedBy: e.target.value,
                      })
                    }
                    value={changeBusinessName.signedBy}
                  />
                </div>

                <div className="inputnwCh">
                  <textarea
                    name=""
                    id=""
                    placeholder="I hearby declare that..."
                    style={{ width: "100%", height: "80px" }}
                    onChange={(e) =>
                      setChangeBusinessName({
                        ...changeBusinessName,
                        declearation: e.target.value,
                      })
                    }
                    value={changeBusinessName.declearation}
                  ></textarea>
                </div>
                <div className="actionBtnCName">
                  <button onClick={() => setTab("tab1")}>Back</button>
                  <button onClick={validateTwo}>Next</button>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Change of businees name ENDS */}
          </div>
        ) : (
          ""
        )}
      </div>
      {filterPub.length == 0 ? (
        ""
      ) : (
        <Modal
          open={showModalBookingSummart2}
          onClose={setOpenshowModalBookingSummart2Modal}
        >
          <div
            className="inner-modal-wrap-trvChN"
            style={{ background: "white" }}
          >
            <div className="previewChangeOfname1Wrap">
              <div className="spLogosCN">
                <img src={TNSLogo} alt="" />
                <img
                  style={{ width: "120px", height: "70px" }}
                  src={filterPub[0]?.publication?.logo}
                  alt=""
                />
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "0",
                }}
              >
                Booking Summary!
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12.5px",
                  magin: "12px 0px 0px 0px !important",
                  color: "#F80707",
                }}
              >
                Kindly preview and confirm the details below
              </p>

              <div className="cnNoticeg">
                <h1>Public Notice</h1>

                {category == "Loss of document" ? (
                  <p>
                    I{" "}
                    <span>{`${lossOfDoc.firstName} ${lossOfDoc.lastName}`}</span>{" "}
                    notify the necessary bodies of the loss of the stated
                    document{" "}
                  </p>
                ) : (
                  <p>
                    {" "}
                    I,{" "}
                    <span>
                      {" "}
                      {changeOfBoardOfTrust.bussinessTrustee1 == ""
                        ? ""
                        : changeOfBoardOfTrust.bussinessTrustee1}{" "}
                      {changeBusinessName.bussinessTrustee1 == ""
                        ? ""
                        : changeBusinessName.bussinessTrustee1}{" "}
                      {businessRegistration.bussinessTrustee1 == ""
                        ? ""
                        : businessRegistration.bussinessTrustee1}{" "}
                    </span>{" "}
                    on behalf of{" "}
                    <span>
                      {businessRegistration.bussinessName == ""
                        ? ""
                        : businessRegistration.bussinessName}{" "}
                      {changeBusinessName.oldBussinessName == ""
                        ? ""
                        : changeBusinessName.oldBussinessName}{" "}
                      {changeOfBoardOfTrust.bussinessName == ""
                        ? ""
                        : changeOfBoardOfTrust.bussinessName}{" "}
                    </span>{" "}
                    hereby declare the above information to be true. General
                    public take note.
                  </p>
                )}
              </div>

              <div style={{ marginTop: "25px" }} className="cNLine"></div>

              <div className="cnNoticeg">
                <p style={{ color: "black", fontSize: "14px" }}>
                  You have chosen to publish your notice with{" "}
                  <span>{filterPub[0]?.publication?.name}</span>
                </p>
              </div>
              <div className="cnNoticeg" style={{ margin: "0" }}>
                <p style={{ color: "#6E7191", fontSize: "12px" }}>
                  {filterPub[0]?.publication?.name} publishes{" "}
                  <span className="pubDays3h">
                    {filterPub[0]?.publishDays?.map((data) => {
                      return (
                        <span className="pubDays3hCh1">{data.slice(0, 3)}</span>
                      );
                    })}
                  </span>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    background: "#EE7F0D",
                    width: "220px",
                    height: "70px",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "white",
                    paddingTop: "20px",
                    borderRadius: "4px",
                  }}
                >
                  {`₦${
                    Number(filterPub[0]?.price) +
                    Number(filterPub[0]?.serviceCharge)
                  }`}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                <button
                  style={{
                    height: "50px",
                    width: "180px",
                    backgroundColor: "#2BB509",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "13px",
                    marginBottom: "15px",
                    cursor: "pointer",
                  }}
                  onClick={submitData}
                >
                  Make Payment
                </button>
                <p
                  style={{
                    color: "black",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenshowModalBookingSummart2Modal()}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
