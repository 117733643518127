import './viewer.css';
import React, { useEffect, useContext } from 'react';
import AppView from './appView';
import { UserDataContext } from '../context/userContext';
export default function View(props) {
  const { userIsLogin, checkUserIsLogin, GetUserInfoFromStorge } =
    useContext(UserDataContext);
  useEffect(() => {
    // if (userIsLogin !== true) {
    //   props.history.push("/login");
    //   localStorage.setItem("rediretURL", props.match.params.id);
    // }
  }, []);
  return (
    <div className="wrapPdf">
      <div style={{ height: '100%' }}>
        <AppView path={props.match.params.id} history={props.history} />
      </div>
    </div>
  );
}
