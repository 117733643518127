import React, { useContext, useEffect } from "react";
import "./cards.css";
import DateFormater from "../helpers/dateFormater";
import { CartContextData } from "../../context/newsPaperContext";
import Truncate from "../../components/helpers/truncate";

export default function NewspaperCards({
  header,
  viewPaper,
  getEditions,
  userIsLogin,
}) {
  useEffect(() => {
    localStorage.setItem("unAuthorizedAccess", "false");
    console.log(">>>>>>>>>>>>>>>>>>>>>>here", viewPaper.match.params.id);
  }, []);
  const { onOpenModal, followPublications, mySubs } = useContext(
    CartContextData
  );

  const handleClickCheck = (data) => {
    if (userIsLogin === true) {
      if (mySubs.includes(data.publicationId)) {
        viewPaper.history.push(`/view_single_paper/${data.id}`);
      } else {
        onOpenModal(data, "fromPublicPublication");
      }
    } else {
      viewPaper.history.push("/login");
      localStorage.setItem("comingFromPublicPublication", "true");
      // localStorage.setItem("unAuthorizedAccess", "true");
      localStorage.setItem("rediretURL", viewPaper.match.params.id);
    }
  };

  return (
    <React.Fragment>
      <div className="news-wrap-home singdfghjhyh">
        <h1 className="edition-type-home">{header}</h1>
        <div className={`TNSNewsPaperCover`}>
          {getEditions.map((data) => {
            return (
              <div key={data.id} className="newspaperWrapBody">
                <div className="sPPW" style={{ position: "relative" }}>
                  <div className="wraopNewpaperIMg">
                    <div className="paperImgNew">
                      <img src={data.image} alt="" />
                    </div>
                  </div>

                  <div className="paperImgHover">
                    <div className="subscribeToPublicationBtn">
                      <button onClick={() => handleClickCheck(data)}>
                        {mySubs.includes(data.publicationId)
                          ? "Reads"
                          : "Subscribe"}
                      </button>
                      {/* <div className="followEditionIcon">
                      <i
                        onClick={() => followPublications(data.id)}
                        class="fas fa-user-plus"
                      ></i>
                    </div> */}
                    </div>
                  </div>
                  <div className="TNSAboutMagazine">
                    <div className="TNSAboutMagazine-name">
                      {/* <p>{data.editionName}</p> */}
                      <p>{DateFormater(data.publishedDate)}</p>
                    </div>

                    <div className="TNSAboutMagazine-socialEngagement">
                      {/* <i class="fas fa-comments"></i> */}
                      {/* <span>{data.editions[0].comment.length}</span> */}
                      {/* <i class="fas fa-thumbs-up"></i> */}
                      {/* <span>{data.editions[0].like.length}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
