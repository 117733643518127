import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import Navbar from "./Navbar.jsx";
import Sidebar from "./Sidebar";
import "./layout.css";
export default function Index(props) {
  // let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div>
        <div id="hideNav">
          <Navbar
            browserRouter={props.browserRouter}
            routeType={props.routeType}
            currentRoute={props.currentRoute}
          />
        </div>

        <Sidebar
          browserRouter={props.browserRouter}
          currentRoute={props.currentRoute}
        />

        <div>
          <section
            // onClick={() =>
            //   setSidebarState({ ...sidebarState, openSidebar: false })
            // }
            className="app-container"
          >
            {props.children}
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
