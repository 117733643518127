import React, { createContext, useState, useEffect } from "react";
import socketIO from "socket.io-client";
export const SocketDataContext = createContext();
export const AppSocket = {};
let baseUrl = process.env.REACT_APP_BASE_URL;
export const SocketDataProvider = (props) => {
  const [userNotification, setUserNotification] = useState(null);

  useEffect(() => {}, [userNotification]);

  AppSocket.createConnection = async ({}) => {
    if (AppSocket.io?.connected) return; // if there has been a connection before, return
    //open a connection
    var connectionOptions = {
      "force new connection": true,
      reconnectionAttempts: "Infinity",
      timeout: 10000,
      transports: ["websocket"],
    };
    AppSocket.io = socketIO(
      `${baseUrl}?token=${localStorage.token}`,
      connectionOptions
    );
    //listen for connection
    AppSocket.io.on("connect", () => {
      console.log("connected to server >>");

      AppSocket.io.emit("user_notification", (data) => {
        setUserNotification(data);
      });
    });

    AppSocket.io.on("notification", (data) => {
      console.log("this is a notification", data);
      setUserNotification(data);
    });
  };

  AppSocket.sendRequest = async (path, payload) => {
    if (AppSocket.io && AppSocket.io.connected === true) {
      AppSocket.io.emit(path, payload);
    } else {
      console.log("App disconnected from server");
    }
  };

  AppSocket.logOut = () => {
    if (AppSocket.io && AppSocket.io.connected === true) {
      AppSocket.io.disconnect();
    } else {
      console.log("App disconnected from server");
    }
  };

  return (
    <SocketDataContext.Provider
      value={{
        AppSocket,
        userNotification,
      }}
    >
      {props.children}
    </SocketDataContext.Provider>
  );
};
