import React, { useContext, useEffect } from "react";
import "./cards.css";
import DateFormater from "../helpers/dateFormater";
import { CartContextData } from "../../context/newsPaperContext";
import Truncate from "../../components/helpers/truncate";
export default function NewspaperCards({
  header,
  viewPaper,
  getEditions,
  aboutPublicationHead,
}) {
  const { onOpenModal, followPublications } = useContext(CartContextData);
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="news-wrap-home singdfghjhyh">
        <h1 className="edition-type-home">{header}</h1>
        <div className={`view_paper_card`}>
          {aboutPublicationHead.type.name === "books" ? (
            <div key={getEditions[0].id} className="newspaperWrapBody">
              <div className="sPPW" style={{ position: "relative" }}>
                <div className="wraopNewpaperIMg">
                  <div className="paperImgNew">
                    <img src={getEditions[0].image} alt="" />
                  </div>
                </div>

                <div className="paperImgHover">
                  <div className="subscribeToPublicationBtn">
                    <button
                      onClick={() =>
                        viewPaper.push(
                          `/view_single_paper/${getEditions[0].id}`
                        )
                      }
                    >
                      Read
                    </button>
                    {/* <div className="followEditionIcon">
                <i
                  onClick={() => followPublications(getEditions[0].id)}
                  class="fas fa-user-plus"
                ></i>
              </div> */}
                  </div>
                </div>
                <div className="TNSAboutMagazine">
                  <div className="TNSAboutMagazine-name">
                    {/* <p>{getEditions[0].editionName}</p> */}
                    <p>{DateFormater(getEditions[0].publishedDate)}</p>
                  </div>

                  <div className="TNSAboutMagazine-socialEngagement">
                    {/* <i class="fas fa-comments"></i> */}
                    {/* <span>{data.editions[0].comment.length}</span> */}
                    {/* <i class="fas fa-thumbs-up"></i> */}
                    {/* <span>{data.editions[0].like.length}</span> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {getEditions.map((data) => {
                return (
                  <div key={data.id} className="newspaperWrapBody">
                    <div className="sPPW" style={{ position: "relative" }}>
                      <div className="wraopNewpaperIMg">
                        <div className="paperImgNew">
                          <img src={data.image} alt="" />
                        </div>
                      </div>

                      <div className="paperImgHover">
                        <div className="subscribeToPublicationBtn">
                          <button
                            onClick={() =>
                              viewPaper.push(`/view_single_paper/${data.id}`)
                            }
                          >
                            Read
                          </button>
                          {/* <div className="followEditionIcon">
                      <i
                        onClick={() => followPublications(data.id)}
                        class="fas fa-user-plus"
                      ></i>
                    </div> */}
                        </div>
                      </div>
                      <div className="TNSAboutMagazine">
                        <div className="TNSAboutMagazine-name">
                          {/* <p>{data.editionName}</p> */}
                          <p>{DateFormater(data.publishedDate)}</p>
                        </div>

                        <div className="TNSAboutMagazine-socialEngagement">
                          {/* <i class="fas fa-comments"></i> */}
                          {/* <span>{data.editions[0].comment.length}</span> */}
                          {/* <i class="fas fa-thumbs-up"></i> */}
                          {/* <span>{data.editions[0].like.length}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
