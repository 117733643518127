import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta
        property="og:url"
        content={url ?? "https://app.thenewspaperstand.com"}
      />
      <meta
        name="keywords"
        content="Digital newspapers,
          Magazine replicas,
          Nigerian newspapers,
          Online newspaper platform,
          Digital newsstand,
          Newspaper archives,
          Magazine subscriptions,
          Read newspapers online,
          Nigerian news outlets,
          Online magazine access,
          ePaper platform,
          Current news updates,
          Newsstand app,
          Print media online,
          Newspapers in Nigeria,
          eMagazines,
          Online periodicals,
          Latest news in Nigeria,
          Newspaper app,
          Access to digital press"
      />
      <meta name="twitter:site" content="@thenewspaperst" />
    </Helmet>
  );
};

export default SEO;
