import React, { useEffect } from "react";
import "./index.css";
import "../../pages/landingPage/home.css";

import Magazine2 from "../../assets/Magazine2.png";
export default function ViewSinglePaper(props) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="section-wrap">
        <div className="app-center">
          <div class="shopping-cart">
            <div class="product">
              <div class="product-image">
                <img src={Magazine2} alt="broken data" />
              </div>
              <div class="product-details">
                {/* <div class="product-title">Dingo Dog Bones</div> */}
                <p class="product-description">The News stand</p>
                <div class="product-line-price">25.98</div>
                <select className="sub-type-cart" name="" id="">
                  <option value="">One Time</option>
                  <option value="">Weekly</option>
                  <option value="">Monthly</option>
                </select>
              </div>
              <div class="product-removal">
                <button class="remove-product">Remove</button>
                <button class="subscribe-product">Subscribe</button>
              </div>
            </div>

            <div class="product">
              <div class="product-data">
                <img src={Magazine2} alt="broken data" />
              </div>
              <div class="product-details">
                {/* <div class="product-title">Dingo Dog Bones</div> */}
                <p class="product-description">The News stand</p>
                <div class="product-line-price">25.98</div>
                <select className="sub-type-cart" name="" id="">
                  <option value="">One Time</option>
                  <option value="">Weekly</option>
                  <option value="">Monthly</option>
                </select>
              </div>
              <div class="product-removal">
                <button class="remove-product">Remove</button>
                <button class="subscribe-product">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
