import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { UserDataContext } from "../../context/userContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  httpPostFormData,
  httpPut,
  httpPatch,
  httpGet,
  httpPost,
  httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import like from "../../assets/like.png";
import likeDown from "../../assets/like-down.png";
import heart from "../../assets/heart-dark.png";
import Magazine2 from "../../assets/Magazine2.png";
import "./index.css";
import { data } from "jquery";
import NotFound from "../../components/helpers/notFound";
export default function Review({ history }) {
  const { setLoginModal } = useContext(UserDataContext);

  useEffect(() => {
    PublicationReviews();
    document.body.style.backgroundColor = "#F4F4F4";

    let elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);

  const [myReviews, setMyReviews] = useState([]);
  const PublicationReviews = async () => {
    showLoader();
    let res = await httpGet(`my_reviews`);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        setloadData(false);
        return;
      }
      hideLoader();
      setMyReviews(res.data);
      setloadData(false);
    }
  };

  const [Showloading, setLoading] = useState(false);
  const [loadData, setloadData] = useState(true);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <div className="section-wrap">
        <React.Fragment>
          <div>
            {loadData === true ? (
              <div>
                <div className="row h-100 justify-content-center align-items-center app-spinner">
                  <Loader
                    type="TailSpin"
                    color="#EF6C00"
                    height={50}
                    width={50}
                  />
                </div>
              </div>
            ) : myReviews.length === 0 ? (
              <NotFound
                header="You don't have any review yet"
                body="When you comment on an edition, it will appear here."
              />
            ) : (
              <div className="section-wrap">
                <div className="reviews-wrap">
                  {myReviews.map((data) => {
                    return (
                      <div className="inner-review">
                        <div className="review-img">
                          <img src={data.edition.image} alt="" />
                        </div>
                        <div className="review-text">
                          <p>{data.edition.editionName}</p>
                          <p style={{ marginTop: "-14px" }}>{data.message}</p>
                          <div
                            style={{ marginLeft: "0 !important" }}
                            onClick={() =>
                              history.push(
                                `/view_single_paper/${data.editionId}`
                              )
                            }
                            className="reviewjk-profile"
                          >
                            View Edition
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}
