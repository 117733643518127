import React, { useState, useEffect, useContext } from 'react';
import TrendingEditionCards from './trendingEditionCards';
import './cards.css';
import '../../pages/landingPage/home.css';
import Magazine2 from '../../assets/Magazine2.png';
import Loader from 'react-loader-spinner';
import { httpGet, httpPost } from '../../components/helpers/httpMethods';
import { hideLoader, showLoader } from '../../components/helpers/loader';
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import * as copy from 'copy-to-clipboard';
import Slugify from '../helpers/slugFy';
import { CartContextData } from '../../context/newsPaperContext';
import { UserDataContext } from '../../context/userContext';
import {
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
export default function ViewSinglePaper(props) {
  useEffect(() => {
    purchasedEditions();
  }, []);
  const { onOpenModal, followPublications, mySubs } =
    useContext(CartContextData);
  const { userIsLogin, checkUserIsLogin, GetUserInfoFromStorge } =
    useContext(UserDataContext);
  const [Showloading, setLoading] = useState(true);
  const [getEditions, setEditions] = useState([]);
  const [lattestEditions, setlattestEditions] = useState();
  const [aboutPublicationHead, setaboutPublicationHead] = useState();
  const CopyToClipboard = (text) => {
    //copy(text);
    copy(text, {
      debug: true,
      message: 'Press #{key} to copy',
    });
    NotificationManager.success(`Link copied to clipboard ${text}.`);
  };
  const purchasedEditions = async () => {
    const res = await httpGet(`purchased_editions/${props.match.params.id}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        if (
          res.er.message ===
          'You do not have an active subscription with this publication'
        ) {
          props.history.goBack();
        }
        return;
      }

      let d = res.data.editions;
      setaboutPublicationHead(res.data.findPublication);
      console.log(d[0]);
      setlattestEditions(d[0]);
      setEditions(d);
      setLoading(false);
      let checkIfPaidWithWalletStatus = localStorage.getItem(
        'paidWithWallet',
        'false'
      );
      if (checkIfPaidWithWalletStatus === 'true') {
        swal(
          'Wallet payment successful!',
          'Payment for publication was successful. Your wallet balance have been debited for the transaction. Make sure wallet is always funded to continue enjoying seamless subscription',
          'success'
        ).then(() => {
          localStorage.setItem('paidWithWallet', 'false');
        });
      }
    }
  };
  console.log(aboutPublicationHead);
  const handleClickCheckSingle = (data) => {
    if (userIsLogin === true) {
      if (mySubs.includes(data.publicationId)) {
        props.history.push(`/view_single_paper/${data.id}`);
      } else {
        onOpenModal(data, 'fromPublicPublication');
      }
    } else {
      props.history.push('/login');
      localStorage.setItem('unAuthorizedAccess', 'true');
      localStorage.setItem('rediretURL', props.match.params.id);
    }
  };
  if (Showloading) {
    return (
      <div className='row h-100 justify-content-center align-items-center app-spinner'>
        <Loader type='TailSpin' color='#EF6C00' height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className='new-section'>
          <div className='publicationPublicAbout'>
            <div className='pubgiu-img-wrap'>
              <img src={aboutPublicationHead.logo} alt='' />
            </div>
            <div className='pubgiu-aboutTexxtQrap'>
              <h5>{aboutPublicationHead.name}</h5>
              <p></p>
              <div className='shraePublication-shareSocials'>
                <div className='shareIconsWrapReactShare'>
                  <FacebookShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </div>
                <div className='shareIconsWrapReactShare'>
                  <TwitterShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </div>
                <div className='shareIconsWrapReactShare'>
                  <LinkedinShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </div>
                <div className='shareIconsWrapReactShare'>
                  <TelegramShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </div>
                <div className='shareIconsWrapReactShare'>
                  <WhatsappShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
                <div className='shareIconsWrapReactShare'>
                  <EmailShareButton
                    url={`https://app.thenewspaperstand.com/publication/${Slugify(
                      aboutPublicationHead.name
                    )}/`}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </div>
                <div
                  onClick={() =>
                    CopyToClipboard(
                      `https://app.thenewspaperstand.com/publication/${Slugify(
                        aboutPublicationHead.name
                      )}/`
                    )
                  }
                  className='copyToClipBoard'
                >
                  {' '}
                  <i className='fas fa-clipboard'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='linehr-publlicPub'></div>
          <div className='single-book-section'>
            <div className='sigle-book-col-one'>
              <img
                className='magazin-img-single'
                src={lattestEditions ? lattestEditions.image : Magazine2}
                alt=''
                style={{ width: '94%' }}
              />
              <h3
                style={{ color: 'rgba(0, 0, 0, 0.856)' }}
                className='single-book-name'
              >
                {lattestEditions ? lattestEditions.editionName : ''}
              </h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '18px',
                  marginBottom: '15px',
                }}
              >
                <button
                  onClick={() => handleClickCheckSingle(lattestEditions)}
                  className='single-book-sub-btn'
                >
                  Read Now
                </button>
              </div>
            </div>
            <div className='sigle-book-col-two'>
              <div className=''>
                <TrendingEditionCards
                  getEditions={getEditions}
                  viewPaper={props.history}
                  header='Latest Editions'
                  aboutPublicationHead={aboutPublicationHead}
                />
                {/* <TrendingEditionCards
                  magazines={getEditions}
                  viewPaper={props.history}
                  header="Other Publications"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
