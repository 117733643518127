import React, { useEffect, useState } from "react";
import "./index.css";
import { Select } from "react-select";
import demo1 from "../../assets/demopic1.png";
import { httpGet } from "./../../components/helpers/httpMethods";
const Index = (props) => {
  useEffect(() => {
    showChangeOfNameVendors();
    return () => {
      setVendor([]);
    };
  }, []);

  const [vendor, setVendor] = useState([]);
  const showChangeOfNameVendors = async () => {
    const res = await httpGet("con/vendors");
    if (res.code == 200) {
      setVendor(res.data.conSubscriptions);
    }
  };
  return (
    <div
      style={{
        borderColor: "#E5E5E5",
        height: "fit-content",
        paddingBottom: "90px",
      }}
    >
      <div className="changeofNameBanner">
        <div className="chNameTextWrap">
          <div className="chNameTextMain">
            <h1>CHANGE OF NAME</h1>
            <p className="chNameText">
              Publish your Change of Name with your favourite newspaper with
              comfort and ease.
              <br /> Leverage our integrated partnership with publishers to get
              your Change of Name out fast <br />
              and easy.
            </p>
            <button
              style={{
                padding: "13px",
                backgroundColor: "transparent",
                borderRadius: "4px",
                border: "1px solid #EE7F0D",
                color: "#EE7F0D",
                marginTop: "10px",
              }}
              onClick={() => props.history.push("/change_of_name_applications")}
            >
              My Application
            </button>
          </div>
        </div>

        <div className="drpSelOp">
          <select
            onChange={(e) =>
              props.history.push(`/change_of_name_tab1/${e.target.value}`)
            }
          >
            <option value="">--Select a newspaper--</option>
            {vendor?.map(
              ({ price, publishDays, publication, publicationId }) => {
                return (
                  <option value={publicationId}>{publication.name}</option>
                );
              }
            )}
          </select>
        </div>
      </div>

      <h1 style={{ textAlign: "center", fontSize: "18px", marginTop: "60px" }}>
        Select a publisher to get started
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="newspaperListChangeOfname">
          {vendor ? (
            vendor.map(
              ({
                price,
                serviceCharge,
                publishDays,
                publication,
                publicationId,
              }) => {
                return (
                  <div
                    className="newspaperListChangeOfname_child"
                    onClick={() => {
                      if (!localStorage.getItem("token")) {
                        return (window.location.href = "/login");
                      }
                      props.history.push(
                        `/change_of_name_tab1/${publicationId}`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img className="img-fluid" src={publication?.logo} alt="" />
                    <div className="newspaperChChAB">
                      <p
                        style={{
                          margin: "0",
                          textAlign: "center",
                          fontWeight: "600",
                          marginTop: "19px",
                          fontSize: "14p",
                        }}
                      >
                        N{Number(price) + Number(serviceCharge)}
                      </p>
                      <p
                        style={{
                          margin: "3px 0 0 5px",
                          textAlign: "center",
                          color: "#242424",
                          fontSize: "12px",
                        }}
                      >
                        Change of Name Publication Days
                      </p>

                      <p
                        style={{
                          margin: "3px 0 0 5px",
                          textAlign: "center",
                          color: "#242424",
                          fontSize: "11px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className="pubDays3h">
                          {publishDays.map((data) => {
                            return (
                              <span className="pubDays3hCh1">
                                {data.slice(0, 3)}
                              </span>
                            );
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <p style={{ textAlign: "center", color: "red", marginTop: "10px" }}>
              No Vendor found for change of name service
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
