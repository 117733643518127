import React, { useEffect, useState, ref, useRef, useContext } from "react";
// Import styles
import { httpGet, httpPost } from "../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../components/helpers/loader";
import crypto from "crypto";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import LikeIcon from "./like.png";
import commentIcon from "./comment.png";
import MaleAvatar from "../assets/male.png";
import { Scrollbars } from "react-custom-scrollbars";
import warningIcon from "./warningIcon.png";
import swal from "sweetalert";
import textViewImg from "./pageview.png";
import TextView from "./textview";
import shareImg from "./share.png";
import { FileIcon, Blocks, ArrowPdf } from "../svg/svg";
import GiftUser from "../assets/giftUser.png";
import GiftUserFlat from "../assets/flat-ui_gift-box.png";
import Close from "../assets/Close.png";
import {
  Viewer,
  Worker,
  SpecialZoomLevel,
  ProgressBar,
  RenderPageProps,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/zoom/lib/styles/index.css';
// import {AppSocket,notifications} from '../components/helpers/socket'
import { SocketDataContext } from "../context/socketContext";
import { Tooltip } from "@material-ui/core";
import { UserDataContext } from "../context/userContext";

function App({ path, history }) {
  const [makeComment, setmakeComment] = useState(false);
  const [pdfPath, sepPdfPath] = useState();
  const [hidePublicationPrivacy, sethidePublicationPrivacy] = useState(true);
  const [pageViewType, setpageViewType] = useState("");
  const [showGifting, setShowGifting] = useState(false);
  const [localNot, setLocalNot] = useState({ body: "", img: "" });
  const { AppSocket, userNotification } = useContext(SocketDataContext);
  const { UserInfo } = useContext(UserDataContext);

  const scrTtOPRef = useRef();
  useEffect(() => {
    console.log("?????");
    purchasedEdition();
    getUserComment();

    let hidePublicationPrivacyVar = localStorage.getItem(
      "hidePublicationPrivacy"
    );

    if (hidePublicationPrivacyVar === "true") {
      // alert(hidePublicationPrivacy);
      sethidePublicationPrivacy(true);
    }
    if (hidePublicationPrivacyVar === "false") {
      // alert(hidePublicationPrivacy);
      sethidePublicationPrivacy(false);
    }

    // view_paper/55915ee3-641d-4ef2-be2a-00f438643026

    return () => {};
  }, []);

  useEffect(() => {
    AppSocket.createConnection(setLocalNot);
    // setLocalNot(notifications)
    console.log(">>>>>>ss", userNotification);
    return () => AppSocket.logOut();
  }, []);

  const handlePageViewtyp = (type) => {
    setpageViewType(type);
  };
  const [loadingPdfUrl, setloadingPdfUrl] = useState(true);
  const [articles, setArticles] = useState([]);
  const [activeArticle, setActiveArticle] = useState([]);
  const [publicationEdition, setPublicationEdition] = useState([]);

  const decrypt = (text) => {
    let iv = Buffer.from(text.iv, "hex");
    let encryptedText = Buffer.from(text.encryptedData, "hex");
    let key = text.key;
    let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  };

  const purchasedEdition = async () => {
    showLoader();
    setloadingPdfUrl(true);
    const res = await httpGet(`purchased_edition/${path}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        if (
          res.er.message ==
          "You do not have an active subscription with this publication"
        ) {
          history.goBack();
        }
        return;
      }

      //       data: {edition: {iv: "9d27e60c1be4db4da6e4a9d3d18dd244",…}}
      // edition: {iv: "9d27e60c1be4db4da6e4a9d3d18dd244",…}
      // encryptedData
      const decriptedData = decrypt(res.data.edition);
      const decriptedDataParse = JSON.parse(decriptedData);
      let d = decriptedDataParse;
      if (decriptedDataParse.pdfPath === null) {
        setpageViewType("textView");
      }

      if (decriptedDataParse.pdfPath !== null) {
        setpageViewType("pageView");
      }
      console.log(d);
      sepPdfPath(decriptedDataParse.pdfPath);
      setArticles(decriptedDataParse.articles);
      setarticleCount(decriptedDataParse.articles.length);
      setPublicationEdition(decriptedDataParse);
      setActiveArticle(0);
      console.log(decriptedDataParse.pdfPath);
      setloadingPdfUrl(false);
      hideLoader();
      // handlePdf(decriptedDataParse.pdfPath);
    }
  };
  const setNextArticleFun = (index) => {
    setActiveArticle(index);
    console.log(articles[index]);
    // setActiveArticle(decriptedDataParse.articles[0]);
  };
  const showPdfPrivacyPolicy = () => {
    swal({
      title: "Privacy Policy!",
      text: "DO NOT: screengrab, flip, print, share or circulate any content from this site as it will attract stiff penalties for defaulters.!",
      icon: "warning",
      button: "Sure, i won't",
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      confirmButtonColor: "red",
      confirmButtonColor: "#EE7F0D",
    });
    localStorage.setItem("hidePublicationPrivacy", "false");
    sethidePublicationPrivacy(false);
  };

  const [message, setmessage] = useState("");
  const [toggleCommentBox, settoggleCommentBox] = useState(false);
  const [sendComment, setSendingComment] = useState(false);

  const [textViewList, setTextViewList] = useState([
    "Abuja Based fashion...",
    "Women we Love: The ...",
    "  Womenhood",
    "Trending hair Style",
    "Money, Sex and family",
  ]);
  const createComment = async () => {
    const data = {
      editionId: path,
      message: message,
      publicationId: publicationEdition?.publicationId,
    };
    // {editionId, message, userId, publicationId};
    if (message === "") {
      return;
    }
    setSendingComment(true);
    const res = await httpPost(`create_comment`, data);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      getUserComment();
      setmessage("");

      setSendingComment(false);
    }
  };

  const [Replymessage, setReplymessage] = useState("");
  const [sendReply, setSendingReply] = useState(false);
  const createReplyComment = async () => {
    const data = {
      commentId: replyId,
      message: Replymessage,
      publicationId: publicationEdition?.publicationId,
    };
    if (Replymessage === "") {
      return;
    }
    setSendingReply(true);
    const res = await httpPost(`reply_comment`, data);

    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      getUserComment();
      setReplymessage("");
      settoggleCommentBox(true);
      setSendingReply(false);
    }
  };

  const handleReplyChange = (e) => {
    console.log(e.target.value);
    setReplymessage(e.target.value);
  };

  const [getComments, setgetComments] = useState([]);
  const getUserComment = async () => {
    // showLoader()
    const res = await httpGet(`comments/${path}`);
    if (res) {
      if (res.er) {
        // NotificationManager.error(res.er.message);
        return;
      }
      // hideLoader()
      setgetComments(res.data);
    }
  };

  const likeUnLikeEdition = async () => {
    showLoader();
    const res = await httpPost(`like_unlike_edition/${path}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      console.log(res);
      NotificationManager.success(res.message);
      hideLoader();
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setmessage(e.target.value);
  };

  const [replyId, setReplyId] = useState("");
  const [showReplyBox, setshowReplyBox] = useState(false);
  const handleReplyBox = (id) => {
    setReplyId(id);

    setshowReplyBox(!showReplyBox);
  };

  const showCommentsReply = (id) => {
    setReplyId(id);

    settoggleCommentBox(!toggleCommentBox);
    setshowReplyBox(!showReplyBox);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const zoomPluginInstance = zoomPlugin();

  const [hideViwwPaperSidebarOnMobile, setHideViwwPaperSidebarOnMobile] =
    useState(false);
  const [togglearticleListCard, settogglearticleListCard] = useState(false);
  const [articleIndex, setarticleIndex] = useState(0);
  const [articleCount, setarticleCount] = useState(0);

  const renderPage = (props: RenderPageProps) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: "none" }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };

  const handleNextArticleScroll = (index, from) => {
    if (from === "next") {
      if (articleIndex + 1 == articleCount) {
        return;
      } else {
        setNextArticleFun(index + 1);
        setarticleIndex(index + 1);
        scrTtOPRef.current.scrollTop = 20;
        slide("right");
      }
    }

    if (from === "previous") {
      if (articleIndex === 0) {
        return;
      } else {
        setarticleIndex(articleIndex - 1);
        setNextArticleFun(index - 1);
        scrTtOPRef.current.scrollTop = 20;
      }
    }

    if (from === "basic") {
      setarticleIndex(index);
      setNextArticleFun(index);
      scrTtOPRef.current.scrollTop = 20;
      setHideViwwPaperSidebarOnMobile(false);
    }
  };

  function slide(direction) {}

  const showCommentBoxHideDialog = () => {
    setmakeComment(true);
    settogglearticleListCard(!togglearticleListCard);
  };

  console.log(userNotification);
  return (
    <div>
      {userNotification?.body === "" || userNotification?.body == undefined ? (
        ""
      ) : userNotification === null &&
        publicationEdition?.publicationId !==
          userNotification?.publicationId ? (
        ""
      ) : userNotification !== null &&
        publicationEdition?.publicationId ===
          userNotification?.publicationId ? (
        <Tooltip title={userNotification?.title} arrow placement="top-start">
          <div className="userGiftingMessage blobs-container">
            <div className="blob red">
              <img
                src={GiftUser}
                onClick={() => {
                  setShowGifting(!showGifting);
                }}
              />
            </div>
          </div>
        </Tooltip>
      ) : userNotification?.publicationId === null ? (
        <Tooltip title={userNotification?.title} arrow placement="top-start">
          <div className="userGiftingMessage blobs-container">
            <div className="blob red">
              <img
                src={GiftUser}
                onClick={() => {
                  setShowGifting(!showGifting);
                }}
              />
            </div>
          </div>
        </Tooltip>
      ) : (
        ""
      )}

      {userNotification?.body == ""
        ? ""
        : showGifting && (
            <div className="userGiftingMessage-modal">
              <div className="closeGiftModalCss">
                <img
                  src={Close}
                  alt=""
                  onClick={() => {
                    setShowGifting(!showGifting);
                  }}
                />
              </div>
              <div className="gift-user-logo">
                <img src={GiftUserFlat} />
              </div>

              <div className="gifting-msg-body ">
                {userNotification?.featuredImage ? (
                  <img src={userNotification.featuredImage} />
                ) : (
                  ""
                )}

                {userNotification?.body}
              </div>
            </div>
          )}

      <div style={{ position: "relative" }} id="" className="">
        {loadingPdfUrl === true ? (
          ""
        ) : (
          <div className="pdf-section-cover">
            <div className="tohfghHarmburger">
              <div
                className="harmdfuys"
                onClick={() =>
                  setHideViwwPaperSidebarOnMobile(!hideViwwPaperSidebarOnMobile)
                }
              >
                <i
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#EE7F0D",
                    color: "white",
                    padding: "5px",
                    width: "43px",
                    height: "48px",
                    paddingTop: "15px",
                    paddingLeft: "12px",
                  }}
                  class="fa fa-bars"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            {hideViwwPaperSidebarOnMobile == true ||
            window.innerWidth >= 1089 ? (
              <div
                className={`digitalEdition-Sidebar`}
                style={pageViewType === "pageView" ? { display: "none" } : {}}
              >
                <div className="digitalEditWrap">
                  <div className="digital-edition-header">
                    <div className="digital-edition-header-likeContain">
                      {/* <i
                        style={{ cursor: "pointer" }}
                        onClick={likeUnLikeEdition}
                        class="fas fa-heart"
                        style={{ fontSize: "20px", marginRight: "1px" }}
                      ></i> */}
                      {/* <span>Add to Favourites</span> */}
                      {/* <i
                        onClick={showCommentBoxHideDialog}
                        class="fa fa-comment smThip"
                        aria-hidden="true"
                        style={{ fontSize: "20px", marginLeft: "14px" }}
                      ></i> */}
                    </div>
                    {/* <i class="fas fa-share-alt"></i> */}
                    <i
                      onClick={() =>
                        setHideViwwPaperSidebarOnMobile(
                          !hideViwwPaperSidebarOnMobile
                        )
                      }
                      style={{
                        color: "rgba(255, 0, 0, 0.562)",
                        marginLeft: "18px",
                        fontSize: "24px",
                      }}
                      class="fas fa-times tohfghHarmburger"
                    ></i>
                  </div>
                  <div className="image-digital-wrapperkgu">
                    <img src={publicationEdition?.image} />
                  </div>
                  <div className="editHeaderNmae-digital">
                    <h1>{activeArticle?.title}</h1>
                    {/* <p>27 February, 2021</p> */}
                    <span></span>
                  </div>

                  <div className="pageViewSetter ">
                    {publicationEdition.pdfPath === null ? (
                      ""
                    ) : (
                      <div
                        onClick={
                          publicationEdition.pdfPath === null
                            ? () => {
                                swal({
                                  title: "Opps!",
                                  text: "Page view not avalable!",
                                  icon: "warning",
                                  button: "Ok!",
                                });
                              }
                            : () => handlePageViewtyp("pageView")
                        }
                        style={
                          pageViewType === "pageView"
                            ? { backgroundColor: "#EE7F0D" }
                            : {}
                        }
                        className="textViewS"
                      >
                        <img src={shareImg} alt="" />
                        <span>Page View</span>
                      </div>
                    )}
                    {articles.length === 0 ? (
                      ""
                    ) : (
                      <div
                        onClick={
                          articles.length === 0
                            ? ""
                            : () => handlePageViewtyp("textView")
                        }
                        style={
                          pageViewType === "textView"
                            ? { backgroundColor: "#EE7F0D" }
                            : {}
                        }
                        className="pageview"
                      >
                        <img src={textViewImg} alt="" />
                        <span>Digital </span>
                      </div>
                    )}
                    {articles.length === 0 ? (
                      ""
                    ) : (
                      <div className="textViewMobileListContainerMain">
                        <ol>
                          {articles.map((text, index) => {
                            return (
                              <li
                                onClick={() =>
                                  handleNextArticleScroll(index, "basic")
                                }
                                className={`${
                                  activeArticle === index
                                    ? "activeFooterTextViewTab"
                                    : ""
                                }`}
                              >
                                <span
                                  className="Tnumbers"
                                  style={
                                    activeArticle === index
                                      ? { color: "white" }
                                      : {}
                                  }
                                >
                                  {index + 1}
                                </span>

                                {text.title.slice(0, 15)}
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : window.innerWidth <= 1089 ? (
              ""
            ) : (
              ""
            )}

            {pageViewType === "textView" ? (
              <TextView
                articles={articles}
                activeArticle={activeArticle}
                scrTtOPRef={scrTtOPRef}
                articles={articles}
              />
            ) : (
              ""
            )}

            {pageViewType === "pageView" ? (
              <div className="mainPdfCover">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <div
                    style={{
                      height: "100vh",
                      width: "100%",
                      // marginLeft: 'auto',
                      // marginRight: 'auto',
                    }}
                    className="pdfViewSizer"
                  >
                    <Viewer
                      renderLoader={(percentages: number) => (
                        // You can use your own progress bar component
                        <div style={{ width: "240px", marginTop: "10px" }}>
                          <ProgressBar progress={Math.round(percentages)} />
                        </div>
                      )}
                      fileUrl={pdfPath}
                      defaultScale={SpecialZoomLevel.PageWidth}
                      // plugins={[defaultLayoutPluginInstance]}
                      plugins={[zoomPluginInstance]}
                      renderPage={renderPage}
                    />
                  </div>
                </Worker>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <div></div>
      </div>
      {hidePublicationPrivacy === true ? (
        <div className="termsOfUsePdfPage">
          <div
            className="termsOfUsePdfPage-inner"
            onClick={showPdfPrivacyPolicy}
          >
            <img src={warningIcon} alt="" />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="commentLikeBoxWrap">
        <div className="commentSectionBtn">
          <div
            onClick={likeUnLikeEdition}
            style={{ cursor: "pointer", marginLeft: "11px" }}
          >
            <div className="commentCoutEdition">{`${
              getComments.length > 0 ? getComments.length : 0
            }`}</div>
            <img src={LikeIcon} alt="" />
          </div>
          <div
            onClick={() => setmakeComment(true)}
            style={{
              cursor: "pointer",
              marginTop: "-19px",
              marginLeft: "16px",
            }}
          >
            <div className="commentCoutEdition">{`${
              getComments.length > 0 ? getComments.length : 0
            }`}</div>
            <img src={commentIcon} alt="" />
          </div>
        </div>
      </div>

      {makeComment === true ? (
        <Scrollbars
          // style={{ height: "270px " }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          <div className="commentBoxSection">
            <div className="commentHeader">
              <span>Comments</span>
              <span
                onClick={() => setmakeComment(false)}
                style={{ fontWeight: "600", cursor: "pointer" }}
                className="canceCommentBox"
              >
                <i class="far fa-times-circle"></i>
              </span>
            </div>
            <div className="commentBody">
              {getComments.map((data) => {
                return (
                  <React.Fragment>
                    <div className="commentTextBox">
                      <div className="commentUserDetail">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          src={
                            data.user.avatar === null
                              ? MaleAvatar
                              : data.user.avatar
                          }
                          alt=""
                        />
                        <span>{`${data.user.firstName} ${data.user.lastName}`}</span>
                      </div>
                      <span className="commenttextdata">{data.message}</span>
                      <Collapse
                        isOpen={
                          toggleCommentBox === true && replyId === data.id
                            ? true
                            : false
                        }
                      >
                        <Card style={{ backgroundClip: "red !important" }}>
                          <CardBody
                            style={{ backgroundClip: "red !important" }}
                          >
                            {data.commentReply.map((data) => {
                              return (
                                <div className="subComment-wrap">
                                  <div className="commentUserDetail">
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                      src={
                                        data.user.avatar === null
                                          ? MaleAvatar
                                          : data.user.avatar
                                      }
                                      alt=""
                                    />
                                    <span>
                                      {data.user.firstName} {data.user.lastName}
                                    </span>
                                  </div>
                                  <span className="commenttextdata">
                                    {data.message}
                                  </span>
                                </div>
                              );
                            })}
                          </CardBody>
                        </Card>
                      </Collapse>
                      {
                        <Collapse
                          isOpen={
                            replyId === data.id && showReplyBox === true
                              ? true
                              : false
                          }
                        >
                          <Card style={{ backgroundClip: "red !important" }}>
                            <CardBody
                              style={{ backgroundClip: "red !important" }}
                            >
                              <div className="replyInputBoxWrap">
                                <input
                                  value={Replymessage}
                                  type="text"
                                  onChange={handleReplyChange}
                                />{" "}
                                <button onClick={createReplyComment}>
                                  {sendReply === true ? (
                                    <Loader
                                      type="TailSpin"
                                      color="#EF6C00"
                                      height={20}
                                      width={20}
                                    />
                                  ) : (
                                    "Reply"
                                  )}
                                </button>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                      }
                      <div className="commentActions">
                        <span onClick={() => handleReplyBox(data.id)}>
                          Reply
                        </span>
                        {data.commentReply.length > 0 ? (
                          <span onClick={() => showCommentsReply(data.id)}>
                            {data.commentReply.length} Replies
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            <div className="commentBoxUserComment">
              <textarea value={message} onChange={handleChange} type="text" />
              <button onClick={createComment}>
                {" "}
                {sendComment === true ? (
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                  />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </Scrollbars>
      ) : (
        ""
      )}

      {/* {pageViewType === "textView" ? (
        <div>
          <div className="textViewMobileListContainer">
            {togglearticleListCard === true ? (
              <div className="textViewMobileListContainerMain">
                <div className="textViewMobileListContainerMainheaderTit">
                  <span>Articles</span>
                  <div className="fawsomeIconsArticles">
                    <i
                      onClick={likeUnLikeEdition}
                      class="fa fa-thumbs-up smThip"
                      aria-hidden="true"
                    ></i>
                    <i
                      onClick={showCommentBoxHideDialog}
                      class="fa fa-comment smThip"
                      aria-hidden="true"
                    ></i>

                    <i
                      onClick={() => settogglearticleListCard(false)}
                      style={{
                        color: "rgba(255, 0, 0, 0.562)",
                        // marginLeft: "18px",
                        fontSize: "19px",
                        cursor: "pointer",
                      }}
                      class="fas fa-times tohfghHarmburger"
                    ></i>
                  </div>
                </div>

                <ol>
                  {articles.map((text, index) => {
                    return (
                      <li
                        onClick={() => handleNextArticleScroll(index, "basic")}
                        className={`${
                          activeArticle === index
                            ? "activeFooterTextViewTab"
                            : ""
                        }`}
                      >
                        <span className="Tnumbers">{index + 1}</span>

                        {text.title.slice(0, 15)}
                      </li>
                    );
                  })}
                </ol>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="showtextViewMobile">
            <div className="showtextViewMobileClick">
              
              <div
                style={
                  pageViewType === "textView"
                    ? { backgroundColor: "#EE7F0D", padding: "10px" }
                    : {}
                }
                onClick={() =>
                  setHideViwwPaperSidebarOnMobile(!hideViwwPaperSidebarOnMobile)
                }
                className="icontextviewwrapf"
              >
                <Blocks />
              </div>
            </div>
          </div>
          <div
            style={{ position: "relative", width: "100%" }}
            className="tohfghHarmburgerButtonLong"
          >
            <div className="textview-footer">
              <div className="tectViewCol1">
                <div className="tabcontrollerFGoooter">
                  {
                      pageViewType === "textView"?"": <div
                      onClick={
                        publicationEdition.pdfPath === null
                          ? () => {
                              "";
                            }
                          : () => handlePageViewtyp("pageView")
                      }
                      className="icontextviewwrapf"
                    >
                      <FileIcon />
                    </div>
                  }
                 
                  <div
                    style={
                      pageViewType === "textView"
                        ? { backgroundColor: "#EE7F0D" }
                        : {}
                    }
                    className="icontextviewwrapf"
                  >
                    <Blocks />
                  </div>
                </div>
              </div>

              <div className="setPdfFotterCol">
                <div
                  className="arrowLeftF"
                  onClick={() =>
                    handleNextArticleScroll(articleIndex, "previous")
                  }
                >
                  <ArrowPdf />
                </div>
                <div className="showpagesLinkText">
                  <ol id="contP">
                    {articles.map((text, index) => {
                      return (
                        <li
                        id="artPos"
                          onClick={() =>
                            handleNextArticleScroll(index, "basic")
                          }
                          className={`${
                            activeArticle === index
                              ? "activeFooterTextViewTab"
                              : ""
                          }`}
                        >
                          <span className="Tnumbers" >{index + 1}</span>

                          {text.title.slice(0, 15)}
                        </li>
                      );
                    })}
                  </ol>
                </div>
                <div
                  className="arrowRightF"
                  onClick={() => handleNextArticleScroll(articleIndex, "next")}
                >
                  <ArrowPdf />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default App;
