import React, { createContext, useState, useEffect, useContext } from "react";
import {
  httpGet,
  httpPost,
  httpDelete,
} from "../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../components/helpers/loader";


export const ContextWrapState = createContext();

export const ContextWrapStateProvider = (props) => {
  useEffect(() => {
   
  }, []);

  const [reloadCartDetails,setReloadCartDetails] =useState(false)
  const [closeAddToCartModalGlob,setcloseAddToCartModalGlob] =useState(false)

  return (
    <ContextWrapState.Provider
      value={{
        setReloadCartDetails,
        reloadCartDetails,
        setcloseAddToCartModalGlob,
        closeAddToCartModalGlob
      }}
    >
      {props.children}
    </ContextWrapState.Provider>
  );
};
