import { NotificationManager } from "react-notifications";
const validateImage = (file) => {
  let valid = true;
  let message = "uploading...";

  if (file[0] && !/\/(jpg|jpeg|png)$/i.test(file[0].type)) {
    valid = false;
    message = "Invalid picture format";
    return message;
  }
  if (file[0] && parseFloat(file[0].size) > 5097152) {
    valid = false;
    message = "File size exceeds 5MB";
    return message;
  }
  return file[0];
};

export default validateImage;
