import React, { useContext, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { CartContextData } from "../../context/newsPaperContext";
import { UserDataContext } from "../../context/userContext";
import dateFormater from "../helpers/dateFormater";
import { httpPost } from "../helpers/httpMethods";
import { hideLoader, showLoader } from "../helpers/loader";

const SubscriptionCard = ({ data, showRead, viewPaper }) => {
  const { setLoginModal } = useContext(UserDataContext);
  const { onOpenModal } = useContext(CartContextData);

  const cancelSubscription = async (id) => {
    showLoader();
    const res = await httpPost(`cancel_subscription/${id}`);
    if (res) {
      if (res.er) {
        hideLoader();
        console.log("subError", res.er);
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      hideLoader();

      NotificationManager.success("Successfully cancelled subscription");

      console.log(res.data);
    }
  };

  return (
    <div className="subs-wrap">
      <div className="subs-wrap-inner">
        <div className="sub-img-wrap">
          <img src={data.publication.image} alt="" />
        </div>
        <div className="sub-about-wrap">
          <div className="sub-tit">{data.publication.name} </div>
          <div className="sub-type">{data.subType} </div>
          <div className="hr-sub"></div>
          <div className="sub-next">
            Your next payment is {data.amount} NGN, to be charged on{" "}
            {dateFormater(data.nextPaymentDate)}
          </div>
          <div className="sub-warn">
            Your payment will be automatically renewed each{" "}
            <span style={{ fontWeight: 600, color: "black" }}>
              {data.subType === "weekly" ? "Week" : ""}{" "}
              {data.subType === "one-off" ? "Day" : ""}{" "}
              {data.subType === "monthly" ? "Month" : ""}
            </span>
            .
          </div>
        </div>
        <div className="sub-button-wrap">
          {showRead ? (
            <button
              onClick={() =>
                viewPaper.push(`/view_paper/${data.publicationId}`)
              }
            >
              Read
            </button>
          ) : null}
          <button onClick={() => onOpenModal(data, "subSetting")}>
            Upgrade subscription
          </button>
          <button onClick={() => cancelSubscription(data.id)}>
            Cancel subscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
