import React, { createContext, useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import {
  httpGet,
  httpPost,
  httpDelete,
} from "../components/helpers/httpMethods";
import moment from "moment";
import Magazine2 from "../assets/Magazine2.png";
import { hideLoader, showLoader } from "../components/helpers/loader";
import swal from "sweetalert";
import { PaymentContext } from "./paymentContext";
import { UserDataContext } from "./userContext";
import currencyFormatter from "currency-formatter";
import { ContextWrapState } from "./contextHandler";
import dateFormater from "./../components/helpers/dateFormater";
import {
  FormatMontly,
  FormatWeekly,
  FormatDaily,
  FormatYearly,
  FormatQuarter,
  FormatBiannul,
} from "./../components/helpers/subscrptionExp";
import EmeptyData from "../components/helpers/emptyData";
import { set } from "lodash";
export const CartContextData = createContext();

export const CartContextProvider = (props) => {
  const MonnifySDK = window.MonnifySDK;
  const {
    AddToCart,
    sendPaymentDetailsToBackend,
    upDateSubscription,
    multipleCheckout,
  } = useContext(PaymentContext);
  const { reloadCartDetails, closeAddToCartModalGlob, setReloadCartDetails } =
    useContext(ContextWrapState);
  const { userLocation, userIsLogin, reloadExpToken, setLoginModal } =
    useContext(UserDataContext);
  const [CartCount, setCartCount] = useState(0);
  const [cartPage, setcartPage] = useState(0);
  const [cartPublication, setcartPublication] = useState([]);
  const [filterType, setfilterType] = useState("all");
  const [categoriesType, setCategoriesType] = useState({
    name: "all",
    id: null,
  });
  const [frequencyType, setFrequencyType] = useState("all");
  useEffect(() => {
    if (userIsLogin) {
      mySubscriptionsId();
      categories();
      if (cartPage !== "") {
        if (cartPage === "/user/subscription") {
          setcartPublicationPlans(null);
        } else if (cartPage === "/") {
          setPublicationPlans(null);
        }
      }

      console.log("this is from context....(WE GLOBAL)");
      cartCountData();
      getUserCartsDataList();
      if (closeAddToCartModalGlob === false) {
        onCloseModal();
      }
    }
  }, [reloadCartDetails, cartPage, userIsLogin, reloadExpToken]);
  const [UID, setUID] = useState("");

  const idGetter = (id) => {
    setUID(id);
  };

  console.log(categoriesType);

  const [UserCartsDataList, setUserCartsDataList] = useState([]);
  const getUserCartsDataList = async (data) => {
    let res = await httpGet(`my_carts`);
    if (res) {
      setReloadCartDetails(false);
      if (res.er) {
        hideLoader();
        setUserCartsDataList("");
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }

      setUserCartsDataList(res.data);
    }
  };

  const cartCountData = async (data) => {
    try {
      let res = await httpGet(`cart_count`);
      setCartCount(res.data);
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  };
  const [UserCatgories, setUserCatgories] = useState([]);
  const categories = async (data) => {
    try {
      let res = await httpGet(`categories`);
      setUserCatgories(res.data?.categories);
    } catch (error) {}
  };

  const [mySubs, SetMysubs] = useState([]);
  const mySubscriptionsId = async (data) => {
    try {
      let res = await httpGet(`my_subscriptions_id`);
      console.log(">>>my subs", res.data.subscriptions);
      SetMysubs(res.data.subscriptions);
    } catch (error) {}
  };

  const [EditionDataRes, setEditionData] = useState();

  // const handeleAddToCart = async () => {
  //   showLoader();

  //   let res = await httpPost(`add_cart/${EditionDataRes.publicationId}`);
  //   if (res) {
  //     if (res.er) {
  //       NotificationManager.error(res.er.message);
  //       return;
  //     }
  //     NotificationManager.success("Publication added to cart");
  //     getUserCartsDataList();
  //     cartCountData();
  //     setOpen(false);
  //     hideLoader();
  //   }
  // };

  const LikePublication = async () => {
    showLoader();
    let res = await httpPost(`add_cart/${EditionDataRes.publicationId}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      NotificationManager.success("Liked");
      hideLoader();
    }
  };

  const geDeleteID = (ID) => {
    setDeleteCartId(ID);
    handleDeleteModal();
  };
  const [deleteCartID, setDeleteCartId] = useState("");

  const handeleRemoveCart = async (id) => {
    showLoader();

    let res = await httpDelete(`delete_cart/${id}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      swal("Removed!", "data deleted successfully!", "success");
      cartCountData();
      getUserCartsDataList();
      hideLoader();
    }
  };

  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data, from, type) => {
    console.log(data);
    setOpen(!open);
    setEditionData(data);
    setSubFrom(from);
    setType(type);
    getPublicationsSubscriptions(data, from);
    console.log("dataaaa>>>>", data);
  };

  const [subFrom, setSubFrom] = useState("");
  const [cartPublicationPlans, setcartPublicationPlans] = useState(null);
  const [publicationPlans, setPublicationPlans] = useState(null);

  const getPublicationsSubscriptions = async (data, from) => {
    showLoader();
    let res;

    if (from === "subSetting") {
      res = await httpGet(`publication/${data.publicationId}`);
      if (res) {
        if (res.er) {
          hideLoader();
          NotificationManager.error(res.er.message || res.er);
          if (res.er === "Unauthorized, Your token is invalid or expired") {
            setLoginModal();
          }
          return;
        }
        console.log(res.data.edition);
        hideLoader();
        setPublicationPlans(res.data.publication);
      }
    }

    if (from === "homeSub") {
      res = await httpGet(`edition/${data.id}`);
      if (res) {
        if (res.er) {
          hideLoader();
          NotificationManager.error(res.er.message || res.er);
          if (res.er === "Unauthorized, Your token is invalid or expired") {
            setLoginModal();
          }
          return;
        }
        console.log(res.data.edition);
        hideLoader();
        setcartPublicationPlans(res.data.edition);
      }
    }

    if (from === "fromPublicPublication") {
      res = await httpGet(`edition/${data.id}`);
      if (res) {
        if (res.er) {
          hideLoader();
          NotificationManager.error(res.er.message || res.er);
          if (res.er === "Unauthorized, Your token is invalid or expired") {
            setLoginModal();
          }
          return;
        }
        console.log(res.data.edition);
        hideLoader();
        setcartPublicationPlans(res.data.edition);
      }
    }
  };

  const handleDeleteModal = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to perform this action?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handeleRemoveCart(id);
      }
    });
  };
  const handeleClearCart = async () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to perform this action?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ClearCart();
      }
    });
  };

  const ClearCart = async () => {
    showLoader();
    let res = await httpDelete(`clear_carts`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }
      swal("Removed!", "Cart cleared successfully!", "success");
      cartCountData();
      getUserCartsDataList();
    }
    hideLoader();
  };

  const followPublications = async (id) => {
    showLoader();
    let res = await httpPost(`follow/${id}`);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      hideLoader();
      NotificationManager.success(
        "You have successfully followed this publication."
      );
    }
  };

  const [ShowAddToCartModalS, setShowAddToCartModal] = useState(false);
  const onCloseModalCart = () => setShowAddToCartModal(false);
  const onShowAddToCartModalModal = () => {
    setShowAddToCartModal(!ShowAddToCartModalS);
  };
  const ShowAddToCartModal = () => {
    return (
      <div>
        <div id="modal-wrap-cart">
          <Modal open={ShowAddToCartModalS} center onClose={onCloseModalCart}>
            <div className="inner-modal-wrap">
              <div className="cart-modal-header">
                <button onClick={onCloseModalCart} className="btn btn-secondry">
                  Close
                </button>
                <div className="center-product-cartHeader">
                  <span>Checkout Publication Testing</span>
                </div>
              </div>
              {UserCartsDataList === "" ? (
                <EmeptyData text="Opps you don't have any publication in your cart!" />
              ) : (
                UserCartsDataList.map((data) => {
                  return (
                    <div
                      key={data.id}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="cart-productmodal">
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <img src={data.publication.image} alt="" />
                            <div className="modal-about-p">
                              <h1>{data.publication.name}</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${data.amount} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${data.amou} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {data.subscriptionType === "one-off"
                                  ? "Daily Plan"
                                  : ""}{" "}
                                {data.subscriptionType === "weekly"
                                  ? "Weekly Plan"
                                  : ""}{" "}
                                {data.subscriptionType === "monthly"
                                  ? "Monthly Plan"
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() => handleDeleteModal(data.id)}
                              className="fyttt52h"
                            >
                              Remove
                            </button>
                            <button
                              onClick={() =>
                                sendPaymentDetailsToBackend(
                                  data.amount,
                                  data.subscriptionType,
                                  data.currency,
                                  data.publication.id,
                                  data.publication.isPaid
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                            >
                              Subscribe
                              {/* <MakePaymentfL amount="200" /> */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}

              {UserCartsDataList.length > 0 && (
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-danger"
                    style={{
                      padding: "5px  15px",
                      background: "rgb(238, 127, 13)",
                      border: "none",
                    }}
                    onClick={() => {
console.log("USER CART",   UserCartsDataList.reduce(
  (prev, item) => +prev + +item?.amount,
  0
  ));
                      multipleCheckout(
                        UserCartsDataList,
                        UserCartsDataList.reduce(
                          (prev, item) => +prev + +item?.amount,
                          0
                          )
                          )
                        }
                    }
                  >
                    Checkout items
                  </button>
                  <div>
                    Total {` : `}
                    {currencyFormatter.format(
                      UserCartsDataList.reduce(
                        (prev, item) => +prev + +item?.amount,
                        0
                      ),
                      {
                        code: userLocation === "Nigeria" ? "NGN" : "USD",
                      }
                    )}
                  </div>
                </div>
              )}
              {UserCartsDataList.length > 0 && (
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-danger"
                    style={{
                      padding: "5px  15px",
                    }}
                    onClick={handeleClearCart}
                  >
                    clear all
                  </button>
                  <div>{`Total item in cart: ${UserCartsDataList.length}`}</div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  };

  return (
    <CartContextData.Provider
      value={{
        CartCount,
        setCartCount,
        ShowAddToCartModal,
        onShowAddToCartModalModal,
        onOpenModal,
        setOpen,
        onCloseModal,
        setDeleteCartId,
        handleDeleteModal,
        setfilterType,
        filterType,
        frequencyType,
        setFrequencyType,
        idGetter,
        followPublications,
        setcartPage,
        mySubs,
        UserCatgories,
        categoriesType,
        setCategoriesType,
      }}
    >
      {props.children}
      {open && (
        <div id="modal-wrap-cart">
          <Modal open={open} center onClose={onCloseModal}>
            <div className="inner-modal-wrap">
              <div className="cart-modal-header">
                <button onClick={onCloseModal} className="btn btn-secondry">
                  Cancel
                </button>
                <div className="center-product-cartHeader">
                  <span> Subscriptions</span>
                </div>
              </div>
              {publicationPlans === null ? (
                ""
              ) : (
                <React.Fragment>
                  {!Number(publicationPlans.annualFee) ? (
                    ""
                  ) : (
                    <div
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <div className="cart-productmodal">
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <img src={publicationPlans.image} alt="" />
                            <div className="modal-about-p">
                              <h1>{publicationPlans.name}</h1>
                              {/* <p>{dateFormater(publicationPlans.createdAt)}</p> */}
                              <p>Annual Plan</p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal recommendedSub">
                            <p>Recommended</p>
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.annualFee
                                    : publicationPlans.annualDollarFee,
                                  "annually",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              {userLocation === "Nigeria"
                                ? currencyFormatter.format(
                                    ` "" ${publicationPlans.annualFee} " "`,
                                    {
                                      code: "NGN",
                                    }
                                  )
                                : currencyFormatter.format(
                                    ` "" ${publicationPlans.annualDollarFee} " "`,
                                    {
                                      code: "USD",
                                    }
                                  )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!Number(publicationPlans.oneOffFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">One off Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Daily Plan</h1>
                              <p>
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.oneOffFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.oneOffFeeDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatDaily(new Date(), "daily")}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.oneOffFee
                                    : publicationPlans.oneOffFeeDollarFee,
                                  "one-off",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!Number(publicationPlans.weeklyFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">Weekly Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Weekly Plan</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.weeklyFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.weeklyDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatWeekly(new Date(), "weekly")}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.weeklyFee
                                    : publicationPlans.weeklyDollarFee,
                                  "weekly",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!Number(publicationPlans.monthlyFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">Monthly Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Monthly Plan</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.monthlyFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.monthlyFeeDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatMontly(new Date(), "montly")}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.monthlyFee
                                    : publicationPlans.monthlyDollarFee,
                                  "monthly",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!Number(publicationPlans.quarterlyFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">Quarterly Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Quarterly Plan</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.quarterlyFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.quarterlyDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatQuarter(new Date())}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.quarterlyFee
                                    : publicationPlans.quarterlyDollarFee,
                                  "quarterly",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* { New plans UPdate} */}

                  {!Number(publicationPlans.biannuallyFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">Biannual Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Biannual Plan</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.biannuallyFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.biannuallyDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatBiannul(new Date())}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.biannuallyFee
                                    : publicationPlans.biannuallyDollarFee,
                                  "quarterly",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* { New plans UPdate} */}

                  {!Number(publicationPlans.annualFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">Premium Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              <h1>Annual Plan</h1>
                              <p>
                                {" "}
                                {userLocation === "Nigeria"
                                  ? currencyFormatter.format(
                                      ` "" ${publicationPlans.annualFee} " "`,
                                      {
                                        code: "NGN",
                                      }
                                    )
                                  : currencyFormatter.format(
                                      ` "" ${publicationPlans.annualDollarFee} " "`,
                                      {
                                        code: "USD",
                                      }
                                    )}
                              </p>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatYearly(new Date(), "montly")}
                            
                              `}
                              </p>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                upDateSubscription(
                                  userLocation === "Nigeria"
                                    ? publicationPlans.annualFee
                                    : publicationPlans.annualDollarFee,
                                  "annually",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  publicationPlans.id,
                                  publicationPlans.id
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Update subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}

              {cartPublicationPlans === null ? (
                ""
              ) : (
                <React.Fragment>
                  {!Number(cartPublicationPlans.publication.annualFee) ? (
                    ""
                  ) : (
                    <div
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <div className="cart-productmodal">
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <img src={cartPublicationPlans.image} alt="" />
                            <div className="modal-about-p">
                              <h1>{cartPublicationPlans.publication.name}</h1>
                              <p>
                                {`${dateFormater(new Date())}
                              to ${FormatYearly(new Date(), "montly")}
                            
                              `}
                              </p>
                              <p>Annual Plan</p>
                              <div className="button-subscribe-ed-modal hdOnminSUBBTN">
                                <p className="recomdPubthgdt">Recommended</p>
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .annualFee
                                        : cartPublicationPlans.publication
                                            .annualDollarFee,
                                      "annually",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                >
                                  {!cartPublicationPlans.publication.isPaid
                                    ? "Free"
                                    : userLocation === "Nigeria"
                                    ? currencyFormatter.format(
                                        ` "" ${cartPublicationPlans.publication.annualFee} " "`,
                                        {
                                          code: "NGN",
                                        }
                                      )
                                    : currencyFormatter.format(
                                        ` "" ${cartPublicationPlans.publication.annualDollarFee} " "`,
                                        {
                                          code: "USD",
                                        }
                                      )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal recommendedSub">
                            <p className="recomdPubthgdt">Recommended</p>
                            <button
                              onClick={() =>
                                sendPaymentDetailsToBackend(
                                  userLocation === "Nigeria"
                                    ? cartPublicationPlans.publication.annualFee
                                    : cartPublicationPlans.publication
                                        .annualDollarFee,
                                  "annually",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  cartPublicationPlans.publicationId,
                                  cartPublicationPlans.publication.isPaid
                                )
                              }
                              style={{ background: "#ee7f0d" }}
                              className="fyttt52h"
                            >
                              {!cartPublicationPlans.publication.isPaid
                                ? "Free"
                                : userLocation === "Nigeria"
                                ? currencyFormatter.format(
                                    ` "" ${cartPublicationPlans.publication.annualFee} " "`,
                                    {
                                      code: "NGN",
                                    }
                                  )
                                : currencyFormatter.format(
                                    ` "" ${cartPublicationPlans.publication.annualDollarFee} " "`,
                                    {
                                      code: "USD",
                                    }
                                  )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!Number(cartPublicationPlans.publication.oneOffFee) ? (
                    ""
                  ) : (
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <div className="cart-productmodal">
                        <h1 className="p-titl">One off Plan</h1>
                        <div className="product-details-modal">
                          <div className="p-img-se">
                            <div className="modal-about-p">
                              {cartPublicationPlans.publication.typeId ===
                              "eb244fbc-ab2c-4a51-9b6f-034d987f352e" ? (
                                <h1>Buy</h1>
                              ) : (
                                <h1>Daily Plan</h1>
                              )}
                              <p>
                                {cartPublicationPlans.publication.isPaid
                                  ? userLocation === "Nigeria"
                                    ? currencyFormatter.format(
                                        ` "" ${cartPublicationPlans.publication.oneOffFee} " "`,
                                        {
                                          code: "NGN",
                                        }
                                      )
                                    : currencyFormatter.format(
                                        ` "" ${cartPublicationPlans.publication.oneOffDollarFee} " "`,
                                        {
                                          code: "USD",
                                        }
                                      )
                                  : "Free"}
                              </p>
                              {type !== "book" ? (
                                <p>{`${dateFormater(
                                  new Date()
                                )} to ${FormatDaily(new Date(), "montly")}`}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="button-subscribe-ed-modal">
                            <button
                              onClick={() =>
                                sendPaymentDetailsToBackend(
                                  userLocation === "Nigeria"
                                    ? cartPublicationPlans.publication.oneOffFee
                                    : cartPublicationPlans.publication
                                        .oneOffFeeDollarFee,
                                  "one-off",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  cartPublicationPlans.publicationId,
                                  cartPublicationPlans.publication.isPaid
                                )
                              }
                              style={{
                                background: "#ee7f0d",
                                minWidth: "50px",
                              }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              {cartPublicationPlans.publication.typeId ===
                              "eb244fbc-ab2c-4a51-9b6f-034d987f352e"
                                ? "Buy"
                                : "Subscribe"}

                              {/* <MakePaymentfL amount="200" /> */}
                            </button>
                            <button
                              onClick={() =>
                                AddToCart(
                                  userLocation === "Nigeria"
                                    ? cartPublicationPlans.publication.oneOffFee
                                    : cartPublicationPlans.publication
                                        .oneOffFeeDollarFee,
                                  "one-off",
                                  userLocation === "Nigeria" ? "NGN" : "USD",
                                  cartPublicationPlans.publicationId,
                                  cartPublicationPlans.publication.isPaid
                                )
                              }
                              style={{ background: "#2BC48C" }}
                              className="fyttt52h"
                              data-bind="text"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {type !== "book" ? (
                    <>
                      {" "}
                      {!Number(cartPublicationPlans.publication.weeklyFee) ? (
                        ""
                      ) : (
                        <div
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div className="cart-productmodal">
                            <h1 className="p-titl">Weekly Plan</h1>
                            <div className="product-details-modal">
                              <div className="p-img-se">
                                <div className="modal-about-p">
                                  <h1>Weekly Plan</h1>
                                  <p>
                                    {" "}
                                    {cartPublicationPlans.publication.isPaid
                                      ? userLocation === "Nigeria"
                                        ? currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.weeklyFee} " "`,
                                            {
                                              code: "NGN",
                                            }
                                          )
                                        : currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.weeklyDollarFee} " "`,
                                            {
                                              code: "USD",
                                            }
                                          )
                                      : "Free"}
                                  </p>
                                  <p>
                                    {`${dateFormater(new Date())}
                              to ${FormatWeekly(new Date(), "weekly")}
                            
                              `}
                                  </p>
                                </div>
                              </div>
                              <div className="button-subscribe-ed-modal">
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .weeklyFee
                                        : cartPublicationPlans.publication
                                            .weeklyDollarFee,
                                      "weekly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Subscribe
                                  {/* <MakePaymentfL amount="200" /> */}
                                </button>
                                <button
                                  onClick={() =>
                                    AddToCart(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .weeklyFee
                                        : cartPublicationPlans.publication
                                            .weeklyDollarFee,
                                      "weekly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId
                                    )
                                  }
                                  style={{ background: "#2BC48C" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!Number(cartPublicationPlans.publication.monthlyFee) ? (
                        ""
                      ) : (
                        <div
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div className="cart-productmodal">
                            <h1 className="p-titl">Monthly Plan</h1>
                            <div className="product-details-modal">
                              <div className="p-img-se">
                                <div className="modal-about-p">
                                  <h1>Monthly Plan</h1>
                                  <p>
                                    {" "}
                                    {cartPublicationPlans.publication.isPaid
                                      ? userLocation === "Nigeria"
                                        ? currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.monthlyFee} " "`,
                                            {
                                              code: "NGN",
                                            }
                                          )
                                        : currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.monthlyFeeDollarFee} " "`,
                                            {
                                              code: "USD",
                                            }
                                          )
                                      : "Free"}
                                  </p>
                                  <p>
                                    {`${dateFormater(new Date())}
                              to ${FormatMontly(new Date(), "monthly")}
                            
                              `}
                                  </p>
                                </div>
                              </div>
                              <div className="button-subscribe-ed-modal">
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .monthlyFee
                                        : cartPublicationPlans.publication
                                            .monthlyDollarFee,
                                      "monthly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Subscribe
                                  {/* <MakePaymentfL amount="200" /> */}
                                </button>
                                <button
                                  onClick={() =>
                                    AddToCart(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .monthlyFee
                                        : cartPublicationPlans.publication
                                            .monthlyDollarFee,
                                      "monthly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId
                                    )
                                  }
                                  style={{ background: "#2BC48C" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* NEW Plans added section quarterly  */}
                      {!Number(
                        cartPublicationPlans.publication.quarterlyFee
                      ) ? (
                        ""
                      ) : (
                        <div
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div className="cart-productmodal">
                            <h1 className="p-titl">Quarterly Plan</h1>
                            <div className="product-details-modal">
                              <div className="p-img-se">
                                <div className="modal-about-p">
                                  <h1>Quarterly Plan</h1>
                                  <p>
                                    {" "}
                                    {cartPublicationPlans.publication.isPaid
                                      ? userLocation === "Nigeria"
                                        ? currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.quarterlyFee} " "`,
                                            {
                                              code: "NGN",
                                            }
                                          )
                                        : currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.quarterlyDollarFee} " "`,
                                            {
                                              code: "USD",
                                            }
                                          )
                                      : "Free"}
                                  </p>
                                  <p>
                                    {`${dateFormater(new Date())}
                              to ${FormatQuarter(new Date())}
                            
                              `}
                                  </p>
                                </div>
                              </div>
                              <div className="button-subscribe-ed-modal">
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .quarterlyFee
                                        : cartPublicationPlans.publication
                                            .quarterlyDollarFee,
                                      "quarterly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Subscribe
                                  {/* <MakePaymentfL amount="200" /> */}
                                </button>
                                <button
                                  onClick={() =>
                                    AddToCart(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .quarterlyFee
                                        : cartPublicationPlans.publication
                                            .quarterlyDollarFee,
                                      "quarterly",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId
                                    )
                                  }
                                  style={{ background: "#2BC48C" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* NEW Plans added section  */}
                      {!Number(
                        cartPublicationPlans.publication.biannuallyFee
                      ) ? (
                        ""
                      ) : (
                        <div
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div className="cart-productmodal">
                            <h1 className="p-titl">Biannual Plan</h1>
                            <div className="product-details-modal">
                              <div className="p-img-se">
                                <div className="modal-about-p">
                                  <h1>Biannual Plan</h1>
                                  <p>
                                    {" "}
                                    {cartPublicationPlans.publication.isPaid
                                      ? userLocation === "Nigeria"
                                        ? currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.biannuallyFee} " "`,
                                            {
                                              code: "NGN",
                                            }
                                          )
                                        : currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.biannuallyDollarFee} " "`,
                                            {
                                              code: "USD",
                                            }
                                          )
                                      : "Free"}
                                  </p>
                                  <p>
                                    {`${dateFormater(new Date())}
                              to ${FormatBiannul(new Date())}
                            
                              `}
                                  </p>
                                </div>
                              </div>
                              <div className="button-subscribe-ed-modal">
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .biannuallyFee
                                        : cartPublicationPlans.publication
                                            .biannuallyDollarFee,
                                      "biannually",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Subscribe
                                  {/* <MakePaymentfL amount="200" /> */}
                                </button>
                                <button
                                  onClick={() =>
                                    AddToCart(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .biannuallyFee
                                        : cartPublicationPlans.publication
                                            .biannuallyDollarFee,
                                      "biannually",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId
                                    )
                                  }
                                  style={{ background: "#2BC48C" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!Number(cartPublicationPlans.publication.annualFee) ? (
                        ""
                      ) : (
                        <div
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div className="cart-productmodal">
                            <h1 className="p-titl">Annual Plan</h1>
                            <div className="product-details-modal">
                              <div className="p-img-se">
                                <div className="modal-about-p">
                                  <h1>Premium Plan</h1>
                                  <p>
                                    {" "}
                                    {cartPublicationPlans.publication.isPaid
                                      ? userLocation === "Nigeria"
                                        ? currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.annualFee} " "`,
                                            {
                                              code: "NGN",
                                            }
                                          )
                                        : currencyFormatter.format(
                                            ` "" ${cartPublicationPlans.publication.annualDollarFee} " "`,
                                            {
                                              code: "USD",
                                            }
                                          )
                                      : "Free"}
                                  </p>
                                  <p>
                                    {`${dateFormater(new Date())}
                              to ${FormatYearly(new Date(), "monthly")}
                            
                              `}
                                  </p>
                                </div>
                              </div>
                              <div className="button-subscribe-ed-modal">
                                <button
                                  onClick={() =>
                                    sendPaymentDetailsToBackend(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .annualFee
                                        : cartPublicationPlans.publication
                                            .annualDollarFee,
                                      "annually",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId,
                                      cartPublicationPlans.publication.isPaid
                                    )
                                  }
                                  style={{ background: "#ee7f0d" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Subscribe
                                  {/* <MakePaymentfL amount="200" /> */}
                                </button>
                                <button
                                  onClick={() =>
                                    AddToCart(
                                      userLocation === "Nigeria"
                                        ? cartPublicationPlans.publication
                                            .annualFee
                                        : cartPublicationPlans.publication
                                            .annualDollarFee,
                                      "annually",
                                      userLocation === "Nigeria"
                                        ? "NGN"
                                        : "USD",
                                      cartPublicationPlans.publicationId
                                    )
                                  }
                                  style={{ background: "#2BC48C" }}
                                  className="fyttt52h"
                                  data-bind="text"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
          </Modal>
        </div>
      )}
    </CartContextData.Provider>
  );
};
