import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import TransationTable from "../../components/tables/transationsTable";
import { httpGet } from "../../components/helpers/httpMethods";
import { UserDataContext } from "./../../context/userContext";
import { NotificationManager } from "react-notifications";

export default function Home(props) {
  const [Showloading, setLoading] = useState(true);
  const [transations, settransations] = useState([]);
  const { setLoginModal } = useContext(UserDataContext);
  useEffect(() => {
    getTransations();
  }, []);

  const getTransations = async () => {
    const res = await httpGet(`user_payment_history`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        return;
      }
      console.log(res.data.paymentHistory);
      settransations(res.data.paymentHistory);
      setLoading(false);
      // handlePdf(res.data.edition.pdfPath);
    }
  };

  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="section-wrap">
          <div className="wrapTransationTable">
            <TransationTable transations={transations} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
