import React, { useContext, useEffect } from "react";
import "./cards.css";
import DateFormater from "../helpers/dateFormater";
import { CartContextData } from "../../context/newsPaperContext";
import Truncate from "../../components/helpers/truncate";
import { PaymentContext } from "../../context/paymentContext";
export default function NewspaperCards({
  header,
  viewPaper,
  magazines,
  userIsLogin,
}) {
  const { onOpenModal, followPublications, mySubs } =
    useContext(CartContextData);
  const { toggleFreePdfModal } = useContext(PaymentContext);
  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <div className="news-wrap-home">
        <h1 className="edition-type-home">{header}</h1>
        <div className={`TNSNewsPaperCover`}>
          {magazines
            ? magazines.map((data) => {
                if (data.editions.length) {
                  return (
                    <div key={data.id} className="newspaperWrapBody">
                      <div style={{ position: "relative" }}>
                        <div className="wraopNewpaperIMg">
                          {!data.isPaid ? (
                            <button
                              onClick={() =>
                                toggleFreePdfModal("newspaper", data)
                              }
                              className="free-button"
                            >
                              Free
                            </button>
                          ) : null}
                          <div className="paperImgNew">
                            <img
                              src={
                                data.editions.length
                                  ? data.editions[0].image
                                  : ""
                              }
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="paperImgHover">
                          <div className="subscribeToPublicationBtn">
                            <button
                              onClick={() =>
                                userIsLogin === true
                                  ? mySubs.includes(data.id)
                                    ? viewPaper.push(`/view_paper/${data.id}`)
                                    : data.isPaid
                                    ? onOpenModal(
                                        data.editions[0],
                                        "homeSub",
                                        "book"
                                      )
                                    : toggleFreePdfModal("book", data)
                                  : viewPaper.push("/login")
                              }
                            >
                              {mySubs.includes(data.id) ? "Read" : "Buy"}
                            </button>
                            <div className="followEditionIcon">
                              <i
                                onClick={
                                  userIsLogin === true
                                    ? () => followPublications(data.id)
                                    : () => viewPaper.push("/login")
                                }
                                class="fas fa-user-plus"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="TNSAboutMagazine">
                          <div className="TNSAboutMagazine-name">
                            <p
                              onClick={() =>
                                viewPaper.push(`/view_paper/${data.id}`)
                              }
                            >
                              {Truncate(data.name, 30)}
                            </p>
                            <p>
                              {DateFormater(
                                data.editions.length
                                  ? data.editions[0].publishedDate
                                  : ""
                              )}
                            </p>
                          </div>

                          <div className="TNSAboutMagazine-socialEngagement">
                            <i className="fas fa-comments"></i>
                            <span>
                              {data.editions.legnth
                                ? data.editions[0].comment.length
                                : 0}
                            </span>
                            <i className="fas fa-thumbs-up"></i>
                            <span>
                              {data.editions.length
                                ? data.editions[0].like.length
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
}
