import { useState } from "react";
import { usePaystackPayment } from "react-paystack";

const usePayStack = () => {
  const [config, setConfig] = useState({});
  const initializePayment = usePaystackPayment(config);

  const setPayStackConfig = ({ amount, reference, email }) => {
    setConfig({
      publicKey: "pk_live_d0b21acfa964eb479672bc376953fc68a85283b6",
      // publicKey: "pk_test_003f1fe1d761f27da6d0d039938fb52fb47dd413",
      amount: amount * 100,
      reference,
      email,
    });
  };

  // Pay With PayStack
  const PayWithPayStack = (onSuccess, onClose) => {
    initializePayment(onSuccess, onClose);
  };

  return {
    setPayStackConfig,
    PayWithPayStack,
  };
};

export default usePayStack;
