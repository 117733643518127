import React, { createContext, useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import axios from "axios";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-responsive-modal/styles.css";
import { NotificationManager } from "react-notifications";
import "./context.css";
import { httpGet } from "../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../components/helpers/loader";
import { setLocalItem } from "../components/helpers/authService";
import UserModalLogin from "../pages/Access/modalLogin";

import JWT from "jwt-decode";
export const UserDataContext = createContext();

export const UserDataProvider = (props) => {
  const [userDataFromBackend, setuserDataFromBackend] = useState("");

  const [UserInfo, setUserInfo] = useState(null);

  const [userIsLogin, setUserIsLogin] = useState(false);
  const [reloadExpToken, setReloadExpToken] = useState(false);
  const [loadingUserData, setloadingUserData] = useState(true);
  const [userLocation, setUserLocation] = useState("Nigeria");
  const [sLoginM, setsLoginM] = useState(localStorage.getItem("showModal"));
  const [ToggleLoginModal, setToggleLoginModal] = useState(false);

  const setLoginModal = () => {
    setToggleLoginModal(!ToggleLoginModal);
  };

  useEffect(() => {
    // getUserProfile();
    // if (userIsLogin) {
    getUserProfile();
    // }
    checkUserIsLogin();
    GetUserInfoFromStorge();
  }, [reloadExpToken, sLoginM, userIsLogin]);

  const getUserProfile = async () => {
    const checkToken = localStorage.getItem("token");
    console.log("UserToken>>>", checkToken);
    if (checkToken === null || checkToken === undefined) {
      return console.log("New User");
    }

    // showLoader();
    let res = await httpGet(`auth/profile_details`);

    console.log("RESPONSE PPPPPPPP", res);

    if (res) {
      if (res.er) {
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        console.log(res.er);
        hideLoader();

        return;
      }
      hideLoader();
      console.log(res.data.country);
      // setUserLocation(res.data.country);
      setUserLocation("Nigeria");
      setuserDataFromBackend(res.data);
      setLocalItem("user", res.data);
      GetUserInfoFromStorge();
      setloadingUserData(false);
    }

    hideLoader();
  };

  const checkUserIsLogin = () => {
    let userToken = localStorage.getItem("token");
    //  alert("userToken>>>>", userToken);
    if (userToken) {
      let decodedToken = JWT(userToken);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("expired");

        setLoginModal();

        // window.location.href = `/`;
        setUserIsLogin(false);
      } else {
        console.log("not expired");
        setUserIsLogin(true);
      }
    }
  };

  const GetUserInfoFromStorge = () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    setUserInfo(parseUserData);
    const setPhoneNumber = localStorage.getItem("setPhoneNumber");
    if (setPhoneNumber === "true") {
      swal({
        title: "Update Mobile Number",
        text: "In order to be able to login with your mobile number, please update your profile.",
        icon: "info",
        dangerMode: true,
      }).then(() => {});
    }
  };

  // const failedTogetLocation = () => {
  //   // Get user registered location
  //   let userRegisteredLocation = UserInfo ? UserInfo.country : "Nigeria";
  //   setUserLocation(userRegisteredLocation);
  //   console.log(">>>>User Location", UserInfo.country);
  // };

  // const getLocation = async (position) => {
  //   if (position === undefined) {
  //   } else {
  //     const { latitude, longitude } = await position.coords;
  //     let res = await axios
  //       .get(
  //         `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=4f279bd0440d479fb63d373ec654e584`
  //       )
  //       .then((res) => {
  //         console.log(
  //           "User location>>>",
  //           res.data.results[0].components.country
  //         );
  //         setUserLocation(res.data.results[0].components.country);
  //       });
  //   }
  // };

  const LogUserOut = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Logout ?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal(
          "Success!",
          "You have successfully logged out of the TNS Service!",
          "success"
        );
        localStorage.clear();
        setTimeout(() => {
          localStorage.setItem("expiredToken", "false");
          window.location.href = "/login";
        }, 500);
      }
    });
  };

  return (
    <UserDataContext.Provider
      value={{
        UserInfo,
        setUserInfo,
        userLocation,
        LogUserOut,
        userIsLogin,
        checkUserIsLogin,
        GetUserInfoFromStorge,
        getUserProfile,
        userDataFromBackend,
        loadingUserData,
        ToggleLoginModal,
        setLoginModal,
        reloadExpToken,
      }}
    >
      {props.children}
      <UserModalLogin
        ToggleLoginModal={ToggleLoginModal}
        setLoginModal={setLoginModal}
        setReloadExpToken={setReloadExpToken}
        sLoginM={sLoginM}
        LogUserOut={LogUserOut}
      />
    </UserDataContext.Provider>
  );
};
