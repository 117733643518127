import React, { useState } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../helpers/dateFormater";
export default function TransationTable(props) {
  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);

  const data = props.transations;

  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Action Performed", field: "action" },
          { title: "Reference", field: "Reference" },
          { title: "Date", field: "date" },
          { title: "Amount", field: "amount" },
          { title: "status", field: "status" },
        ]}
        data={props.transations.map((data) => {
          return {
            action: data.message,
            Reference: data.reference,
            date: dateFormater(data.createdAt),
            amount: `NGN ${data.amount}`,
            status: data.paymentStatus,
            // Check: (
            //   <div classaction="LastLoginCheckbox">
            //     <input id="c1" type="checkbox" />
            //   </div>
            // ),
          };
        })}
        title="Transaction history"
      />
    </div>
  );
}
