import React, { useEffect, useState } from "react";
import checkbox from "../../assets/Checkbox.svg";
import redeemCardImage from "../../assets/redeem-coupon-image.svg";
import employmentIcon from "../../assets/empoyment-icon.svg";
import settingIcon from "../../assets/setting-icon.svg";
import busIcon from "../../assets/bus-icon.svg";

import "./redeem-card.css";
import useRedeemCard from "./useRedeemCard";
import SuccessView from "./SuccessView";

const benefits = [
  "Access to Newspapers & Magazines",
  "Pay For Change of Name and more",
  "Buy your friends and family their favorite newspaper & magazine",
];

function RedeemCard() {
  const { redeem } = useRedeemCard();
  const [message, setMessage] = useState({ success: false, msg: "" });
  const [openModal, setModal] = useState(false);
  const [code, setCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);



const urlParams = new URLSearchParams(window.location.search);
const queryCode = urlParams.get('code');

useEffect(() => {
  setCode(queryCode)
}, [queryCode])
  

  const onOpenModal = ({ success, msg }) => {
    setMessage({ success, msg });
    setModal(true);
  };
  const onCloseSuccess = () => {
    setModal(false);
    setMessage({ success: false, msg: "" });
  };

  const onRedeem = async () => {
    setIsProcessing(true);
    try {
      const res = await redeem(code);
      if (res.status === 200) {
        setIsProcessing(false);
        setCode("");
        const msg = `Your wallet have been successfully credited with ${res.payload.amount} worth of subscription and service fee to subscribe any publication of choice and other services`;
        onOpenModal({ success: true, msg });
      }
    } catch (error) {
      const msg = "coupon has been used, expired or invalid";
      setIsProcessing(false);
      onOpenModal({ success: false, msg });
      setCode("");
    }
  };

  return (
    <>
      <div className="container redeem-card-container">
        <div className="left-col">
          <p className="heading">
            Explore our vast collection of digital publications and choose your
            favorites! Credit Your Wallets
          </p>
          <p className="heading2">Credit Your Wallets</p>
          <div>
            {benefits.map((text, i) => (
              <div key={i} className="benefit">
                <img
                  src={checkbox}
                  title="Check box"
                  className="checkbox-icon"
                  alt="img-data"
                />
                <p>{text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="right-col">
          {openModal ? (
            <SuccessView onClose={onCloseSuccess} message={message} />
          ) : (
            <div className="redeem-box">
              <p className="title">Redeem your coupon code to cash</p>
              <img
                src={redeemCardImage}
                alt="redeem coupon"
                className="redeem-coupon-image"
              />
              <div className="benefit2-container">
                <div className="benefit2">
                  <div className="icon">
                    <img
                      src={employmentIcon}
                      title="employment box"
                      className="employment-icon"
                      alt="img-data"
                    />
                  </div>
                  <div>
                    <p>Quickest delivery</p>
                  </div>
                </div>
                <div className="benefit2">
                  <div className="icon">
                    <img
                      src={settingIcon}
                      title="employment box"
                      className="employment-icon"
                      alt="img-data"
                    />
                  </div>
                  <div>
                    <p>News on the Go!</p>
                  </div>
                </div>
                <div className="benefit2">
                  <div className="icon">
                    <img
                      src={busIcon}
                      title="employment box"
                      className="employment-icon"
                      alt="img-data"
                    />
                  </div>
                  <div>
                    <p>On Time</p>
                  </div>
                </div>
              </div>
              <div style={{ height: "80px" }}>
                <input
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter code"
                  value={code}
                  type={"text"}
                  className="code-input"
                />
                {/* <p className="message">invalid used successful</p> */}
              </div>
              <button onClick={onRedeem}>
                {isProcessing ? "Loading..." : "Redeem Now"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RedeemCard;
