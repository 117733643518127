import React from "react";
import SubscriptionCard from "./SubscriptionCard";

const ActiveSubscriptions = ({ data, viewPaper }) => {
  return (
    <div>
      <h1 className="subscription-header">Active ({data.length})</h1>
      <div className="mt-20">
        {data.map((subscription, i) => (
          <SubscriptionCard
            key={i}
            data={subscription}
            showRead={true}
            viewPaper={viewPaper}
          />
        ))}
      </div>
    </div>
  );
};

export default ActiveSubscriptions;
