import React, { useState, useEffect } from "react";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  validatePassword,
} from "../../components/helpers/validateInput";
import { NotificationManager } from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import swal from "sweetalert";
export default function ForgotPassword({ match, history }) {
  useEffect(() => {}, []);
  const [userAccount, setUserAccount] = useState({
    email: "",
  });

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = validatePassword(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    } else {
      setValidated(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errorInput.password === "Looks Good!") {
      showLoader();
      const data = {
        password: userAccount.password,
      };
      let res = await httpPost(
        `auth/user/reset_password/${match.params.id}`,
        data
      );

      if (res) {
        if (res.er) {
          NotificationManager.error(res.er.message);
          return;
        } else {
          swal("Success", "Your password has successfully updated.").then(() =>
            history.push("/login")
          );
        }

        hideLoader();
      }
    }

    hideLoader();
  };

  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  return (
    <div className="login-wrap-home">
      <div className="login-wrap-home-input">
        <h1 className="login-header-text">Password Reset</h1>
        <p style={{ textAlign: "center" }}>Create a new password</p>
        <form className="login-form-wrap">
          <div className="login-col-three-home">
            <div className="inPutHomeWrapP">
              <input
                style={{ marginTop: "15px" }}
                name="password"
                onChange={handleChange}
                placeholder="Enter password"
                value={userAccount.password}
                type={`${showPass ? "text" : "password"}`}
              />
              <div className="toggleViewPassword">
                <i
                  onClick={toggleSeePasswordIcon}
                  className={`fas ${showPass ? " fa-eye" : " fa-eye-slash"}`}
                ></i>
                {/* <i class="fas fa-eye-slash"></i> */}
              </div>
              <div className="error-container">
                <span
                  style={
                    errorInput.password === "Looks Good!"
                      ? { color: "green" }
                      : {}
                  }
                  className="AppError"
                >
                  {errorInput.password}
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="submit-login-details-home">
          <button onClick={handleSubmit}>Reset Password</button>
        </div>
        {/* <div className="forgot-password-wrap-home">
          <Link to="/forgot_password">Can’t log in?</Link>
          <Link to="/create_account">Don’t have an account?</Link>
        </div> */}
      </div>
    </div>
  );
}
