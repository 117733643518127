import React, { useState, useEffect, useContext, useMemo } from 'react';
import './index.css';
import Loader from 'react-loader-spinner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { httpPostFormData, httpPut, httpPatch, httpGet, httpPost, httpDelete } from '../../components/helpers/httpMethods';
import MaleAvatar from '../../assets/male.png';
import femaleAvatar from '../../assets/female.png';
import { hideLoader, showLoader } from '../../components/helpers/loader';
import { ValidateInput, ValidateEmail } from '../../components/helpers/validateInput';
import fb from '../../assets/p-fb.png';
import ig from '../../assets/p-ig.png';
import twit from '../../assets/p-twit.png';
import youtube from '../../assets/p-youtube.png';
import { UserDataContext } from '../../context/userContext';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Avatar from 'react-avatar-edit';
import { uid } from 'uid';
import swal from 'sweetalert';
import UploadImage from '../../components/helpers/uploadImage';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import Select from 'react-select';
import countryList from 'react-select-country-list';
export default function Home(props) {
  const options = useMemo(() => countryList().getData(), []);
  const [EditProfilePicModalOpen, setEditProfilePicModalOpen] = useState(false);
  const OpenEditProfilePicModalModal = () => setEditProfilePicModalOpen(true);
  const setOpenEditProfilePicModalModal = () => setEditProfilePicModalOpen(false);

  const [ChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const OpenChangePasswordModalModal = () => setChangePasswordModalOpen(true);
  const setOpenChangePasswordModalModal = () => setChangePasswordModalOpen(false);
  const [userCountry, setuserCountry] = useState('');
  const { UserInfo, userDataFromBackend, getUserProfile, loadingUserData, userIsLogin } = useContext(UserDataContext);
  const [userAccount, setUserAccount] = useState({
    email: '',
    password: '',
    mobile: '',
    repeatCurrentPassword: '',
    currentPassword: '',
    oldPassword: '',
    username: '',
    firstName: '',
    lastName: '',
    tagLine: '',
    jobPosition: '',
    location: '',
    ig: '',
    twit: '',
    fb: '',
    youtube: '',
  });

  const userDataInputes = () => {
    setUserAccount({
      email: userDataFromBackend.email,
      password: '',
      mobile: `${userDataFromBackend.countryCode}${userDataFromBackend.phoneNumber}`,
      repeatCurrentPassword: '',
      currentPassword: '',
      oldPassword: '',
      username: userDataFromBackend.username,
      firstName: userDataFromBackend.firstName,
      lastName: userDataFromBackend.lastName,
      tagLine: userDataFromBackend.bio,
      jobPosition: userDataFromBackend.jobPosition,
      location: userDataFromBackend.country,
      youtube: userDataFromBackend.youtubeHandle,
      twit: userDataFromBackend.twitterHandle,

      ig: userDataFromBackend.instagramHandle,
      fb: userDataFromBackend.fbHandle,
    });

    setuserCountry(userDataFromBackend.country);
    setuserNumber(`${userDataFromBackend.countryCode}${userDataFromBackend.phoneNumber}`);
  };
  useEffect(() => {
    userDataInputes();

    document.body.style.backgroundColor = '#F4F4F4';

    var elementsArray = document.getElementsByClassName('section-wrap');
    elementsArray[0].style.backgroundColor = '#F4F4F4';

    return () => {
      document.body.style.backgroundColor = 'white';
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, [loadingUserData, userIsLogin]);

  const onClose = () => {
    setPreview(preview);
  };
  const [imgSrc, setimgSrc] = useState(null);

  const [preview, setPreview] = useState(userDataFromBackend.avatar === null ? MaleAvatar : userDataFromBackend.avatar);
  const onCrop = (preview) => {
    console.log(imgSrc);
    setPreview(preview);
    dataURLtoFile(preview, `userAvatar${uid(32)}`);
  };
  const [imageFile, setImageFile] = useState('');
  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const f = new File([u8arr], filename, { type: mime });
    setImageFile(f);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 921680) {
      alert('File is too big!');
      elem.target.value = '';
    }
  };

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: '',
    password: '',
    mobile: '',
    repeatCurrentPassword: '',
    currentPassword: '',
    oldPassword: '',
    username: '',
    firstName: '',
    lastName: '',
    tagLine: '',
    jobPosition: '',
    location: '',
  });
  const [userNumber, setuserNumber] = useState('');

  const handleChange = (e) => {
    if (e.target.name === 'email') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === 'mobile') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, mobile: testValid });
    }

    if (e.target.name === 'repeatCurrentPassword') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, repeatCurrentPassword: testValid });
    }

    if (e.target.name === 'currentPassword') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, currentPassword: testValid });
    }

    if (e.target.name === 'oldPassword') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, oldPassword: testValid });
    }

    if (e.target.name === 'username') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, username: testValid });
    }

    if (e.target.name === 'password') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }

    if (e.target.name === 'firstName') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
    }

    if (e.target.name === 'lastName') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, lastName: testValid });
    }

    if (e.target.name === 'location') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, location: testValid });
    }

    if (e.target.name === 'jobPosition') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, jobPosition: testValid });
    }

    if (e.target.name === 'tagLine') {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, tagLine: testValid });
    } else {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
    }
  };

  const UpdateUserAvatar = async () => {
    if (imageFile === '') {
      NotificationManager.error('Please Upload An Image');
      return;
    }
    showLoader();
    const im = await UploadImage(imageFile);
    const data = { avatar: im.imagePath };
    let res = await httpPatch(`auth/edit_profile`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      getUserProfile();
      hideLoader();
      NotificationManager.success('Profile Image Updated Successfully.');
      setOpenEditProfilePicModalModal();
    }
  };

  const updateUserProfile = async () => {
    if (userNumber === '' || userNumber === null || userNumber === undefined) {
      return NotificationManager.error('Phone number is required.');
    }

    if (userCountry === '' || userCountry === null || userCountry === undefined) {
      return NotificationManager.error('Country is required.');
    }
    let countryCode = `+${parsePhoneNumber(userNumber).countryCallingCode}`;
    let sliceNum = userNumber.slice(-10);
    let data = {
      bio: userAccount.tagLine,
      country: userCountry.label,
      email: userAccount.email,
      fbHandle: userAccount.fb,
      firstName: userAccount.firstName,
      instagramHandle: userAccount.ig,
      countryCode,
      phoneNumber: sliceNum,
      jobPosition: userAccount.jobPosition,
      lastName: userAccount.lastName,
      twitterHandle: userAccount.twit,
      username: userAccount.username,
      youtubeHandle: userAccount.youtube,
    };
    showLoader();
    let res = await httpPatch(`auth/edit_profile`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      getUserProfile();
      hideLoader();
      NotificationManager.success('Profile details updated successfully');
      console.log('>>>user', res);
    }
  };
  const [userPassword, setUserPassword] = useState({
    newPassword: '',
    confirmNewPassword: '',
    oldPassword: '',
  });
  const handleUserPasswordInputChange = async (e) => {
    setUserPassword({
      ...userPassword,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassword = async () => {
    if (userPassword.confirmNewPassword !== userPassword.newPassword) {
      swal({
        title: 'Opps!',
        text: 'Passwords do not match!',
        icon: 'warning',
        dangerMode: true,
      });
    }
    const data = {
      newPassword: userPassword.newPassword,
      currentPassword: userPassword.oldPassword,
    };
    showLoader();
    let res = await httpPatch(`auth/change_password`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      // getUserProfile();
      // setLocalItem("user", userDataFromBackend);
      hideLoader();
      swal('Yepp', 'Password updated  successfully.');
      console.log('user>>>>', res);
    }
  };

  const changeHandler = (value) => {
    // alert(value.label);
    setuserCountry(value);
  };

  const [Showloading, setLoading] = useState(false);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="section-wrap">
          <div className="user-profile-layout">
            <div className="user-profile-card-one">
              <div className="user-profilecard-inner">
                <div className="user-avatar-circle">
                  <img src={`${userDataFromBackend.avatar === null ? MaleAvatar : userDataFromBackend.avatar}`} alt="" />
                </div>
                <div className="username-profile">
                  <span style={{ fontWeight: 600 }}>
                    {`${userDataFromBackend ? (userDataFromBackend.username === null ? '' : '@') : ''}`}

                    {`${userDataFromBackend ? (userDataFromBackend.username === null ? '' : userDataFromBackend.username) : ''}`}
                  </span>
                  <span> {`${userDataFromBackend.firstName} ${userDataFromBackend.lastName}`} </span>
                </div>

                <div className="profile-pic-chaageImage">
                  <button onClick={OpenEditProfilePicModalModal}>Change Profile Pic</button>
                </div>
              </div>
            </div>
            <div className="user-profile-card-two">
              <div className="user-profile-card-two-inner-one">
                <h1 className="userProfile-input-header">Account data</h1>
                <div className="profileinput-box">
                  <p style={{ paddingBottom: '20px' }} className={`${userAccount.username === '' ? '' : 'move-label-up-profile'}`}>
                    User Name
                  </p>
                  <input type="text" name="username" onChange={handleChange} value={userAccount.username} />
                  <span
                    style={errorInput.username === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}}
                    className="AppError appErrorProfil"
                  >
                    {errorInput.username}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p  style={{paddingBottom: "20px"}} className={`${userAccount.email === '' ? '' : 'move-label-up-profile'}`}>Email</p>
                  <input type="text" name="email" onChange={handleChange} value={userAccount.email} />
                  <span style={errorInput.email === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}} className="AppError appErrorProfil">
                    {errorInput.email}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p  style={{paddingBottom: "20px"}}  className={`${userAccount.tagLine === '' ? '' : 'move-label-up-profile'}`}>Tagline ( 1000 character max )</p>
                  <textarea type="text" name="tagLine" onChange={handleChange} value={userAccount.tagLine} />
                  <span style={errorInput.tagLine === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}} className="AppError appErrorProfil">
                    {errorInput.tagLine}
                  </span>
                </div>

                {/* <div className="profileinput-box">
                  <p
                    className={`${
                      userAccount.oldPassword === ""
                        ? ""
                        : "move-label-up-profile"
                    }`}
                  >
                    Old Password
                  </p>
                  <input
                    type="password"
                    name="oldPassword"
                    onChange={handleChange}
                    value={userAccount.oldPassword}
                  />
                  <span
                    style={
                      errorInput.oldPassword === "Looks Good!"
                        ? { color: "green", marginTop: "-8px" }
                        : {}
                    }
                    className="AppError appErrorProfil"
                  >
                    {errorInput.oldPassword}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p
                    className={`${
                      userAccount.currentPassword === ""
                        ? ""
                        : "move-label-up-profile"
                    }`}
                  >
                    Current Password
                  </p>
                  <input
                    type="password"
                    name="currentPassword"
                    onChange={handleChange}
                    value={userAccount.currentPassword}
                  />
                  <span
                    style={
                      errorInput.currentPassword === "Looks Good!"
                        ? { color: "green", marginTop: "-8px" }
                        : {}
                    }
                    className="AppError appErrorProfil"
                  >
                    {errorInput.currentPassword}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p
                    className={`${
                      userAccount.repeatCurrentPassword === ""
                        ? ""
                        : "move-label-up-profile"
                    }`}
                  >
                    Repeat Current Password
                  </p>
                  <input
                    type="password"
                    name="repeatCurrentPassword"
                    onChange={handleChange}
                    value={userAccount.repeatCurrentPassword}
                  />
                  <span
                    style={
                      errorInput.repeatCurrentPassword === "Looks Good!"
                        ? { color: "green", marginTop: "-8px" }
                        : {}
                    }
                    className="AppError appErrorProfil"
                  >
                    {errorInput.repeatCurrentPassword}
                  </span>
                </div> */}

                {/* <div className="profileinput-box">
                  <p
                    className={`${
                      userAccount.mobile === "" ? "" : "move-label-up-profile"
                    }`}
                  >
                    Mobile Number
                  </p>
                  <input
                    type="text"
                    name="mobile"
                    onChange={handleChange}
                    value={userAccount.mobile}
                  />
                  <span
                    style={
                      errorInput.mobile === "Looks Good!"
                        ? { color: "green", marginTop: "-8px" }
                        : {}
                    }
                    className="AppError appErrorProfil"
                  >
                    {errorInput.mobile}
                  </span>
                </div> */}

                <div className="social-handle-profile">
                  <div className="socila-m-profile-inner">
                    <img src={ig} alt="" />
                    <input type="text" name="ig" onChange={handleChange} value={userAccount.ig} />
                  </div>

                  <div className="socila-m-profile-inner">
                    <img src={fb} alt="" />
                    <input type="text" name="fb" onChange={handleChange} value={userAccount.fb} />
                  </div>

                  <div className="socila-m-profile-inner">
                    <img src={twit} alt="" />
                    <input type="text" name="twit" onChange={handleChange} value={userAccount.twit} />
                  </div>

                  <div className="socila-m-profile-inner">
                    <img src={youtube} alt="" />
                    <input type="text" name="youtube" onChange={handleChange} value={userAccount.youtube} />
                  </div>
                </div>
              </div>
              <div className="user-profile-card-two-inner-two"></div>
              <div className="user-profile-card-two-inner-three">
                <h1 className="userProfile-input-header">Personal data</h1>
                <div className="profileinput-box">
                  <p  style={{paddingBottom: "20px"}} className={`${userAccount.firstName === '' ? '' : 'move-label-up-profile'}`}>First Name</p>
                  <input type="text" name="firstName" onChange={handleChange} value={userAccount.firstName} />
                  <span
                    style={errorInput.firstName === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}}
                    className="AppError appErrorProfil"
                  >
                    {errorInput.firstName}
                  </span>
                </div>
                <div className="profileinput-box">
                  <p  style={{paddingBottom: "20px"}} className={`${userAccount.lastName === '' ? '' : 'move-label-up-profile'}`}>Last Name</p>
                  <input type="text" name="lastName" onChange={handleChange} value={userAccount.lastName} />
                  <span
                    style={errorInput.lastName === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}}
                    className="AppError appErrorProfil"
                  >
                    {errorInput.lastName}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p   style={{paddingBottom: "20px"}} className={`${userAccount.location === '' ? '' : 'move-label-up-profile'}`}>Phone number</p>
                  <PhoneInput
                    defaultCountry="NG"
                    // placeholder="Enter phone number"
                    value={userNumber}
                    onChange={setuserNumber}
                  />
                </div>

                <div style={{ marginTop: '10px', marginBottom: '17px' }} className="profileinput-box">
                  <Select options={options} value={userCountry} onChange={changeHandler} placeholder="Select country" />
                  <span
                    style={errorInput.location === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}}
                    className="AppError appErrorProfil"
                  >
                    {errorInput.location}
                  </span>
                </div>

                <div className="profileinput-box">
                  <p  style={{paddingBottom: "20px"}} className={`${userAccount.jobPosition === '' ? '' : 'move-label-up-profile'}`}>Job Position</p>
                  <input type="text" name="jobPosition" onChange={handleChange} value={userAccount.jobPosition} />
                  <span
                    style={errorInput.jobPosition === 'Looks Good!' ? { color: 'green', marginTop: '-8px' } : {}}
                    className="AppError appErrorProfil"
                  >
                    {errorInput.jobPosition}
                  </span>
                </div>

                <div className="profile-newsletter-box">
                  <div className="profile-newsletter-box-inner">
                    <span>I wish to receive newsletters, promotions and news from TheNewspaperStand </span>
                    <input type="checkbox" />
                  </div>

                  <div className="profile-newsletter-box-inner">
                    <span>Notify me when someone I follow uploads new publication </span>
                    <input type="checkbox" />
                  </div>
                </div>
                <button onClick={updateUserProfile} className="editProfilebtn">
                  Save Changes
                </button>

                <button
                  style={{
                    marginLeft: '10px',
                    background: 'none',
                    border: '1px solid orange',
                    color: 'orange',
                  }}
                  onClick={OpenChangePasswordModalModal}
                  className="editProfilebtn"
                >
                  Change Login Password
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal open={EditProfilePicModalOpen} onClose={setOpenEditProfilePicModalModal}>
          <div className="inner-modal-wrap">
            <div className="avatarProfileImageCrop">
              {' '}
              <Avatar width={400} height={295} onCrop={onCrop} onClose={onClose} onBeforeFileLoad={onBeforeFileLoad} src={imgSrc} />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <img src={preview} alt="Preview" />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <button style={{ marginRight: '25px' }} onClick={UpdateUserAvatar} className="editProfilebtn">
                Save Changes
              </button>
            </div>
          </div>
        </Modal>

        <Modal open={ChangePasswordModalOpen} onClose={setOpenChangePasswordModalModal}>
          <div className="inner-modal-wrap">
            <form className="changeUserPassword">
              <div className="updateUserPaswordTitle">
                <h1>Update Password</h1>
              </div>
              <div className="changePassinputWrap">
                <label>Old Password</label>
                <input name="oldPassword" onChange={handleUserPasswordInputChange} value={userPassword.oldPassword} type="password" placeholder="" />
              </div>

              <div className="changePassinputWrap">
                <label>New Password</label>
                <input name="newPassword" onChange={handleUserPasswordInputChange} value={userPassword.newPassword} type="password" placeholder="" />
              </div>

              <div className="changePassinputWrap">
                <label>Confirm New Password</label>
                <input
                  name="confirmNewPassword"
                  onChange={handleUserPasswordInputChange}
                  value={userPassword.confirmNewPassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </form>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <button style={{ marginRight: '25px' }} onClick={resetPassword} className="editProfilebtn">
                Save Changes
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
