import React, { useContext, useEffect } from "react";
import "./cards.css";
import DateFormater from "../helpers/dateFormater";
import { CartContextData } from "../../context/newsPaperContext";
import Truncate from "../../components/helpers/truncate";
import { PaymentContext } from "../../context/paymentContext";
import { httpPost } from "../helpers/httpMethods";
import dayjs from "dayjs";

export default function NewspaperCards({
  header,
  viewPaper,
  Newspapers,
  userIsLogin,
}) {
  const { onOpenModal, followPublications, mySubs } =
    useContext(CartContextData);
  const { toggleFreePdfModal } = useContext(PaymentContext);

  useEffect(() => {}, []);

  const isPastMonthOrGreater = (dateToCheck) => {
    const date1 = dayjs();

    const isPastMonthOrGreater = date1.diff(dateToCheck, "month") > 1;

    return isPastMonthOrGreater;
  };

  const calculateTotalComment = (editions) => {
    const total = editions.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.comment?.length;
    }, 0);

    return total;
  };

  const addPublicationViewCount = async (id) => {
    let res = await httpPost(`publication/view_count/`, { id });

    console.log(res);
  };

  const viewPublication = (id) => {
    viewPaper.push(`/view_paper/${id}`);
    addPublicationViewCount(id);
  };

  return (
    <React.Fragment>
      <div className="news-wrap-home">
        <h1 className="edition-type-home">{header}</h1>
        <div className={`TNSNewsPaperCover`}>
          {Newspapers
            ? Newspapers.map((data) => {
                if (data.editions.length) {
                  return (
                    <div key={data.id} className="newspaperWrapBody">
                      <div style={{ position: "relative", overflow: "hidden" }}>
                        <div className="wraopNewpaperIMg">
                          {!data.isPaid ? (
                            <button
                              onClick={() =>
                                toggleFreePdfModal("newspaper", data)
                              }
                              className="free-button"
                            >
                              Free
                            </button>
                          ) : null}
                          {isPastMonthOrGreater(
                            data.editions[0].publishedDate
                          ) ? (
                            <button className="inactive-button">
                              Inactive
                            </button>
                          ) : null}
                          <div className="paperImgNew">
                            <img
                              src={
                                data.editions.length
                                  ? data.editions[0].image
                                  : ""
                              }
                              alt=""
                            />
                          </div>
                        </div>

                        {/* DISABLE OVERLAY AND SUBSCRIBE BUTTON IF PUBLICATION IS INACTIVE  */}
                        {!isPastMonthOrGreater(
                          data.editions[0].publishedDate
                        ) ? (
                          <div className="paperImgHover">
                            <div className="subscribeToPublicationBtn">
                              {data.name !== "PUNCH Newspaper" && (
                                <button
                                  disabled={
                                    data.name === "PUNCH Newspaper"
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    userIsLogin === true
                                      ? mySubs.includes(data.id)
                                        ? viewPublication(data.id)
                                        : data.isPaid
                                        ? onOpenModal(
                                            data.editions[0],
                                            "homeSub"
                                          )
                                        : toggleFreePdfModal("newspaper", data)
                                      : viewPaper.push("/login")
                                  }
                                >
                                  {mySubs.includes(data.id)
                                    ? "Read"
                                    : "Subscribe"}
                                </button>
                              )}

                              <div className="followEditionIcon">
                                <i
                                  onClick={
                                    userIsLogin === true
                                      ? () => followPublications(data.id)
                                      : () => viewPaper.push("/login")
                                  }
                                  className="fas fa-user-plus"
                                ></i>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="TNSAboutMagazine">
                          <div className="TNSAboutMagazine-name">
                            <p onClick={() => viewPublication(data.id)}>
                              {Truncate(data.name, 30)}
                            </p>
                            <p>
                              {DateFormater(
                                data.editions.length
                                  ? data.editions[0].publishedDate
                                  : ""
                              )}
                            </p>
                          </div>

                          <div className="TNSAboutMagazine-socialEngagement">
                            <i className="fas fa-comments"></i>
                            <span>{calculateTotalComment(data?.editions)}</span>
                            <i className="fas fa-thumbs-up"></i>
                            <span>
                              {data.editions.length
                                ? data.editions[0].like.length
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
}
