import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { UserDataContext } from "../../context/userContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  httpPostFormData,
  httpPut,
  httpPatch,
  httpGet,
  httpPost,
  httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import NotFound from "../../components/helpers/notFound";

import "./index.css";
export default function Subscription({ history }) {
  const { setLoginModal } = useContext(UserDataContext);

  useEffect(() => {
    followPublications();
    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);
  const [myFollows, setMyFollows] = useState([]);
  const followPublications = async () => {
    showLoader();
    let res = await httpGet(`my_following`);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message || res.er);
        if (res.er === "Unauthorized, Your token is invalid or expired") {
          setLoginModal();
        }
        setloadData(false);
        return;
      }
      hideLoader();
      setloadData(false);
      setMyFollows(res.data.following);
    }
  };
  const [loadData, setloadData] = useState(true);
  const [Showloading, setLoading] = useState(false);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }} className="section-wrap">
          {loadData === true ? (
            <div className="row h-100 justify-content-center align-items-center app-spinner">
              <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
            </div>
          ) : (
            <div>
              {myFollows.length === 0 ? (
                <NotFound
                  header="You are not following any publication yet"
                  body="Explore our resources and follow the ones you like the most so you
                don’t lose sight of them."
                />
              ) : (
                <div className="following-wrap">
                  {myFollows
                    ? myFollows.map((data) => {
                        return (
                          <div className="following-cards">
                            <div className="following-img-wrap">
                              <img
                                src={data.publication.editions[0].image}
                                alt=""
                              />
                            </div>
                            <div
                              style={{
                                width: "70%",
                                marginLeft: "24px",
                                marginBottom: "6px",
                              }}
                              className="hr-sub"
                            ></div>
                            <div className="following-uname">
                              {data.publication.name}
                            </div>
                            <div
                              style={{ marginTop: "7px" }}
                              className="fol-fol"
                            >
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {data.publication.follow.length}
                              </span>{" "}
                              Followers
                            </div>
                            <div className="fol-fol">
                              <span
                                style={{ fontWeight: "600", color: "black" }}
                              >
                                {data.publication.editions.length}
                              </span>{" "}
                              Publications
                            </div>
                            <div
                              onClick={() =>
                                history.push(
                                  `/view_paper/${data.publicationId}`
                                )
                              }
                              className="following-profile"
                            >
                              View Publication
                            </div>
                          </div>
                        );
                      })
                    : "loading.."}
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
