import React from "react";
import { createContext } from "react";

export const DisabledItemsContext = createContext();

const DisabledItemsProvider = ({ children }) => {
  return (
    <DisabledItemsContext.Provider
      value={{
        showPublicNotice: false,
        showRequest: false,
        showFollowing: false,
        showReview: false,
        showActiveSubscription: false,
      }}
    >
      {children}
    </DisabledItemsContext.Provider>
  );
};

export default DisabledItemsProvider;
