import React, { useEffect, useState, ref } from "react";
import "./textview.css";
import sampleImg from "./images/sample1.png";
import _ from "lodash";
var decode = require("decode-html");
const TextView = ({ activeArticle, scrTtOPRef, articles }) => {
  const [ckTags, SetCktags] = useState("");

  useEffect(() => {
    // if (activeArticle != null) {
    //   SetCktags(null);
    //   let replaceStr = activeArticle.body.replace("&nbsp;", "<p></p>");
    //   let replaceStrBR = replaceStr.replace("<br />", "");
    //   SetCktags(replaceStrBR);
    //   console.log(activeArticle);
    // }
  }, []);

  const mapArticles = () => {
    return articles.map((data, index) => {
      console.log(data);
      let replaceStr = data.body.replace("&nbsp;", "<p></p>");
      let replaceStrBR = replaceStr.replace("<br />", "");
      return (
        <div
          style={
            activeArticle == index ? { display: "block" } : { display: "none" }
          }
          className="first-text-flow"
        >
          <div>
            <div className="postTitleST">
              <h1>{data?.title}</h1>
            </div>
            <div className="textViewContainer-grid">
              <div className="tviewgrid1">
                <p className="text-size-calculator">
                  {data.byLine == "" ||
                  data.byLine == null ||
                  data.byLine == "NULL" ? (
                    ""
                  ) : (
                    <p className="post-des">{data.byLine}</p>
                  )}

                  {data?.author == null ||
                  data?.author == "" ||
                  data?.author == "NULL" ||
                  data.author == "null null" ? (
                    ""
                  ) : (
                    <p className="post-autor">
                      BY:{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "black",
                          textTransform: "uppercase",
                          fontSize: "13px",
                          letterSpacing: "0.75px",
                        }}
                      >
                        {data?.author}
                      </span>
                    </p>
                  )}

                  <div className="postTags">
                    {data?.tag == null ? (
                      ""
                    ) : (
                      <span className="tagsT" style={{ color: "orange" }}>
                        TAGS{" "}
                      </span>
                    )}
                    {data?.tag == null
                      ? ""
                      : data?.tag?.map((x) => {
                          return (
                            <span>
                              <span className="allUsedTags">{x}</span>
                            </span>
                          );
                        })}

                    {/* <span className="totalTagCount" style={{ color: "orange" }}>
                +6 More {}
              </span> */}
                  </div>
                  <div
                    className="textViewCoverImage"
                    style={
                      data?.image == "null" || data?.image == null
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <img src={data?.image} />
                  </div>
                  <div className="sk-editor-render-style">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replaceStrBR,
                      }}
                    ></div>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div ref={scrTtOPRef} className="textViewWithImage">
      {mapArticles()}
      {/* 
      <div className="textWithImage-remainder">{mapOutAutoColumSection()}</div> */}
    </div>
  );
};

export default TextView;
