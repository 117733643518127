import React from "react";
import SubscriptionCard from "./SubscriptionCard";

const ExpiredSubscriptions = ({ data }) => {
  return (
    <div className="mt-40">
      <h1 className="subscription-header">Expired ({data.length})</h1>
      <div className="mt-20">
        {data.map((subscription, i) => (
          <SubscriptionCard key={i} data={subscription} showRead={false} />
        ))}
      </div>
    </div>
  );
};

export default ExpiredSubscriptions;
