import React, { useEffect, useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { toggleSidebar } from "../../globalState/localData";
import AppLogo from "../../assets/images/appLogoV2.png";
import bookmarkheart from "../../assets/bx_bx-bookmark-heart.png";
import addUser from "../../assets/addUser.png";
import atm1 from "../../assets/atm1.png";
import walletImg from "../../assets/wallet.png";
import cartImg from "../../assets/cartBasket.png";
import starReview from "../../assets/starReview.png";
import devices from "../../assets/devices.png";
import support from "../../assets/support.png";
import logout from "../../assets/logout.png";
import dropYellow from "../../assets/dropYellow.png";
import userIcon from "../../assets/userIcon.png";
import { Link } from "react-router-dom";
import { getLocalItem } from "../../components/helpers/authService";
import { CartContextData } from "../../context/newsPaperContext";
import { UserDataContext } from "../../context/userContext";
import { PaymentContext } from "../../context/paymentContext";
import MaleAvatar from "../../assets/male.png";
import femaleAvatar from "../../assets/female.png";
import n1 from "../../assets/n1.png";
import n2 from "../../assets/n2.png";
import tn3 from "../../assets/tn3.png";
import settingsic from "../../assets/set.png";
import SearchDropDown from "./searchDropDown";
import { httpGet } from "../helpers/httpMethods";
import { DisabledItemsContext } from "../../context/DisabledItemsContext";
export default function Navbar({ browserRouter, routeType, currentRoute }) {
  const {
    CartCount,
    ShowAddToCartModal,
    onShowAddToCartModalModal,
    setfilterType,
    filterType,
  } = useContext(CartContextData);
  const { OpenWalletModal } = useContext(PaymentContext);
  const { UserInfo, LogUserOut, userLocation } = useContext(UserDataContext);

  // FOR NONE WORKING ITEMS AND ITEMS TO DISABLE
  const {
    showPublicNotice,
    showRequest,
    showFollowing,
    showReview,
    showActiveSubscription,
  } = useContext(DisabledItemsContext);

  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);

  console.log(filterType);
  let [LoginUser, setLoginUser] = useState(false);
  let [navDrop, setnavDrop] = useState(false);
  let [userData, setUserData] = useState();
  const [UserProfilePage, setUserProfilePage] = useState("Profile");
  const IsUserValidated = () => {
    const lastUsedToken = localStorage.getItem("token");
    if (
      lastUsedToken === null ||
      lastUsedToken === undefined ||
      lastUsedToken === ""
    ) {
      setLoginUser(false);
    } else {
      setLoginUser(true);
      let res = JSON.parse(getLocalItem("user"));
      setUserData(res);
    }
  };
  let [useUserContextData, setUseUserContext] = useState(null);

  useEffect(() => {
    checkUserProfilePageRoute();
    IsUserValidated();

    if (UserInfo != null) {
      setUseUserContext(UserInfo);
    }
  }, [UserInfo]);

  const checkUserProfilePageRoute = () => {
    if (currentRoute === "/bookmarks") {
      setUserProfilePage("bookmarks");
    }

    if (currentRoute === "/following") {
      setUserProfilePage("following");
    }

    if (currentRoute === "/reviews") {
      setUserProfilePage("reviews");
    }

    if (currentRoute === "/devices") {
      setUserProfilePage("Devices");
    }

    if (currentRoute === "/subscription") {
      setUserProfilePage("Subscription");
    }

    if (currentRoute === "/sub_accounts") {
      setUserProfilePage("sub_accounts");
    }
    if (currentRoute === "/transations") {
      setUserProfilePage("transations");
    }
  };
  const [searchText, setSearchText] = useState("");
  const [searchPublicationResult, setSearchPublicationResult] = useState([]);
  const searchPublications = ({ target: { value } }) => {
    setSearchText(value);
    searchPublicationAsync(value);
  };
  const searchPublicationAsync = async (value) => {
    const res = await httpGet(`search_publication?name=${value}`);
    if (res.code == 200) {
      setSearchPublicationResult(res.data.publications);
    } else {
      setSearchPublicationResult(undefined);
    }
  };
  return (
    <React.Fragment>
      <div className="navbar-position">
        <div className="navbar-wrap">
          <div className="navbar-pad">
            <div className="app-logo-wrap-layout-page">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => browserRouter("/")}
                src={AppLogo}
                alt=""
              />

              <span className="nav_search_app hidenavbrsearch">
                <select id="">
                  <option value="all">All</option>
                </select>

                <input
                  type="text"
                  onChange={searchPublications}
                  placeholder="Search by publication name"
                  style={{ fontSize: "13px" }}
                />
                <button>
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </span>
            </div>

            <div className="nav-actions">
              <ul>
                {LoginUser === false ? (
                  <React.Fragment>
                    <li className="navbar-login-access ">
                      {" "}
                      <a
                        style={{
                          color: "#002333",
                          marginRight: "20px",
                          border: "none",
                          paddingRight: "30px",
                          fontWeight: "400",
                          fontSize: "14px !important",
                        }}
                        href="https://vendor.thenewspaperstand.com/register"
                        target="blank"
                        className="hdPoNmobile"
                      >
                        Publish your content
                      </a>
                      <a>
                        <Link
                          style={{
                            color: "#002333",
                            padding: "0",
                            margin: "0",
                            paddingRight: "3px",
                          }}
                          to="/login"
                        >
                          Login
                        </Link>
                        /
                        <Link
                          style={{
                            color: "#002333",
                            padding: "0",
                            margin: "0",
                            paddingLeft: "3px",
                          }}
                          to="/create_account"
                        >
                          SignUp
                        </Link>
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {LoginUser === true ? (
                  <React.Fragment>
                    <li
                      onClick={OpenWalletModal}
                      className="nav_wallet_li show-nav-border hidemycartAndWalllet"
                    >
                      <img src={walletImg} alt="" />
                      <span style={{ marginTop: "1px" }}>
                        Wallet Balance <br />{" "}
                        <span className="gjfu6ghs2d">
                          {userLocation === "Nigeria" ? "NGN" : "USD"}{" "}
                          {UserInfo ? UserInfo?.wallet?.amount : "0"}
                        </span>{" "}
                      </span>
                    </li>
                    <li
                      onClick={onShowAddToCartModalModal}
                      className="nav_wallet_li show-nav-border hidemycartAndWalllet"
                    >
                      <img src={cartImg} alt="" />
                      <span
                        style={{
                          color: "orange",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        <span
                          style={{ fontSize: "15px", marginLeft: "-6px" }}
                          className="gjfu6ghs2d"
                        >
                          {CartCount}
                        </span>
                      </span>
                    </li>
                    <li className="nav-user-icon ">
                      <span
                        style={{ whiteSpace: "nowrap" }}
                      >{`${userData?.lastName} ${userData?.firstName}`}</span>
                      {useUserContextData === null ? (
                        <img src={femaleAvatar} alt="" />
                      ) : (
                        <img
                          onClick={() => setnavDrop(!navDrop)}
                          src={
                            useUserContextData?.avatar === null
                              ? MaleAvatar
                              : useUserContextData?.avatar
                          }
                          alt=""
                        />
                      )}

                      <span>
                        <img
                          onClick={() => setnavDrop(!navDrop)}
                          style={
                            navDrop
                              ? {
                                  width: "13px",
                                  height: "10px",
                                  borderRadius: "0",
                                  marginRight: "7px",
                                  transform: "rotate(178deg)",
                                  transition: "all 400ms ease",
                                  marginLeft: "5px",
                                }
                              : {
                                  width: "13px",
                                  height: "10px",
                                  borderRadius: "0",
                                  marginRight: "7px",
                                  transition: "all 400ms ease",
                                  marginLeft: "5px",
                                }
                          }
                          src={dropYellow}
                          alt=""
                          className="remjegdwy"
                        />
                      </span>
                    </li>{" "}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        {LoginUser === true ? (
          <React.Fragment>
            {navDrop === true ? (
              <div className="profile-dropdown-nav">
                <div className="nav-user-drop67hn">
                  {useUserContextData === null ? (
                    <img src={femaleAvatar} alt="" />
                  ) : (
                    <img
                      src={
                        useUserContextData.avatar === null
                          ? MaleAvatar
                          : useUserContextData.avatar
                      }
                      alt=""
                    />
                  )}

                  <span>
                    <span className="account-ausername">{`${userData.lastName} ${userData.firstName}`}</span>
                    <span
                      style={{ fontSize: "7.5px" }}
                      className="account-auseremail"
                    >
                      {userData.email}
                    </span>
                    <Link to={`/user_profile`}>
                      <button className="account-btn-acc">Edit Profile</button>
                    </Link>
                  </span>
                </div>{" "}
                <div className="hr-global"></div>
                <div className="navbar-dropdownlinks">
                  <span
                    className="hideOnDesk"
                    onClick={onShowAddToCartModalModal}
                  >
                    <img
                      style={{
                        width: "32px",
                        height: "26px",
                        marginLeft: "-10px",
                      }}
                      src={cartImg}
                      alt=""
                    />{" "}
                    My Cart
                  </span>
                  <span onClick={OpenWalletModal} className="hideOnDesk">
                    <img
                      style={{
                        height: "20px",
                      }}
                      src={walletImg}
                      alt=""
                    />{" "}
                    My Wallet
                  </span>
                  {/* <span>
                    <img src={bookmarkheart} alt="" /> BOOKMARKS
                  </span> */}
                  {showFollowing ? (
                    <span onClick={() => browserRouter("/following")}>
                      <img src={addUser} alt="" /> FOLLOWING
                    </span>
                  ) : null}
                  {showReview ? (
                    <span onClick={() => browserRouter("/reviews")}>
                      <img src={starReview} alt="" /> REVIEWS
                    </span>
                  ) : null}

                  <span onClick={() => browserRouter("/sub_accounts")}>
                    <img
                      style={{ height: "30px", marginRight: "3px" }}
                      src={tn3}
                      alt=""
                    />{" "}
                    BUY FOR ME
                  </span>
                </div>
                <div className="hr-global"></div>
                <div className="navbar-dropdownlinks">
                  {showActiveSubscription ? (
                    <span onClick={() => browserRouter("/subscription")}>
                      <img src={n1} alt="" />
                      ACTIVE SUBSCRIPTION
                    </span>
                  ) : null}

                  <span onClick={() => browserRouter("/user/subscription")}>
                    <img src={atm1} alt="" /> MY SUBSCRIPTION
                  </span>

                  <span onClick={() => browserRouter("/transations")}>
                    <img src={n2} alt="" /> TRANSACTION
                  </span>

                  {/* <span onClick={() => browserRouter("#")}>
                    <img src={support} alt="" /> SUPPORT
                  </span>
                  <span>
                    <img src={devices} alt="" /> MY DEVICES
                  </span> */}
                </div>
                {showRequest ? (
                  <>
                    <div className="hr-global"></div>
                    <div className="navbar-dropdownlinks">
                      <span onClick={() => browserRouter("/applications")}>
                        <img src={settingsic} alt="" /> Requests
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="hr-global"></div>
                <div className="navbar-dropdownlinks">
                  <span onClick={LogUserOut}>
                    <img src={logout} alt="" /> LOGOUT
                  </span>
                </div>
                <div className="hr-global"></div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      {routeType === "pdf" ? null : (
        <div>
          {routeType === "Withprofile" ? (
            <div id="app-router-home">
              <span
                className="app-router-homeharmburger"
                onClick={() =>
                  setSidebarState({
                    ...sidebarState,
                    openSidebar: !sidebarState.openSidebar,
                    defaultSidebarApiState: "AppCloseSidebar",
                  })
                }
              >
                {" "}
                <i className="fa fa-bars" aria-hidden="true"></i>
                Filters
              </span>

              <div className="center-routers">
                <Link
                  onClick={() => {
                    setUserProfilePage("Profile");
                  }}
                  to={`/user_profile`}
                >
                  <span
                    onClick={() => {
                      setUserProfilePage("Profile");
                    }}
                    className={`hjsgt ${
                      UserProfilePage === "Profile"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    <img src={userIcon} alt="" />
                    Profile
                  </span>
                </Link>
                {/* <Link
                  onClick={() => {
                    setUserProfilePage("bookmarks");
                  }}
                  to={`/bookmarks`}
                >
                  <span
                    onClick={() => {
                      setUserProfilePage("bookmarks");
                    }}
                    className={`${
                      UserProfilePage === "bookmarks"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    Bookmarks
                  </span>
                </Link> */}
                {showFollowing ? (
                  <Link
                    onClick={() => {
                      setUserProfilePage("following");
                    }}
                    className={` hjsgt ${
                      UserProfilePage === "following"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                    to={`/following`}
                  >
                    <span
                      onClick={() => {
                        setUserProfilePage("following");
                      }}
                      className={`hjsgt ${
                        UserProfilePage === "following"
                          ? "activeNews"
                          : "inactiveNews"
                      }`}
                    >
                      <img src={addUser} alt="" />
                      Followings
                    </span>
                  </Link>
                ) : null}

                {showReview ? (
                  <Link to={`/reviews`}>
                    <span
                      onClick={() => {
                        setUserProfilePage("reviews");
                      }}
                      className={` hjsgt ${
                        UserProfilePage === "reviews"
                          ? "activeNews"
                          : "inactiveNews"
                      }`}
                    >
                      <img src={starReview} alt="" />
                      Reviews
                    </span>
                  </Link>
                ) : null}

                {/* <Link to={`/devices`}>
                  <span
                    onClick={() => {
                      setUserProfilePage("Devices");
                    }}
                    className={`${
                      UserProfilePage === "Devices"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    Devices
                  </span>
                </Link> */}

                <Link to={`/user/subscription`}>
                  <span
                    onClick={() => {
                      setUserProfilePage("Subscription");
                    }}
                    className={`hjsgt ${
                      UserProfilePage === "Subscription"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    {" "}
                    <img src={atm1} alt="" />
                    Subscription
                  </span>
                </Link>

                <Link to={`/transations`}>
                  <span
                    onClick={() => {
                      setUserProfilePage("transations");
                    }}
                    className={`hjsgt ${
                      UserProfilePage === "transations"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    {" "}
                    <img src={n2} alt="" />
                    Transactions
                  </span>
                </Link>
                <Link to={`/sub_accounts`}>
                  <span
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setUserProfilePage("sub_accounts");
                    }}
                    className={`${
                      UserProfilePage === "sub_accounts"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                  >
                    <img
                      style={{ height: "30px", marginRight: "3px" }}
                      src={tn3}
                      alt=""
                    />
                    BUY FOR ME
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            <div id="app-router-home">
              <span
                className="app-router-homeharmburger"
                onClick={() =>
                  setSidebarState({
                    ...sidebarState,
                    openSidebar: !sidebarState.openSidebar,
                    defaultSidebarApiState: "AppCloseSidebar",
                  })
                }
              >
                {" "}
                <i className="fa fa-bars" aria-hidden="true"></i>
                <span className="hideFilterText">Filters</span>
              </span>

              <div className="center-routers">
                <span
                  onClick={
                    currentRoute == "/changeofname"
                      ? () => {
                          browserRouter("/");
                          setfilterType("all");
                        }
                      : () => {
                          browserRouter("/");
                          setfilterType("all");
                        }
                  }
                  className={`${
                    filterType === "all" ? "activeNews" : "inactiveNews"
                  }`}
                >
                  All
                </span>
                <span
                  onClick={
                    currentRoute == "/changeofname"
                      ? () => {
                          browserRouter("/");
                          setfilterType("Newspaper");
                        }
                      : () => {
                          browserRouter("/");
                          setfilterType("Newspaper");
                        }
                  }
                  className={`${
                    filterType === "Newspaper" ? "activeNews" : "inactiveNews"
                  }`}
                >
                  Newspapers
                </span>
                <span
                  onClick={
                    currentRoute == "/changeofname"
                      ? () => {
                          browserRouter("/");
                          setfilterType("Magazines");
                        }
                      : () => {
                          browserRouter("/");
                          setfilterType("Magazines");
                        }
                  }
                  className={`${
                    filterType === "Magazines" ? "activeNews" : "inactiveNews"
                  }`}
                >
                  Magazines
                </span>
                <span
                  onClick={() => {
                    setfilterType("Books");
                  }}
                  className={`${
                    filterType === "Books" ? "activeNews" : "inactiveNews"
                  }`}
                >
                  Books
                </span>

                <span
                  onClick={() => {
                    setfilterType("change_of_name_tab1");
                    browserRouter("/changeofname");
                  }}
                  className={`${
                    filterType === "change_of_name_tab1" ||
                    filterType === "change_of_name"
                      ? "activeNews"
                      : "inactiveNews"
                  }`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Change Of Name
                </span>

                {showPublicNotice ? (
                  <span
                    onClick={() => {
                      setfilterType("ublic_notice");
                      browserRouter("/public_notice");
                    }}
                    className={`${
                      filterType === "ublic_notice" ||
                      filterType === "ublic_notice"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Public Notice
                  </span>
                ) : null}
                {LoginUser === true ? (
                  <span
                    onClick={() => {
                      setfilterType("achive");
                      browserRouter("/archive");
                    }}
                    className={`${
                      filterType === "achive" || filterType === "achive"
                        ? "activeNews"
                        : "inactiveNews"
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Archive
                  </span>
                ) : null}
                <span
                  onClick={() => {
                    setfilterType("redeem-card");
                    browserRouter("/coupon/redeem-card");
                  }}
                  className={`${
                    filterType === "redeem-card" || filterType === "redeem-card"
                      ? "activeNews"
                      : "inactiveNews"
                  }`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Redeem Card
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <ShowAddToCartModal />
      {searchText === "" ? (
        ""
      ) : (
        <div className="serachDropDownS" style={{}}>
          <SearchDropDown
            searchPublicationResult={searchPublicationResult}
            browserRouter={browserRouter}
            searchText={searchText}
          />
        </div>
      )}
    </React.Fragment>
  );
}
