import React, { useState, useEffect } from "react";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
} from "../../components/helpers/validateInput";
import { NotificationManager } from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import swal from "sweetalert";
export default function ForgotPassword(props) {
  useEffect(() => {}, []);
  const [userAccount, setUserAccount] = useState({
    email: "",
  });

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    } else {
      setValidated(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errorInput.email === "Looks Good!") {
      showLoader();
      const data = {
        email: userAccount.email,
      };
      let res = await httpPost(`auth/user/forgot_password`, data);

      if (res) {
        if (res.er) {
          NotificationManager.error(res.er.message);
          return;
        } else {
          swal(
            "Success",
            "An email with reset password link has been sent to your mail."
          );
        }

        hideLoader();
      }
    }

    hideLoader();
  };

  return (
    <div className="login-wrap-home">
      <div className="login-wrap-home-input">
        <h1 className="login-header-text">Password Reset</h1>
        <p style={{ textAlign: "center" }}>
          Enter your email, and we’ll send you instructions on
          <br /> how to reset your password.
        </p>
        <form className="login-form-wrap">
          <div className="login-col-three-home">
            <div className="inPutHomeWrapP">
              <input
                name="email"
                onChange={handleChange}
                placeholder="Enter email address"
                type="text"
                value={userAccount.email}
              />
              <span
                style={
                  errorInput.email === "Looks Good!"
                    ? { color: "green", position: "relative", top: "-17px" }
                    : { position: "relative", top: "-17px" }
                }
                className="AppError"
              >
                {errorInput.email}
              </span>
            </div>
          </div>
        </form>
        <div className="submit-login-details-home">
          <button onClick={handleSubmit}>Reset Password</button>
        </div>
        <div className="forgot-password-wrap-home">
          <Link to="/forgot_password">Can’t log in?</Link>
          <Link to="/create_account">Don’t have an account?</Link>
        </div>
      </div>
    </div>
  );
}
