import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
// import demo1 from "../../assets/demopic1.png";
import TNSLogo from "./logo.png";
import validateImage from "../../components/helpers/validateImage";
import { useParams } from "react-router-dom";
import { httpGet, httpPost } from "../../components/helpers/httpMethods";
import swal from "sweetalert";
import { UserDataContext } from "../../context/userContext";
import { PaymentContext } from "../../context/paymentContext";
import { hideLoader } from "../../components/helpers/loader";
import { showLoader } from "./../../components/helpers/loader";
import Switch from "react-switch";

export default function FormerName(props) {
  const { UserInfo, userLocation, getUserProfile, userIsLogin } =
    useContext(UserDataContext);
  const {
    payWithMonnifyChangeOfName,
    CONpayWithWallet,
    CONshowPaymentOptionsFun,
    setCONdataInitPayment,
    changeOfNameSuccess,
    SetchangeOfNameSuccess,
    CONinitPayment,
  } = useContext(PaymentContext);
  const [stage, setStage] = useState("catgory");
  const [category, setCategory] = useState("");
  const [showDOB, setShowDOB] = useState(false);
  const [formalDate, setFormalDate] = useState("");
  const [newDate, setNewDate] = useState("");

  const params = useParams();
  useEffect(() => {
    console.log(">>user", UserInfo);
    document.body.style.backgroundColor = "#f4f4f418";
    showChangeOfNameVendors();
    if (changeOfNameSuccess === true) {
      rediretOnSuccessFulPayment();
    }
    return () => {
      // SetchangeOfNameSuccess(false);
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, [changeOfNameSuccess]);

  const rediretOnSuccessFulPayment = () => {
    if (changeOfNameSuccess === true) {
      SetchangeOfNameSuccess(false);
      setTimeout(() => {
        window.location.href = "/change_of_name_applications";
      }, 2000);
    }
  };

  const [vendor, setVendor] = useState([]);
  const showChangeOfNameVendors = async () => {
    const res = await httpGet(`con/vendor_info/${params.id}`);
    if (res.code === 200) {
      setVendor(res.data.conSubscriptions);
      setLoading(false);
    }
  };

  const handleChangeShowDOB = (checked) => {
    setShowDOB(checked);
  };

  const [tab, setTab] = useState("tab1");
  const [checbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reasonForChangeOfName, setReasonForChangeOfName] = useState("");
  const [showModalBookingSummart1, setshowModalBookingSummart1] =
    useState(false);

  const [showModalBookingSummart2, setshowModalBookingSummart2] =
    useState(false);
  const setOpenshowModalBookingSummart1Modal = () => {
    setshowModalBookingSummart1(!showModalBookingSummart1);
  };

  const setOpenshowModalBookingSummart2Modal = () => {
    setshowModalBookingSummart2(!showModalBookingSummart2);
  };

  const [changeDetails, setChangeDetails] = useState({
    changeOfFormerTitle: "",
    changeOfFormerLastname: "",
    changeOfFormerFirstname: "",
    changeOfFormerMiddlename: "",
    changeOfNewTitle: "",
    changeOfNewLastname: "",
    changeOfNewFirstname: "",
    changeOfNewMiddlename: "",
    phone: "",
    email: "",
    affidavit: "",
    reason: "",
    proveOfReason: "",
    reasonDescription: "",
    publicationId: "",
  });

  const HandleMakePayment = async () => {
    showLoader();
    const {
      changeOfFormerTitle,
      changeOfFormerLastname,
      changeOfFormerFirstname,
      changeOfFormerMiddlename,
      changeOfNewTitle,
      changeOfNewLastname,
      changeOfNewFirstname,
      changeOfNewMiddlename,
      affidavit,
      proveOfReason,
      reasonDescription,
      publicationId,
    } = changeDetails;

    const Data = new FormData();
    Data.append("formerTitle", changeOfFormerTitle);
    Data.append("reason", reasonForChangeOfName);
    Data.append("formerLastname", changeOfFormerLastname);
    Data.append("formerFirstname", changeOfFormerFirstname);
    Data.append("formerMiddlename", changeOfFormerMiddlename);
    Data.append("newTitle", changeOfNewTitle);
    Data.append("newLastname", changeOfNewLastname);
    Data.append("newFirstname", changeOfNewFirstname);
    Data.append("newMiddlename", changeOfNewMiddlename);
    Data.append("affidavit", affidavit);
    Data.append("reasonDescription", reasonDescription);
    Data.append("publicationId", params.id);
    Data.append("email", UserInfo.email);
    Data.append("phone", `${UserInfo.countryCode}${UserInfo.phoneNumber}`);
    Data.append("proveOfReason", proveOfReason);

    const res = await httpPost(`con/apply`, Data);
    if (res.code === 201) {
      hideLoader();
      // swal("tt", "ep", "success");
      initChangeOfNamePayment(res.data.conApplication);
      console.log(res);
    }
    hideLoader();
  };
  const initChangeOfNamePayment = async ({ publicationId, id }) => {
    showLoader();
    const { wallet } = UserInfo;
    let type = "CARD";
    const Data = {
      publicationId,
      id,
      currency: UserInfo?.wallet?.currency ? UserInfo?.wallet?.currency : "NGN",
      amount: `${Number(vendor.price) + Number(vendor.serviceCharge)}`,
      type,
    };
    // setCONdataInitPayment(Data);

    if (
      Number(wallet?.amount) >=
      Number(vendor.price) + Number(vendor.serviceCharge)
    ) {
      setOpenshowModalBookingSummart2Modal();
      setOpenshowModalBookingSummart1Modal();
      return CONpayWithWallet(Data);
    } else {
      CONinitPayment(Data);
    }
  };

  const validateTwo = () => {
    if (
      changeDetails.changeOfFormerTitle === "" ||
      changeDetails.changeOfFormerFirstname === "" ||
      changeDetails.changeOfFormerLastname === ""
    ) {
      swal({
        title: "Validation Error!",
        text: "*All marked fields (**) are mandatory*",
        icon: "error",
      });
    } else {
      setTab("tab3");
    }
  };

  const validateThree = () => {
    if (
      changeDetails.changeOfNewTitle === "" ||
      changeDetails.changeOfNewLastname === "" ||
      changeDetails.changeOfNewFirstname === ""
    ) {
      swal({
        title: "Validation Error!",
        text: "*All marked fields (**) are mandatory*",
        icon: "error",
      });
    } else {
      setOpenshowModalBookingSummart1Modal();
    }
  };

  const validateOne = () => {
    // if (changeDetails.affidavit === "") {
    //   return swal({
    //     title: "Validation Error!",
    //     text: "Affidavit is required!",
    //     icon: "error",
    //   });
    // }

    if (reasonForChangeOfName === "") {
      return swal({
        title: "Validation Error!",
        text: "Select your reason for Change Of Name!",
        icon: "error",
      });
    }
    if (
      changeDetails.reason === "Others" &&
      changeDetails.reasonDescription === ""
    ) {
      return swal({
        title: "Validation Error!",
        text: "Please Input your reason for change of name!",
        icon: "error",
      });
    }
    if (changeDetails.proveOfReason === "" && changeDetails.affidavit === "") {
      return swal({
        title: "Validation Error!",
        text: "A Valid prove image document is required!",
        icon: "error",
      });
    } else {
      // setOpenshowModalBookingSummart1Modal();
      setTab("tab2");
    }
  };

  const confirmTermsApprove = () => {
    if (checbox === false) {
      return swal({
        title: "Validation Error!",
        text: "Please accept, and confirm that all information provided are correct by clicking the check box below!",
        icon: "error",
      });
    } else {
      setOpenshowModalBookingSummart2Modal();
    }
  };
  // const handleSubmit = async () => {
  // if(){
  //   swal({
  //     title: "Validation Error!",
  //     text:
  //       "DO NOT: screengrab, flip, print, share or circulate any content from this site as it will attract stiff penalties for defaulters.!",
  //     icon: "error",

  //   });
  // }
  // };

  const nextToForms = () => {
    if (!category) {
      return swal({
        title: "Validation Error!",
        text: "Please Select a Catgory",
        icon: "error",
      });
    }
    setStage("forms");
  };

  return loading ? (
    <div></div>
  ) : (
    <div>
      {stage === "catgory" ? (
        <div className="changeOfNameTabWrap">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
            className="cangeONameHeasderRes"
          >
            <p
              style={{
                fontSize: "14px",
                marginBottom: "15px",
                fontWeight: 400,
                color: "#6E7191",
              }}
            >
              You have chosen to publish your name with{" "}
              <span style={{ color: "#EE7F0D", fontWeight: 700 }}>
                {vendor.publication.name}
              </span>
            </p>
            <div
              style={{
                border: "1px dashed rgba(0, 0, 0, 0.5)",
                width: "70%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            ></div>

            <p className="tab_label">Choose a Category</p>
            <div className="inputnwCh">
              <select
                name=""
                id=""
                className="mwhgeqinput"
                style={{ margin: "0px auto", maxWidth: "295px", width: "100%" }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">--Category--</option>
                <option value="Change of Name">Change of Name</option>
                <option value="Change of Name/Age Declaration">
                  Change of Name/Age Declaration
                </option>
                <option value="Confirmation of Name">
                  Confirmation of Name
                </option>
                <option value="Rearrangement of Name">
                  Rearrangement of Name
                </option>
              </select>
            </div>

            <div className="actionBtnCName">
              <button onClick={() => props.history.goBack()}>Back</button>
              <button onClick={nextToForms}>Next</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="changeOfNameTabWrap">
          <div className="changeOfNameTabWrapIn">
            <ul>
              <li>
                <span
                  className="ftrsjgfrlosaqpbt"
                  style={
                    tab === "tab1" || tab === "tab2" || tab === "tab3"
                      ? { background: "#EE7F0D" }
                      : {}
                  }
                >
                  <span>1</span>
                </span>
                <span
                  className="trsjgfrlosa"
                  style={
                    tab === "tab1" || tab === "tab2" || tab === "tab3"
                      ? { color: "#EE7F0D" }
                      : {}
                  }
                >
                  <span>Reason for Change</span>
                  <span>{">"}</span>
                </span>
              </li>
              <li>
                <span
                  className="ftrsjgfrlosaqpbt"
                  style={
                    tab === "tab2" || tab === "tab3"
                      ? { background: "#EE7F0D" }
                      : {}
                  }
                >
                  <span>2</span>
                </span>
                <span
                  className="trsjgfrlosa"
                  style={
                    tab === "tab2" || tab === "tab3" ? { color: "#EE7F0D" } : {}
                  }
                >
                  <span>Old Name</span>
                  <span>{">"}</span>
                </span>
              </li>

              <li>
                <span
                  className="ftrsjgfrlosaqpbt"
                  style={tab === "tab3" ? { background: "#EE7F0D" } : {}}
                >
                  <span>3</span>
                </span>
                <span
                  className="trsjgfrlosa"
                  style={tab === "tab3" ? { color: "#EE7F0D" } : {}}
                >
                  <span>New Name</span>
                </span>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
            className="cangeONameHeasderRes"
          >
            {tab === "tab1" ? (
              <p className="tab_label">Reason for {category}</p>
            ) : (
              ""
            )}
            {tab === "tab2" ? (
              <p className="tab_label">Enter Your Old Names</p>
            ) : (
              ""
            )}
            {tab === "tab3" ? (
              <p className="tab_label">Enter Your New Names</p>
            ) : (
              ""
            )}

            <p
              style={{
                fontSize: "14px",
                marginBottom: "15px",
                fontWeight: 400,
                color: "#6E7191",
              }}
            >
              You have chosen to publish your name with{" "}
              <span style={{ color: "#EE7F0D", fontWeight: 700 }}>
                {vendor.publication.name}
              </span>
            </p>

            <div
              style={{ border: "1px dashed rgba(0, 0, 0, 0.5)", width: "70%" }}
            ></div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "15px",
              }}
            >
              <img src="/images//InfoCircle.svg" alt="icon" />
              {tab === "tab1" ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 300,
                    color: "#999999",
                    margin: 0,
                  }}
                >
                  Tell us your new name or name that you will like to change to
                </p>
              ) : null}
              {tab === "tab2" ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 300,
                    color: "#999999",
                    margin: 0,
                  }}
                >
                  Submit your old name that you will like to change
                </p>
              ) : null}
              {tab === "tab3" ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 300,
                    color: "#999999",
                    margin: 0,
                  }}
                >
                  Tell us your new name or name that you will like to change to
                </p>
              ) : null}
            </div>
          </div>
          {tab === "tab1" ? (
            <div
              className="inputChangeNameBoxtab3"
              style={{ position: "relative", marginTop: "25px" }}
            >
              {/* <div className="inputnwChtab3">
                <p className="inputnwChtab3label">
                  Sworn affidavit * {changeDetails?.affidavit?.name}
                </p>
                <div className="inputnwChtab3FakeFileI"></div>

                <input
                  type="file"
                  className="mwhgeqinputTab3"
                  onChange={({ target }) => {
                    setChangeDetails({
                      ...changeDetails,
                      affidavit: "",
                    });
                    let imgData = validateImage(target.files);
                    if (
                      imgData === "Invalid picture format" ||
                      imgData === "File size exceeds 5MB"
                    ) {
                      return swal("Error", `${imgData}`, "error");
                    } else {
                      setChangeDetails({
                        ...changeDetails,
                        affidavit: validateImage(target.files),
                      });
                    }
                  }}
                />
                <button className="inputnwChtab3Button">
                  Upload File
                  <img
                    src="/images/upload-icon.svg"
                    alt="icon"
                    style={{ marginLeft: "10px" }}
                  />
                </button>
              </div>
              <p
                style={{ fontSize: "12px", fontWeight: 300, color: "#999999" }}
              >
                File size must not exceed 200kb
              </p> */}
              <div className="inputChangeNameBox">
                <div className="inputnwCh">
                  <select
                    name=""
                    id=""
                    className="mwhgeqinput"
                    style={{ width: "100%", height: "40px" }}
                    onChange={(e) => {
                      setChangeDetails({
                        ...changeDetails,
                        proveOfReason: "",
                      });
                      setReasonForChangeOfName(e.target.value);
                    }}
                  >
                    <option value="">--select reason--</option>
                    <option value="Marriage">Marriage</option>
                    <option value="Faith">Faith</option>
                    <option value="sworn_affidavit">Sworn affidavit</option>
                  </select>
                </div>
                {reasonForChangeOfName === "Faith" ? (
                  <div
                    className="inputChangeNameBoxtab3"
                    style={{ position: "relative", marginTop: "15px" }}
                  >
                    <div className="inputnwChtab3">
                      <p className="inputnwChtab3label">
                        Faith certificate *{changeDetails.proveOfReason.name}
                      </p>
                      <div className="inputnwChtab3FakeFileI"></div>
                      <input
                        type="file"
                        className="mwhgeqinputTab3"
                        onChange={({ target }) => {
                          setChangeDetails({
                            ...changeDetails,

                            proveOfReason: "",
                          });
                          let imgData = validateImage(target.files);
                          if (
                            imgData === "Invalid picture format" ||
                            imgData === "File size exceeds 5MB"
                          ) {
                            return swal("Error", `${imgData}`, "error");
                          } else {
                            setChangeDetails({
                              ...changeDetails,
                              proveOfReason: validateImage(target.files),
                              // affidavit: validateImage(target.files),
                            });
                          }
                        }}
                      />
                      <button className="inputnwChtab3Button">
                        Upload File
                        <img
                          src="/images/upload-icon.svg"
                          alt="icon"
                          style={{ marginLeft: "10px" }}
                        />
                      </button>
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 300,
                        color: "#999999",
                      }}
                    >
                      File size must not exceed 200kb
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {reasonForChangeOfName === "Marriage" ? (
                  <div
                    className="inputChangeNameBoxtab3"
                    style={{ position: "relative", marginTop: "15px" }}
                  >
                    <div className="inputnwChtab3">
                      <p className="inputnwChtab3label">
                        Marriage certificate *{changeDetails.proveOfReason.name}
                      </p>
                      <div className="inputnwChtab3FakeFileI"></div>
                      <input
                        type="file"
                        className="mwhgeqinputTab3"
                        onChange={({ target }) => {
                          setChangeDetails({
                            ...changeDetails,
                            proveOfReason: "",
                          });
                          let imgData = validateImage(target.files);
                          if (
                            imgData === "Invalid picture format" ||
                            imgData === "File size exceeds 5MB"
                          ) {
                            return swal("Error", `${imgData}`, "error");
                          } else {
                            setChangeDetails({
                              ...changeDetails,
                              proveOfReason: validateImage(target.files),
                            });
                          }
                        }}
                        // value={changeDetails.proveOfReason}
                      />
                      <button className="inputnwChtab3Button">
                        Upload File
                        <img
                          src="/images/upload-icon.svg"
                          alt="icon"
                          style={{ marginLeft: "10px" }}
                        />
                      </button>
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 300,
                        color: "#999999",
                      }}
                    >
                      File size must not exceed 200kb
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {reasonForChangeOfName === "sworn_affidavit" ? (
                  <div>
                    <div
                      className="inputChangeNameBoxtab3"
                      style={{ position: "relative", marginTop: "15px" }}
                    >
                      <div className="inputnwChtab3">
                        <p className="inputnwChtab3label">
                          Sworn affidavit* {changeDetails.affidavit.name}
                        </p>
                        <div className="inputnwChtab3FakeFileI"></div>
                        <input
                          type="file"
                          className="mwhgeqinputTab3"
                          onChange={({ target }) => {
                            setChangeDetails({
                              ...changeDetails,
                              affidavit: "",
                            });
                            let imgData = validateImage(target.files);
                            if (
                              imgData === "Invalid picture format" ||
                              imgData === "File size exceeds 5MB"
                            ) {
                              return swal("Error", `${imgData}`, "error");
                            } else {
                              setChangeDetails({
                                ...changeDetails,
                                affidavit: validateImage(target.files),
                              });
                            }
                          }}
                          // value={changeDetails.proveOfReason}
                        />
                        <button className="inputnwChtab3Button">
                          Upload File
                          <img
                            src="/images/upload-icon.svg"
                            alt="icon"
                            style={{ marginLeft: "10px" }}
                          />
                        </button>
                      </div>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 300,
                          color: "#999999",
                        }}
                      >
                        File size must not exceed 200kb
                      </p>
                    </div>
                    <div
                      className="inputChangeNameBoxtab3"
                      style={{ position: "relative", marginTop: "15px" }}
                    >
                      <textarea
                        name=""
                        id=""
                        className=""
                        placeholder="Explain your reason here  *"
                        onChange={({ target }) =>
                          setChangeDetails({
                            ...changeDetails,
                            reasonDescription: target.value,
                          })
                        }
                        value={changeDetails.reasonDescription}
                      ></textarea>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="actionBtnCName">
                <button onClick={() => setStage("catgory")}>Back</button>
                <button onClick={validateOne}>Next</button>
              </div>
            </div>
          ) : (
            ""
          )}
          {tab === "tab2" ? (
            <div className="inputChangeNameBox">
              <div className="inputnwCh">
                <select
                  name=""
                  id=""
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfFormerTitle: target.value,
                    })
                  }
                  value={changeDetails.changeOfFormerTitle}
                >
                  <option value="">Old Title *</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                </select>
                <input
                  type="text"
                  placeholder="Old Surname  *"
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfFormerLastname: target.value,
                    })
                  }
                  value={changeDetails.changeOfFormerLastname}
                />
              </div>
              <div className="inputnwCh">
                <input
                  type="text"
                  placeholder="Old First Name  *"
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfFormerFirstname: target.value,
                    })
                  }
                  value={changeDetails.changeOfFormerFirstname}
                />
                <input
                  type="text"
                  placeholder="Old Middle Name  *"
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfFormerMiddlename: target.value,
                    })
                  }
                  value={changeDetails.changeOfFormerMiddlename}
                />
              </div>
              <div style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: "14px", fontWeight: 400 }}>
                    Add Date of Birth Optional
                  </p>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>No</p>
                    <Switch
                      onColor="#EE7F0D"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={handleChangeShowDOB}
                      checked={showDOB}
                    />
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>Yes</p>
                  </div>
                </div>
                {showDOB ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <input
                      type="text"
                      placeholder="Former Date of Birth  *"
                      className="mwhgeqinput"
                      style={{
                        width: "295px",
                        height: "45px",
                        borderRadius: "7px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                      }}
                      value={formalDate}
                      onChange={(e) => setFormalDate(e.target.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="actionBtnCName">
                <button onClick={() => setTab("tab1")}>Back</button>
                <button onClick={validateTwo}>Next</button>
              </div>
            </div>
          ) : (
            ""
          )}
          {tab === "tab3" ? (
            <div className="inputChangeNameBox">
              <div className="inputnwCh">
                <select
                  name=""
                  id=""
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfNewTitle: target.value,
                    })
                  }
                  value={changeDetails.changeOfNewTitle}
                >
                  <option value="">New Title *</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                </select>
                <input
                  type="text"
                  placeholder="New Surname  *"
                  className="mwhgeqinput"
                  // onChange={({ target }) =>
                  //   setChangeDetails({
                  //     ...changeDetails,
                  //     changeOfNewFirstname: target.value,
                  //   })
                  // }
                  onChange={(e) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfNewLastname: e.target.value,
                    })
                  }
                  value={changeDetails.changeOfNewLastname}
                />
              </div>
              <div className="inputnwCh">
                <input
                  type="text"
                  placeholder="New First Name  *"
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfNewFirstname: target.value,
                    })
                  }
                  value={changeDetails.changeOfNewFirstname}
                />
                <input
                  type="text"
                  placeholder="New Middle Name  *"
                  className="mwhgeqinput"
                  onChange={({ target }) =>
                    setChangeDetails({
                      ...changeDetails,
                      changeOfNewMiddlename: target.value,
                    })
                  }
                  value={changeDetails.changeOfNewMiddlename}
                />
              </div>
              <div style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: "14px", fontWeight: 400 }}>
                    Add Date of Birth Optional
                  </p>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>No</p>
                    <Switch
                      onColor="#EE7F0D"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={handleChangeShowDOB}
                      checked={showDOB}
                    />
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>Yes</p>
                  </div>
                </div>
                {showDOB ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <input
                      type="text"
                      placeholder="New Date of Birth  *"
                      className="mwhgeqinput"
                      style={{
                        width: "295px",
                        height: "45px",
                        borderRadius: "7px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                      }}
                      value={newDate}
                      onChange={(e) => setNewDate(e.target.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="actionBtnCName">
                <button onClick={() => setTab("tab2")}>Back</button>
                <button onClick={validateThree}>Next</button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      <Modal
        open={showModalBookingSummart1}
        onClose={setOpenshowModalBookingSummart1Modal}
      >
        <div className="inner-modal-wrap-trvChN">
          <div className="previewChangeOfname1Wrap">
            <h1
              style={{
                textAlign: "center",
                fontSize: "28px",
                fontWeight: 600,
                marginBottom: "0",
              }}
            >
              Booking Summary!
            </h1>
            <p
              style={{
                textAlign: "center",
                magin: "12px 0px 0px 0px",
                color: "#F80707",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Kindly preview and confirm your details submitted below.
            </p>

            <div className="changeOfNameNewNPreview section">
              <div className="changeOfNameNewNPreviewOne">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#000000",
                  }}
                >
                  Old Name
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#4E4B66",
                  }}
                >
                  {changeDetails?.changeOfFormerLastname}{" "}
                  {changeDetails?.changeOfFormerFirstname}{" "}
                  {changeDetails?.changeOfFormerMiddlename}
                </p>
              </div>
              <div className="changeOfNameNewNPreviewtwo">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#000000",
                  }}
                >
                  New Name
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#4E4B66",
                  }}
                >
                  {changeDetails?.changeOfNewLastname}{" "}
                  {changeDetails?.changeOfNewFirstname}{" "}
                  {changeDetails?.changeOfNewMiddlename}
                </p>
              </div>
            </div>
            <div className="cNLine"></div>
            <div className="changeOfNameNewNPreviewOne section">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                Reason
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#4E4B66",
                }}
              >
                {reasonForChangeOfName}
              </p>
            </div>
            <div className="cNLine"></div>
            <div className="changeOfNameNewNPreviewOne snDocumwn section">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                Uploaded Documents
              </p>
              <div className="dcCns">
                {changeDetails.affidavit === "" ? (
                  ""
                ) : (
                  <div>
                    <img
                      src={URL.createObjectURL(changeDetails?.affidavit)}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
                {changeDetails.proveOfReason === "" ? (
                  ""
                ) : (
                  <div>
                    <img
                      src={URL.createObjectURL(changeDetails.proveOfReason)}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="cNLine"></div>
            <div className="changeOfNameNewNPreviewOne section">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                Publisher
              </p>
              <div className="cnameAboutPub">
                <img
                  style={{ width: "130px", height: "130px" }}
                  src={vendor?.publication?.logo}
                  alt=""
                />
                <div>
                  <p style={{ fontSize: "12px", fontWeight: 400 }}>
                    Published on
                  </p>
                  <span className="pubDays3h">
                    {vendor?.publishDays.map((data) => {
                      return (
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#787878",
                          }}
                          className="pubDays3hCh1"
                        >
                          {data.slice(0, 3)}
                        </span>
                      );
                    })}
                  </span>

                  {/* <p style={{ fontSize: "11px", color: "#787878" }}>
                    Tuesdays | Wednesday
                  </p> */}
                </div>
              </div>
            </div>
            <div className="cNLine"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  background: "#EE7F0D",
                  width: "220px",
                  height: "70px",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "white",
                  paddingTop: "20px",
                  borderRadius: "4px",
                }}
              >
                {`₦${Number(vendor.price) + Number(vendor.serviceCharge)}`}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                gap: "10px",
              }}
            >
              <input
                onChange={(e) =>
                  e.target.checked ? setCheckbox(true) : setCheckbox(false)
                }
                value={checbox}
                type="checkbox"
                style={{ padding: "10px", outline: "none" }}
              />
              <div>
                <p className="confirm">
                  I confirm that the information provided is accurate and I will
                  be held responsible for any false information provided.
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <button
                style={{
                  height: "50px",
                  width: "180px",
                  backgroundColor: "#2BB509",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "13px",
                  marginBottom: "15px",
                  cursor: "pointer",
                }}
                onClick={confirmTermsApprove}
              >
                Make Payment
              </button>
              <p
                style={{
                  color: "black",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenshowModalBookingSummart1Modal()}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showModalBookingSummart2}
        onClose={setOpenshowModalBookingSummart2Modal}
      >
        <div
          className="inner-modal-wrap-trvChN"
          style={{ background: "white" }}
        >
          <div className="previewChangeOfname1Wrap">
            <div className="spLogosCN">
              <img src={TNSLogo} alt="" />
              <img
                style={{ width: "120px", height: "70px" }}
                src={vendor?.publication?.logo}
                alt=""
              />
            </div>
            <h1
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "0",
              }}
            >
              Booking Summary!
            </h1>
            <p
              style={{
                textAlign: "center",
                fontSize: "12.5px",
                magin: "12px 0px 0px 0px !important",
                color: "#F80707",
              }}
            >
              Kindly preview and confirm the details below
            </p>
            <div className="cnNoticeg">
              <h1>Change of Name Notice</h1>
              <p>
                I formerly{" "}
                <span>
                  {`${changeDetails?.changeOfFormerTitle} ${changeDetails?.changeOfFormerLastname} ${changeDetails?.changeOfFormerFirstname} ${changeDetails?.changeOfFormerMiddlename} `}
                </span>{" "}
                now wish to be known and addressed as{" "}
                <span>
                  {`${changeDetails?.changeOfNewTitle} ${changeDetails?.changeOfNewLastname} ${changeDetails?.changeOfNewFirstname} ${changeDetails?.changeOfNewMiddlename} `}
                </span>{" "}
                <br />
                General public take note.
              </p>
            </div>
            {/* <div style={{ marginTop: "25px" }} className="cNLine"></div>
            <div className="cnNoticeg">
              <h1>Contact Information</h1>
              <p style={{ color: "black", fontSize: "12px" }}>
                E-Mail Address: gbagbos@yahoo.com
                <br />
                Phone Number: 234080000000
              </p>
            </div> */}
            <div style={{ marginTop: "25px" }} className="cNLine"></div>
            <div className="cnNoticeg">
              <h1>Contact Information</h1>
              <p style={{ color: "#000000" }}>
                E-Mail Address: Info@thenewspaperstand.com
              </p>
              <p style={{ color: "#000000" }}> Phone Number: +2349117004643</p>
            </div>
            <div style={{ marginTop: "25px" }} className="cNLine"></div>
            <div className="cnNoticeg">
              <p style={{ color: "black", fontSize: "14px" }}>
                You have chosen to publish your name with{" "}
                <span style={{ fontWeight: 700 }}>
                  {vendor.publication.name}
                </span>
              </p>
            </div>
            <div className="cnNoticeg" style={{ margin: "0" }}>
              <p style={{ color: "#6E7191", fontSize: "12px" }}>
                {vendor.publication.name} publishes{" "}
                <span className="pubDays3h">
                  {vendor?.publishDays.map((data) => {
                    return (
                      <span className="pubDays3hCh1">{data.slice(0, 3)}</span>
                    );
                  })}
                </span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  background: "#EE7F0D",
                  width: "220px",
                  height: "70px",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "white",
                  paddingTop: "20px",
                  borderRadius: "4px",
                }}
              >
                {`₦${Number(vendor.price) + Number(vendor.serviceCharge)}`}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <button
                style={{
                  height: "50px",
                  width: "180px",
                  backgroundColor: "#2BB509",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "13px",
                  marginBottom: "15px",
                  cursor: "pointer",
                }}
                onClick={HandleMakePayment}
              >
                Make Payment
              </button>
              <p
                style={{
                  color: "black",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenshowModalBookingSummart2Modal()}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
