import React from "react";
import successIcon from "../../assets/check-circle-fill.svg";

function SuccessView({ onClose, message }) {
  return (
    <div className="redeem-box">
      <div
        style={{
          background: "#FFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            textAlign: "center",
            color: "#425466",
            fontWeight: 700,
          }}
        >
          {message.success ? "Congratulations!" : "Failed!"}
        </p>
        {message.success && <img src={successIcon} alt="icon" />}
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            color: "#4E4B66",
            lineHeight: "24px",
          }}
        >
          {message.msg}
        </p>

        <button
          onClick={onClose}
          style={{
            width: "266px",
            height: "45px",
            background: "#FFA03F",
            borderRadius: "7px",
            color: "#000",
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default SuccessView;
