import React from "react";
import slugfy from "../../components/helpers/slugFy";
export default function SearchDropDown({
  searchPublicationResult,
  browserRouter,
  searchText,
}) {
  return (
    <div className="searchMainDrop">
      <ul>
        {searchPublicationResult === undefined ||
        searchPublicationResult.length == 0 ? (
          <div>
            <p
              style={{
                color: "rgba(128, 128, 128, 0.507)",
                fontStyle: "italic",
                fontSize: "18px",
                marginTop: "20px",
              }}
            >
              {`No result found for `}
              <span style={{ color: "rgba(255, 0, 0, 0.555)" }}>
                {searchText}
              </span>
            </p>
          </div>
        ) : (
          searchPublicationResult.map(({ name, id }) => {
            return (
              <li onClick={() => browserRouter(`/publication/${name}`)}>
                <div className="imgCon"></div>
                {name.toLowerCase().trim().replace(/ /g, "-")}
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
}
