import React, { useState, useEffect } from "react";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
} from "../../components/helpers/validateInput";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { setLocalItem } from "../../components/helpers/authService";
import { AppLogo } from "../../assets/svg";
import Logo from "../../assets//images/appLogo.png";
import swal from "sweetalert";
import SEO from "../../components/Layout/SEO";
export default function Login(props) {
  const [userAccount, setUserAccount] = useState({
    email: "",
    password: "",
  });

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: "",
    password: "",
  });
  const checkUserLoginState = () => {
    const loginSt = localStorage.getItem("expiredToken");
    if (loginSt === "true") {
      return;
    }
  };
  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };
  const checkAcess = (value) => {
    const regexFormat = /^[[0-9+-]+$/;
    console.log(regexFormat.test(value));
    return regexFormat.test(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let t = checkAcess(userAccount.email);

    if (errorInput.email !== "Looks Good!") {
      return swal("Error", "Email or Mobile number is required", "error");
    }

    if (errorInput.password !== "Looks Good!") {
      return swal("Error", "Password is required", "error");
    }

    showLoader();

    const data =
      t === false
        ? {
            email: userAccount.email,
            password: userAccount.password,
          }
        : {
            phoneNumber: userAccount.email,
            password: userAccount.password,
          };
    if (t === false) {
    }
    const checkFrom = localStorage.getItem("comingFromPublicPublication");
    const rediretURL = localStorage.getItem("rediretURL");
    let res = await httpPost(`auth/user_login`, data);

    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);

        return;
      }
      setUserAccount({
        email: "",
        password: "",
      });
      // checkUserIsLogin();
      // GetUserInfoFromStorge();
      localStorage.setItem("token", res.data.token);
      console.log(res.data);
      setLocalItem("user", res.data.user);
      localStorage.setItem("expiredToken", "false");
      NotificationManager.success(`Welcome Back ${res.data.user.firstName}.`);
      if (checkFrom === "true") {
        localStorage.setItem("comingFromPublicPublication", "false");
        props.history.push(`/publication/${rediretURL}`);
      } else {
        // if (res.data.user.phoneNumber === null) {
        //   localStorage.setItem("setPhoneNumber", "true");
        //   props.history.push("/");
        // } else {
        //   props.history.push("/");
        // }
        props.history.push("/");
      }

      hideLoader();
    }

    hideLoader();
  };
  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  useEffect(() => {
    checkUserLoginState();
  }, []);
  return (
    <>
      <SEO
        title="TheNewsPaperStand (TNS) | Login to Access Digital Newspapers & Magazines"
        description="Login to TheNewsPaperStand (TNS) to access a wide array of digital replicas of Nigerian newspapers and magazines. Stay updated with the latest news, ePapers, and eMagazines from Nigeria's print media. Experience our online newsstand today!"
        url="https://app.thenewspaperstand.com/login"
      />
      <div className="login-wrap-v2">
        <div className="login-wrap-v2-col1">
          <AppLogo />

          {/* <div className="heroTextSection">
          <h1>
            I see you’re the first <br /> time here.
          </h1>
          <p>
            Maybe some text here will help me see it better. <br />
            Oh God. Oke, let’s do it then.{" "}
          </p>
          <button>Create Account</button>
        </div> */}
        </div>

        <div className="login-wrap-v2-col2">
          <div className="signUpOptonLogin">
            <p>
              Don’t have an account?{" "}
              <span>
                {" "}
                <Link to="/create_account">Sign up</Link>
              </span>
            </p>
          </div>

          <div className="mobileViewLogoV2">
            <img src={Logo} alt="" />
          </div>

          <div className="login-wrapv2-main">
            <h1 className="login-txt-header">Login</h1>
            <form>
              <div className="inputWrap-v2">
                <label>Email Address/Mobile Number</label>
                <input
                  type="text"
                  placeholder="Enter email or phone number"
                  name="email"
                  onChange={handleChange}
                  value={userAccount.email}
                  required
                />
              </div>

              <div className="inputWrap-v2">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Enter password"
                  value={userAccount.password}
                  required
                />
              </div>

              <div className="forgotPasswordOptionv2">
                <p>
                  {" "}
                  <Link to="/forgot_password">forgot password?</Link>
                </p>
              </div>

              <div className="loginBtnV2">
                <button onClick={handleSubmit}>Sign In</button>
              </div>

              <div className="haveanAccountV2Op">
                <p>
                  {" "}
                  New to TNS?
                  <Link to="/create_account"> SIGN UP</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
