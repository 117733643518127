import React, { useContext } from "react";
import Layout from "../Layout/index.jsx";
import { Route } from "react-router-dom";
import { PaymentContext } from "../../context/paymentContext.jsx";

const DefaultLayout = ({ children, routeType, ...rest }) => {
  const { CompletePayment } = useContext(PaymentContext);
  let browserRouter = children.props.history.push;
  let currentRoute = children.props.location.pathname;

  return (
    <Layout
      routeType={routeType}
      currentRoute={currentRoute}
      browserRouter={browserRouter}
    >
      {children}
      <CompletePayment />
    </Layout>
  );
};

const DefaultLayoutRoute = ({ component: Component, routeType, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <DefaultLayout routeType={routeType} page={rest.page}>
            <Component {...matchProps} />
          </DefaultLayout>
        );
      }}
    />
  );
};
export default DefaultLayoutRoute;
