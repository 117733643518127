// import React from 'react'
// import moment from 'moment'
// export default function dateFormater(date) {
//   let formatedDate = `${moment(`${date}`).format('Do MMM, yyyy')} at ${moment(`${date}`).format('LT')}
//   `
//   return (
//     formatedDate
//   )
// }

import React from 'react'
import moment from 'moment'
export default function dateFormater(date) {
  let formatedDate = `${moment(`${date}`).format('Do MMM, yyyy')} 
  `
  return (
    formatedDate
  )
}

