import { useContext, useState } from "react";
import { httpPost } from "../../components/helpers/httpMethods";
import { UserDataContext } from "../../context/userContext";

function useRedeemCard() {
  const { UserInfo, GetUserInfoFromStorge } = useContext(UserDataContext);

  const redeem = async (code) => {
    const res = await httpPost("coupon/redeem_coupon", {
      code,
      walletId: UserInfo?.wallet?.id,
    });
    if (res.status === 200) {
      GetUserInfoFromStorge();
      return res;
    }
  };

  return {
    redeem,
  };
}
export default useRedeemCard;
