import React, { useState } from "react";
import icons from "../../components/tables/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "../../components/helpers/dateFormater";

export default function TransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        className="appTab"
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          showTitle: false,
        }}
        columns={[
          { title: "Name", field: "oldName" },
          { title: "Publication Name", field: "newName" },
          { title: "Publication date", field: "reason" },
          { title: "Status", field: "status" },
          { title: "Date Applied", field: "DateApplied" },
          //   { title: "Full new name", field: "newName" },
        ]}
        data={props.applications?.map((data) => {
          return {
            oldName: `${data?.formerTitle} ${data?.formerLastname} ${data?.formerFirstname} ${data?.formerMiddlename}`,
            newName: `${data?.newTitle} ${data?.newLastname} ${data?.newFirstname} ${data?.newMiddlename}`,
            reason: `${data?.reason}`,
            status: `${data?.status}`,
            DateApplied: `${dateFormater(data?.createdAt)}`,
          };
        })}
      />
    </div>
  );
}
