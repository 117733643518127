import React from "react";
import { Route, Switch } from "react-router-dom";
import DefaultLayoutRoute from "./components/DefaultLayout/DefaultLayoutRoute";
import { NotificationContainer } from "react-notifications";
import "react-datepicker/dist/react-datepicker.css";
import HomePage from "./pages/landingPage/home";
import ViewPaper from "./components/newpaperCrads/viewSinglePaper";
// import Login from './pages/Access/login.jsx';
import CreateAccount from "./pages/Access/createAccount.jsx";
import ForgotPassword from "./pages/Access/forgotPassword.jsx";
import VerifyAccount from "./pages/Access/verifyAccount.jsx";
import { CartContextProvider } from "./context/newsPaperContext";
import { UserDataProvider } from "./context/userContext";
import { SocketDataProvider } from "./context/socketContext";
import { PaymentProvider } from "./context/paymentContext";
import { ContextWrapStateProvider } from "./context/contextHandler";

import ShowCart from "./components/carts/cart";
import Profile from "./pages/userProfile/user";
import Review from "./pages/userProfile/reviews";
import Subscription from "./pages/userProfile/subscriptions";
import Follow from "./pages/userProfile/following";
import BookMarks from "./pages/userProfile/bookmarks";
import SubAccount from "./pages/userProfile/subAccount";
import Devices from "./pages/userProfile/devices";
import ViewPdf from "./pdfViewer/view";
import PaidPublications from "./components/pdfViewer/pdfViewer";
import Subscrptions from "./pages/subscriptions/mySubs";
import PublicPublicationAccess from "./components/newpaperCrads/publicEditions";
import InputNewPassword from "./pages/Access/newPassword";
import Transations from "./pages/transations/userTransation";
import DisableInspect from "./components/helpers/disableInspect";
import ChangeOfName from "./pages/changeOfName/index";
import ChangeOfNameForm from "./pages/changeOfName/formerName";
import PublicNotice from "./pages/changeOfName/publicNotice";
import ChangeOfNameApplicaion from "./pages/changeOfName/applications";
import Applications from "./pages/changeOfName/allApplications";
import LoginV2 from "./pages/Access/loginv2";
import CreateAccountV2 from "./pages/Access/createAccountV2";
import Achive from "./pages/archive/index";
import CancelSub from "./cancelSub";
import DisabledItemsProvider from "./context/DisabledItemsContext";
import RedeemCard from "./pages/Coupon/RedeemCard.jsx";

function App(props) {
  const live =
    process.env.REACT_APP_BASE_URL == "https://api.thenewspaperstand.com"
      ? true
      : false;

  if (live) {
    if (window.innerWidth > 800) {
      DisableInspect();
    }

    console.log = function () {};
  }

  return (
    <React.Fragment>
      <NotificationContainer />
      <Switch>
        <Route exact path="/cancel_subscription" component={CancelSub} />
        <Route exact path="/login" component={LoginV2} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset_password/:id" component={InputNewPassword} />
        <Route exact path="/create_account" component={CreateAccountV2} />
        <Route exact path="/create_account" component={CreateAccount} />

        <Route
          exact
          path="/verify_user_account/:id"
          component={VerifyAccount}
        />
        <ContextWrapStateProvider>
          <DisabledItemsProvider>
            <UserDataProvider>
              <PaymentProvider>
                <SocketDataProvider>
                  <CartContextProvider>
                    <DefaultLayoutRoute exact path="/" component={HomePage} />
                    <DefaultLayoutRoute
                      exact
                      path="/applications"
                      component={Applications}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/changeofname"
                      component={ChangeOfName}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/change_of_name_tab1/:id"
                      component={ChangeOfNameForm}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/public_notice"
                      component={PublicNotice}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/home"
                      component={HomePage}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/view_paper/:id"
                      component={ViewPaper}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/carts"
                      component={ShowCart}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/user_profile"
                      component={Profile}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/reviews"
                      component={Review}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/user/subscription"
                      component={Subscription}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/following"
                      component={Follow}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/bookmarks"
                      component={BookMarks}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/sub_accounts"
                      component={SubAccount}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/devices"
                      component={Devices}
                    />
                    <DefaultLayoutRoute
                      exact
                      path="/view_publication/:id"
                      component={PaidPublications}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="pdf"
                      path="/view_single_paper/:id"
                      component={ViewPdf}
                    />
                    {/* <DefaultLayoutRoute
                      exact
                      routeType="pdf"
                      path="/subscription"
                      component={Subscrptions}
                    /> */}
                    <DefaultLayoutRoute
                      exact
                      routeType="pdf"
                      path="/publication/:id"
                      component={PublicPublicationAccess}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType="Withprofile"
                      path="/transations"
                      component={Transations}
                    />

                    <DefaultLayoutRoute
                      exact
                      routeType=""
                      path="/archive"
                      component={Achive}
                    />
                    <DefaultLayoutRoute
                      exact
                      routeType=""
                      path="/coupon/redeem-card"
                      component={RedeemCard}
                    />
                  </CartContextProvider>
                </SocketDataProvider>
              </PaymentProvider>
            </UserDataProvider>
          </DisabledItemsProvider>
        </ContextWrapStateProvider>
      </Switch>
    </React.Fragment>
  );
}

export default App;
