import React, { useState, useEffect, useContext } from "react";
import "./achive.css";
import Plus2 from "../../assets/plus2.png";
import successIcon from "../../assets/check-circle-fill.svg";
import { useHistory } from "react-router-dom";
import { httpGet, httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { UserDataContext } from "../../context/userContext";
import { PaymentContext } from "../../context/paymentContext";
import Datepicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { NotificationManager } from "react-notifications";
// import { indexOf } from "lodash";
const Index = () => {
  const { UserInfo, userLocation, getUserProfile, userIsLogin } =
    useContext(UserDataContext);
  const {
    payWithMonnifyChangeOfName,
    AchivepayWithWallet,
    CONshowPaymentOptionsFun,
    setCONdataInitPayment,
    changeOfNameSuccess,
    SetchangeOfNameSuccess,
    AchiveinitPayment,
  } = useContext(PaymentContext);
  const history = useHistory();

  const [inputCount, setInputCount] = useState(0);
  let [amount, setamount] = useState(0);
  const [price, setPrice] = useState(0);
  let [basicAmount, setBasicAmount] = useState(0);
  const [Vendors, setVendors] = useState([]);
  const [selectedDates, setSelectedDates] = useState([new Date()]);
  const [dateInputes, setDateInputes] = useState([{}]);
  const [publicationData, setPublicationData] = useState(null);
  const [Vendor, setVendorDetails] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [publications, setPublications] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState({});
  const [archiveType, setArchiveType] = useState("newspapers");
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedPublicationImg, setSelectedPublicationImg] = useState("");

  useEffect(() => {
    getVendors();
    getAllPublications();
  }, []);

  useEffect(() => {
    const selectedData = publications.find(
      (item) => item?.name === selectedPublication
    );
    setSelectedPublicationImg(selectedData);
  }, [selectedPublication]);

  const addmoreInputes = () => {
    if (inputCount >= 5) {
      return;
    }
    console.log(dateInputes.length);
    setDateInputes([...dateInputes, {}]);
    setInputCount(inputCount + 1);
    let l = dateInputes.length + 1;
    setamount(Number(basicAmount) * l);
  };

  const getVendors = async () => {
    let res = await httpGet(`archive/vendors`);
    if (res) {
      if (res.code == 200) {
        setVendors(res.data.archiveSubscriptions);
      }
    }
  };

  const getAllPublications = async () => {
    let res = await httpGet(`publications`);
    if (res) {
      if (res.code === 200) {
        setPublications(res.data.publications);
      }
    }
  };

  const handleDateInput = (date, index) => {
    let data1 = [...selectedDates];
    data1[index] = date;
    setSelectedDates(data1);
    setDateChanged(true);
    console.log(">>>>d", selectedDates);
  };

  const handleRemoveDateInput = (index) => {
    if (index == 0) {
      return;
    }
    // let data1 = [...selectedDates];
    // data1[index] = date;
    // setSelectedDates(data1);
    // console.log(">>>>d", selectedDates);
    let filterData = dateInputes.filter((data, Findex) => {
      // console.log(Findex);
      return Findex != index;
    });

    console.log(filterData);
    setDateInputes(filterData);
  };

  const applyAchive = async () => {
    if (publicationData == null) {
      return NotificationManager.error(
        "You need to select a vendor to continue"
      );
    }
    if (dateChanged == false) {
      return NotificationManager.error(
        "You need to select an active date to continue"
      );
    }

    console.log(amount);
    let rDates = selectedDates.map((data) => {
      return { date: data };
    });
    let data = {
      requestDates: JSON.stringify(rDates),
      publicationId: publicationData,
    };

    let res = await httpPost(`archive/apply`, data);
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      console.log(res.data.archiveApplication);
      initChangeOfNamePayment(res.data.archiveApplication);
    }
  };

  const requestForArchive = async () => {
    if (!selectedPublication) {
      return NotificationManager.error("Select a publication");
    }

    showLoader();
    let res = await httpPost(`archive/requst_for_archive`, {
      email: UserInfo.email,
      name: `${UserInfo.firstName} ${UserInfo.lastName}`,
      publicationName: selectedPublication,
      date: selectedDates,
    });
    if (res) {
      hideLoader();
      if (res.status === 200) {
        setShowSuccess(true);
        return NotificationManager.success(`${res.message}`);
      }
    }
  };

  const getVendorAchiveDetails = async (id) => {
    showLoader();
    let res = await httpGet(`archive/vendor_info/${id}`);
    if (res) {
      hideLoader();
      console.log(res.data.archiveSubscriptions);
      if (res.code == 200) {
        setVendorDetails(res.data.archiveSubscriptions);
        setamount(res.data.archiveSubscriptions.serviceCharge);
        setBasicAmount(res.data.archiveSubscriptions.serviceCharge);
        setPrice(res.data.archiveSubscriptions.price);
      }
    }
  };

  const initChangeOfNamePayment = async ({ publicationId, id }) => {
    showLoader();
    const { wallet } = UserInfo;
    let type = "CARD";
    const Data = {
      publicationId,
      id,
      currency: UserInfo?.wallet?.currency ? UserInfo?.wallet?.currency : "NGN",
      amount,
      type,
    };
    // setCONdataInitPayment(Data);

    if (Number(wallet?.amount) >= Number(amount)) {
      // setOpenshowModalBookingSummart2Modal();
      // setOpenshowModalBookingSummart1Modal();
      return AchivepayWithWallet(Data);
    } else {
      AchiveinitPayment(Data);
    }
  };
  let totalPrice = Number(amount) + Number(price);

  return (
    <div id="archivePage">
      <div className="archivePageContainer">
        <div className="achiveHeader">
          <h1>Request for old newspapers</h1>
          {publicationData == null ? (
            ""
          ) : (
            <p>
              You have chosen to request an archive from The{" "}
              <span>{Vendor?.publication?.name}</span>{" "}
            </p>
          )}
        </div>

        <div className="formContainer">
          <div className="inputWrapAchive">
            <select
              name=""
              id=""
              className="inputWrapAchiveMain"
              onChange={(e) => setArchiveType(e.target.value)}
              style={{
                marginBottom: "15px",
              }}
            >
              <option selected value="newspapers">
                News Papers
              </option>
              <option value={"magazines"}>Magazines</option>
            </select>
            <select
              name=""
              id=""
              className="inputWrapAchiveMain"
              onChange={(e) => {
                setSelectedPublication(e.target.value);
                // setPublicationData(e.target.value);
                // setDateInputes([{}]);
                // setBasicAmount(0);
                // setamount(0);
                // getVendorAchiveDetails(e.target.value);
              }}
              style={{
                marginBottom: "15px",
              }}
            >
              <option value="">Select vendor from {archiveType}</option>
              {publications
                .filter((data) => data.type.name === archiveType)
                .map((data) => (
                  <option key={data?.id} value={data?.name}>
                    {data?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="inputWrapAchive">
            {dateInputes.map((date, index) => {
              return (
                // <input
                //   type="date"
                //   className="inputWrapAchiveMain rminputWrapPad"
                //   onChange={(e) => handleDateInput(e, index)}
                // />
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  <Datepicker
                    required
                    selected={selectedDates[index]}
                    // showTimeSelect
                    // minDate={new Date(publicationData?.fromDate)}
                    placeholderText="Select a date"
                    onChange={(date) => handleDateInput(date, index)}
                    className="inputWrapAchiveMain rminputWrapPad"
                    disabled={false}
                    showYearDropdown
                    // minDate={new Date(Vendor?.fromDate)}
                    // maxDate={new Date(Vendor?.endDate)}
                    maxDate={new Date()}
                  />

                  {index == 0 ? (
                    ""
                  ) : (
                    <p
                      className="mtdatexand"
                      onClick={() => handleRemoveDateInput(index)}
                    >
                      Remove
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          {console.log("Vendors>>>>", new Date(Vendor.endDate))}
        </div>
        <div className="addMoreDates" onClick={addmoreInputes}>
          <img src={Plus2} alt="" /> <span>Additonal Dates</span>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="submitAchive">
            {/* <span>
              Total: <span>NGN {totalPrice}</span>
            </span> */}
            <div />
            <button onClick={requestForArchive}>Request archive</button>
          </div>
        </div>
      </div>

      {showSuccess ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "#FFF",
            paddingTop: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              textAlign: "center",
              color: "#425466",
              fontWeight: 700,
            }}
          >
            Congratulations!
          </p>

          <img src={successIcon} alt="icon" />
          <p
            style={{
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
              width: "308px",
              color: "#4E4B66",
              lineHeight: "24px",
            }}
          >
            you have successfully applied for the {selectedPublication} Archive,
            your old newspaper is on the way cheers!
          </p>
          <p style={{ fontSize: "16px", fontWeight: 400, color: "#000000" }}>
            Click to contact
            <span style={{ color: "#EE7F0D" }}> support</span>
          </p>
          <div
            style={{
              maxWidth: "700px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderColor: "#EE7F0D",
              borderTopStyle: "dashed",
              borderBottomStyle: "dashed",
              borderTopWidth: "2px",
              borderBottomWidth: "2px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <img
              src={selectedPublicationImg?.logo}
              alt={selectedPublicationImg?.name}
              style={{ width: "181px" }}
            />
          </div>
          <button
            onClick={() => history.push("/")}
            style={{
              width: "266px",
              height: "45px",
              background: "#2BB509",
              borderRadius: "7px",
              color: "#FFF",
            }}
          >
            Close
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Index;
