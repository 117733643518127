import React from "react";
import NotFound from "../../assets/notFound.png";
export default function EmeptyData({ text }) {
  return (
    <div>
      <div className="datanotFound-wrap">
        <img src={NotFound} alt="" />
        <p>{text}</p>
      </div>
    </div>
  );
}
