import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
  validatePassword,
} from "../../components/helpers/validateInput";
import { NotificationManager } from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import swal from "sweetalert";
import Logo from "../../assets/logo-2048_X_1536_1-removebg-preview 1 (1) 1.png";
import Select from "react-select";
import countryList from "react-select-country-list";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
export default function CreateAccount(props) {
  const [userAccount, setUserAccount] = useState({
    type: "",
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
    activeInput: "",
  });
  const [accountType, SetaccountType] = useState("individual");
  const [setValidatedForm, setValidated] = useState(false);
  const [emailOptionText, setEmailOptionText] = useState(false);
  const [userCountry, setuserCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [userNumber, setuserNumber] = useState("");

  const changeHandler = (value) => {
    // alert(value.label);
    setuserCountry(value);
  };

  const [errorInput, setErrorInput] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "lastName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, lastName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "companyName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, companyName: testValid });
      if (testValid !== "Looks Good!") {
        return false;
      }
    }

    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = validatePassword(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.name === "header_image") {
      //  setUserAccount({...offers,header_image:e.target.files[0], previewHeaderImg: URL.createObjectURL(e.target.files[0])})
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      errorInput.password !== "Looks Good!" ||
      errorInput.email !== "Looks Good!" ||
      errorInput.firstName !== "Looks Good!" ||
      errorInput.lastName !== "Looks Good!"
    ) {
      NotificationManager.error("Please fill in all input.");

      return;
    }

    if (userCountry === "") {
      NotificationManager.error("Please select your country.");
      return;
    }

    if (userNumber === "" || userNumber === null || userNumber === undefined) {
      return NotificationManager.error("Phone number is required.");
    }

    if (termAndConditionCheck === false) {
      swal({
        title: "Oops!",
        text: "You need to accept our terms of use before you can procceed",
        icon: "warning",
        dangerMode: true,
      });

      return;
    }

    showLoader();
    let countryCode = `+${parsePhoneNumber(userNumber).countryCallingCode}`;
    let sliceNum = userNumber.slice(-10);
    const data = {
      type: accountType,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      email: userAccount.email,
      password: userAccount.password,
      emailOpt: emailOptionText,
      country: userCountry.label,
      countryCode,
      phoneNumber: sliceNum,
    };
    let newData =
      accountType === "corporate"
        ? { ...data, companyName: userAccount.companyName }
        : data;
    let res = await httpPost(`auth/register_user`, newData);

    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);

        return;
      }

      setUserAccount({
        type: "",
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        accountType: "",
        activeInput: "",
      });
      hideLoader();
      swal(
        "Account Created!",
        "In order to complete the sign-up process, please click the confirmation link sent to your registered email. If you do not receive a confirmation email, please check your spam folder also.",
        "success"
      ).then(() => {
        props.history.push("/login");
      });
    }
    hideLoader();
  };

  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  const [termAndConditionCheck, SettermAndConditionCheck] = useState(false);
  return (
    <div>
      <div className="create-account-wrap">
        <div className="create-account-col-one-wrap">
          <div className="createAccountLogo">
            <img src={Logo} alt="" />
          </div>
          {/* <img src="./tns.png" alt="" className="tns-create-account-logo" /> */}
          <div className="offer-tns-home-create-acc">
            <h1>
              {" "}
              <img src="./right-color-arrow.png" alt="" /> Start reading{" "}
            </h1>
            <p>
              Read your favourite Newspapers, Magazines and Books in minutes.
            </p>
          </div>

          <div className="offer-tns-home-create-acc">
            <h1>
              {" "}
              <img src="./right-color-arrow.png" alt="" /> One off or Subscribe{" "}
            </h1>
            <p>
              You can choose to buy or subscribe to one or more publications
              either daily, weekly, quartely or annualy.
            </p>
          </div>

          <div className="offer-tns-home-create-acc">
            <h1>
              {" "}
              <img src="./right-color-arrow.png" alt="" /> Request Change of
              Name{" "}
            </h1>
            <p>
              You can get your change of Name done and published with a
              Newspaper of your choice.
            </p>
          </div>

          <div className="offer-tns-home-create-acc">
            <h1>
              {" "}
              <img src="./right-color-arrow.png" alt="" /> Request Publication
              Archive{" "}
            </h1>
            <p>
              In need of a publication dating back to 1960? You can a request
              with us.
            </p>
          </div>
        </div>

        <div className="create-account-col-two-wrap">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="hideOnDesktopCreateAccountLogo"
          >
            <img src={Logo} alt="" />
          </div>
          <h1 className="firstHeaderCr">Create your account</h1>

          <div className="account-type-home accountTypehfo">
            <span>
              Individual{" "}
              <input
                onClick={() => SetaccountType("individual")}
                checked={accountType === "individual" ? true : false}
                type="radio"
                onChange={() => console.log("ch")}
              />
            </span>
            <span>
              Corporate{" "}
              <input
                onClick={() => SetaccountType("corporate")}
                checked={accountType === "corporate" ? true : false}
                type="radio"
                onChange={() => console.log("ch")}
              />
            </span>
          </div>

          {accountType === "individual" ? (
            <form className="create-account-inputes-wrap">
              <div className="input-wrap-create-acc">
                <label>First Name</label>
                <div className="input-create-acc">
                  <input
                    name="firstName"
                    onChange={handleChange}
                    placeholder="Enter first name"
                    type="text"
                    value={userAccount.firstName}
                  />
                  <span
                    style={
                      errorInput.firstName === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.firstName}
                  </span>
                </div>
              </div>

              <div className="input-wrap-create-acc">
                <label>Last Name</label>
                <div className="input-create-acc">
                  <input
                    name="lastName"
                    onChange={handleChange}
                    placeholder="Enter last name"
                    type="text"
                    value={userAccount.lastName}
                  />
                  <span
                    style={
                      errorInput.lastName === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.lastName}
                  </span>
                </div>
              </div>

              <div className="input-wrap-create-acc">
                <label>Email address</label>
                <div className="input-create-acc">
                  <input
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter email address"
                    type="text"
                    value={userAccount.email}
                  />
                  <span
                    style={
                      errorInput.email === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.email}
                  </span>
                </div>
              </div>
              <div className="input-wrap-create-acc">
                <label>Mobile Number</label>
                <PhoneInput
                  defaultCountry="NG"
                  placeholder="Enter phone number"
                  value={userNumber}
                  onChange={setuserNumber}
                />
              </div>

              <div className="input-wrap-create-acc">
                <label>Select Country</label>
                <div className="input-create-acc">
                  <Select
                    options={options}
                    value={userCountry}
                    onChange={changeHandler}
                  />
                </div>
              </div>

              <div className="input-wrap-create-acc">
                <label>Password</label>
                <div className="input-create-acc">
                  <input
                    name="password"
                    onChange={handleChange}
                    placeholder="Enter password"
                    type="password"
                    value={userAccount.password}
                    type={`${showPass ? "text" : "password"}`}
                  />
                  <div className="togglePasscreAcc">
                    <i
                      onClick={toggleSeePasswordIcon}
                      className={`fas ${
                        showPass ? " fa-eye" : " fa-eye-slash"
                      }`}
                    ></i>
                    {/* <i class="fas fa-eye-slash"></i> */}
                  </div>

                  <span
                    style={
                      errorInput.password === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.password}
                  </span>
                </div>
              </div>
              <div className="create-acc-newlette">
                <h1>Allow email communications:</h1>
                <p>
                  <input
                    onClick={() => setEmailOptionText(!emailOptionText)}
                    type="checkbox"
                  />
                  General Update, New Issues and topic trends
                </p>
              </div>

              <div
                style={{ marginTop: "23px" }}
                className="create-acc-newlette"
              >
                <h1>Terms and condition:</h1>
                <p>
                  <input
                    checked={termAndConditionCheck}
                    onClick={() =>
                      SettermAndConditionCheck(!termAndConditionCheck)
                    }
                    type="checkbox"
                  />
                  I understand and agree to the{" "}
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/terms-conditions/"
                  >
                    <span style={{ color: "orange", cursor: "pointer" }}>
                      TNS terms and condition
                    </span>
                  </a>
                  and
                  <br className="hdCreM" />
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/privacy-policy/"
                  >
                    <span
                      style={{
                        color: "orange",
                        cursor: "pointer",
                        marginLeft: "32px",
                        top: "-7px",
                        position: "relative",
                      }}
                      className="pPMr"
                    >
                      Privacy Policy
                    </span>
                  </a>
                </p>
              </div>

              <div
                style={{ justifyContent: "flex-start" }}
                className="submit-login-details-home submit-login-createAcc"
              >
                <button onClick={handleSubmit}>Create my account</button>
              </div>
              <div
                style={{ justifyContent: "flex-start", marginBottom: "20px" }}
                className="forgot-password-wrap-home"
              >
                <Link to="/login">
                  Already have an acount ?{" "}
                  <span style={{ color: "orange" }}>SIgn In</span>
                </Link>
              </div>
            </form>
          ) : (
            ""
          )}

          {accountType === "corporate" ? (
            <form className="create-account-inputes-wrap">
              <div className="input-wrap-create-acc">
                <label>Company Name</label>
                <div className="input-create-acc">
                  <input
                    name="companyName"
                    onChange={handleChange}
                    placeholder="Enter Company Name"
                    type="text"
                    value={userAccount.companyName}
                  />
                  <span
                    style={
                      errorInput.companyName === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.companyName}
                  </span>
                </div>
              </div>
              <div className="input-wrap-create-acc">
                <label>First Name</label>
                <div className="input-create-acc">
                  <input
                    name="firstName"
                    onChange={handleChange}
                    placeholder="Enter first name"
                    type="text"
                    value={userAccount.firstName}
                  />
                  <span
                    style={
                      errorInput.firstName === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.firstName}
                  </span>
                </div>
              </div>
              <div className="input-wrap-create-acc">
                <label>Last Name</label>
                <div className="input-create-acc">
                  <input
                    name="lastName"
                    onChange={handleChange}
                    placeholder="Enter last name"
                    type="text"
                    value={userAccount.lastName}
                  />
                  <span
                    style={
                      errorInput.lastName === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.lastName}
                  </span>
                </div>
              </div>
              <div className="input-wrap-create-acc">
                <label>Email address</label>
                <div className="input-create-acc">
                  <input
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter email address"
                    type="text"
                    value={userAccount.email}
                  />
                  <span
                    style={
                      errorInput.email === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.email}
                  </span>
                </div>
              </div>

              <div className="input-wrap-create-acc">
                <label>Mobile Number</label>
                <PhoneInput
                  defaultCountry="NG"
                  placeholder="Enter phone number"
                  value={userNumber}
                  onChange={setuserNumber}
                />
              </div>

              <div className="input-wrap-create-acc">
                <label>Select Country</label>
                <div className="input-create-acc">
                  <Select
                    options={options}
                    value={userCountry}
                    onChange={changeHandler}
                  />
                </div>
              </div>

              <div className="input-wrap-create-acc">
                <label>Password</label>
                <div className="input-create-acc">
                  <input
                    name="password"
                    onChange={handleChange}
                    placeholder="Enter password"
                    type="password"
                    value={userAccount.password}
                  />
                  <span
                    style={
                      errorInput.password === "Looks Good!"
                        ? { color: "green" }
                        : {}
                    }
                    className="AppError"
                  >
                    {errorInput.password}
                  </span>
                </div>
              </div>
              <div className="create-acc-newlette">
                <h1>Allow email communications:</h1>
                <p>
                  <input
                    onClick={() => setEmailOptionText(!emailOptionText)}
                    type="checkbox"
                  />
                  General Update, New Issues and Trending Topics
                </p>
              </div>
              <div
                style={{ marginTop: "23px" }}
                className="create-acc-newlette"
              >
                <h1>Terms and condition:</h1>
                <p>
                  <input
                    checked={termAndConditionCheck}
                    onClick={() =>
                      SettermAndConditionCheck(!termAndConditionCheck)
                    }
                    type="checkbox"
                  />
                  I understand and agree to the{" "}
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/terms-conditions/"
                  >
                    <span style={{ color: "orange", cursor: "pointer" }}>
                      TNS Terms and Conditions
                    </span>
                  </a>
                  and
                  <br />
                  <a
                    target="blank"
                    href="https://thenewspaperstand.com/privacy-policy/"
                  >
                    <span
                      style={{
                        color: "orange",
                        cursor: "pointer",
                        marginLeft: "32px",
                        top: "-7px",
                        position: "relative",
                      }}
                    >
                      Privacy Policy
                    </span>
                  </a>
                </p>
              </div>

              <div
                style={{ justifyContent: "flex-start" }}
                className="submit-login-details-home submit-login-createAcc"
              >
                <button onClick={handleSubmit}>Create my account</button>
              </div>
              <div
                style={{ justifyContent: "flex-start", marginBottom: "20px" }}
                className="forgot-password-wrap-home"
              >
                <Link to="/login">
                  Already have an acount ?{" "}
                  <span style={{ color: "orange" }}>SIgn In</span>
                </Link>
              </div>
            </form>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
