import React from "react";
import errorImg from "../../assets/error.png";
export default function NotFound({ header, body }) {
  return (
    <div>
      <div className="errorNotFoundImg">
        <div className="errorNotFoundText">
          <p>{header}</p>
          <span>{body}</span>
        </div>
        <div className="errorNotFoundTextImg">
          <img src={errorImg} alt="" />
        </div>
      </div>
    </div>
  );
}
