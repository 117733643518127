import React, { useState } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../helpers/dateFormater";

export default function DataTable({
  setAddSubAccountModalFun,
  allMySubAccountsData,
  cancelSubscription,
}) {
  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);

  const handlePopOver = (id) => {
    console.log("CLicking>>>");
    setShow(!show);
    if (show) {
      setPopOver([id]);
    }
  };

  return (
    <div className="table-wrap border styleTable">
      <div className="addSubAccountBtn">
        <h2 className="addSubAccountHeader">
          You can buy any publication of choice for your family, friends,
          colleagues or staff.
        </h2>
        <button onClick={setAddSubAccountModalFun}>Add Sub Account</button>
      </div>

      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 20,
        }}
        columns={[
          { title: "Name", field: "Name" },
          { title: "Email", field: "Email" },
          // { title: "Phone number", field: "phoneNumber" },
          { title: "Renewal Date", field: "RenewalDate" },
          { title: " Amount", field: "Amount" },
          { title: " Publication", field: "Publication" },
          { title: " Subscription", field: "Subscription" },
          { title: " Status", field: "Status" },
          { title: " Action", field: "Action" },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            Name: `${data.user.firstName} ${data.user.lastName}`,
            Email: data.user.email,
            // phoneNumber: data?.user?.phoneNumber,
            RenewalDate:
              data.nextPaymentDate === null
                ? "No date added"
                : dateFormater(data.nextPaymentDate),
            Amount: data.amount,
            Publication: data.publication.name,
            Subscription: data.subType,
            Status: data.status,
            Action: (
              <div className="subAccountSub">
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: "rgba(128, 128, 128, 0.103)" }
                        : { background: " rgb(199, 13, 13)" }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className="publicationCheckbox">
                <input id="c1" type="checkbox" />
              </div>
            ),
          };
        })}
        title="Sub Accounts"
      />
    </div>
  );
}
