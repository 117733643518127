import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import { Select } from "react-select";
import demo1 from "../../assets/demopic1.png";
import { httpGet } from "./../../components/helpers/httpMethods";
import ChangeOfNameApplications from "./changeOfNameTable";
import AchiveTable from "./achiveTable";
import PNTable from "./pnTable";
import { hideLoader, showLoader } from "./../../components/helpers/loader";
import { PaymentContext } from "../../context/paymentContext";
import { UserDataContext } from "../../context/userContext";
const Index = (props) => {
  const { UserInfo, userLocation, getUserProfile, userIsLogin } = useContext(
    UserDataContext
  );
  const { changeOfNameSuccess, SetchangeOfNameSuccess } = useContext(
    PaymentContext
  );

  const [tab, setTab] = useState("tab1");
  const [archive, setArchive] = useState([]);
  const [PN, setsetPN] = useState([]);
  useEffect(() => {
    showChangeOfNameVendors();
    return () => {
      setApplications([]);
    };
  }, []);

  const [applications, setApplications] = useState([]);
  const showChangeOfNameVendors = async () => {
    showLoader();
    const res1 = await httpGet("con/user/applications");
    const res2 = await httpGet("pn/user/applications");
    const res3 = await httpGet("archive/user/applications");
    if (res1.code == 200) {
      hideLoader();
      setApplications(res1.data.conApplications);
    }

    if (res3.code == 200) {
      setArchive(res3.data.archiveApplications);
    }

    if (res2.code == 200) {
      setsetPN(res2.data.pnApplications);
    }
    hideLoader();
  };
  return (
    <div className="Applicationcontainer">
      <div className="ApplicationcontainerMain">
        <div className="tabsAppli">
          <ul>
            <li
              className={`${tab == "tab1" ? "activedjsehapp" : ""}`}
              onClick={() => setTab("tab1")}
            >
              Change of name
            </li>
            {/* <li
              className={`${tab == "tab2" ? "activedjsehapp" : ""}`}
              onClick={() => setTab("tab2")}
            >
              Loss of Document
            </li> */}
            <li
              className={`${tab == "tab3" ? "activedjsehapp" : ""}`}
              onClick={() => setTab("tab3")}
            >
              Archive
            </li>
            <li
              className={`${tab == "tab4" ? "activedjsehapp" : ""}`}
              onClick={() => setTab("tab4")}
            >
              Public Notice
            </li>
          </ul>
        </div>
        {tab == "tab1" ? (
          <ChangeOfNameApplications applications={applications} />
        ) : (
          ""
        )}

        {tab == "tab3" ? <AchiveTable applications={archive} /> : ""}

        {tab == "tab4" ? <PNTable applications={PN} /> : ""}
      </div>
    </div>
  );
};

export default Index;
